import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';

const m2kUnit = () => {
  const { region } = useSelector(getUser);
  const US_UNIT_REGION = 'NAC';

  if (region === US_UNIT_REGION) {
    return (
      <span>
        ft
        <sup>2</sup>
      </span>
    );
  }
  return (
    <span>
      W/
      <span>
        m
        <sup>
          2
        </sup>
        K
      </span>
    </span>
  );
};

export default m2kUnit;
