import PropTypes from 'prop-types';

export const Gauge = ({
  percent, value, thresholds,
}) => {
  const radius = 50;
  const strokeWidth = radius * 0.2;
  const innerRadius = radius - strokeWidth;
  const circumference = innerRadius * 2 * Math.PI;
  const arc = circumference * 0.75;
  const dashArray = `${arc} ${circumference}`;
  const transform = `rotate(135, ${radius}, ${radius})`;

  return (
    <svg height={radius * 2} width={radius * 2}>

      <lineargradient id="grad" x1="0" y1="0" x2="1" y2="1" />
      <stop offset="15%" stopColor="#1267ff" stopOpacity="1" />
      <stop offset="85%" stopColor="#98c0ff" stopOpacity="1" />
      ‍
      ‍

      <circle
        className="gauge_base"
        cx={radius}
        cy={radius}
        fill="transparent"
        r={innerRadius}
        stroke="#DDD"
        strokeDasharray={dashArray}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        transform={transform}
      />

      {thresholds.map((threshold) => (
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={innerRadius}
          stroke={threshold.color}
          strokeDasharray={`${(threshold.value / 100) * 190} ${270 - (threshold.value / 100) * 190}`}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          style={{
            transition: 'stroke-dashoffset 0.3s',
          }}
          transform={transform}
        />
      ))}

      <polygon
        fill="#2E2E2E"
        points="57.071,50 50,47.071 42.929,50 50,35"
        transform={`rotate(${-135 + (270 * (percent / 100))} ${radius} ${radius}) translate(0, -25)`}
      />

      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize={18} fontWeight={600} fill="#333">
        {value}
      </text>

    </svg>

  );
};

Gauge.propTypes = {
  value: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  thresholds: PropTypes.array,
};

Gauge.defaultProps = {
  thresholds: [],
};
