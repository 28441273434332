import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import {
  Form, Formik,
} from 'formik';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import FormSelect from 'components/Form/FormSelect';
import { useSendShareMutation } from 'store/services/api';

const validationSchema = Yup.object().shape({
  team: Yup.string().required(t('validationErrors.required')),
});

const DataGridShareModalTeam = ({ options, itemId }) => {
  const formikInitialValues = {
    team: '',
  };

  const { teams } = options;

  const [sendShare, { isLoading }] = useSendShareMutation();

  const onSubmitHandler = async (data) => {
    try {
      const result = await sendShare({
        data: {
          target: data.team,
          share_type: 'team',
        },
        id: itemId,
      });

      result ? toast.info('Shared') : toast.error('Could not share code');

      toast.info('Shared');
    } catch {
      toast.error(t('toasterMessage.error.requestPassReset'));
    }
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ errors }) => (
        <Form className="flex flex-col gap-6">

          <FormSelect
            name="team"
            label={t('DataGrid.shareModal.team')}
            options={teams.map((team) => ({ key: team.id, value: team.name }))}
          />

          <Button
            disabled={Object.keys(errors).length > 0 || isLoading}
            type="submit"
          >
            {t('DataGrid.shareModal.buttons.shareWithTeam')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

DataGridShareModalTeam.propTypes = {
  itemId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
};

export default DataGridShareModalTeam;
