import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { ReportContext } from 'containers/Reports/ReportContext';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

const AccordionItem = ({ children, label, id }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleItem = () => setIsVisible(!isVisible);

  const { report, setReport } = useContext(ReportContext);

  return (
    <div className="">
      <div
        role="button"
        tabIndex={0}
        onClick={() => toggleItem()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            toggleItem();
          }
        }}
        className="bg-sky-700 hover:bg-sky-800 cursor-pointer flex items-center px-4 py-3 select-none ring-yellow-100"
      >
        <span className="pr-4">
          {!isVisible ? <ChevronDownIcon className="text-white/60 w-5" /> : <ChevronUpIcon className="text-white/60 w-5" />}
        </span>

        <span className="font-medium text-sm text-white flex-grow">{label}</span>

        <div
          role="button"
          className="text-white text-sm"
          onClick={(e) => { e.stopPropagation(); }}
          onKeyDown={(e) => { e.stopPropagation(); }}
          tabIndex={0}
        >
          <label htmlFor={`addReport_${id}`}>
            <input
              id={`addReport_${id}`}
              value={`report_${id}`}
              type="checkbox"
              onChange={() => {
                setReport({ ...report, [`${id}`]: !report[id] });
              }}
              checked={report[id]}
            />
            <span className="pl-2">Add to report</span>
          </label>
        </div>
      </div>
      {isVisible
        && (
          <div className="bg-slate-50 px-4 py-3 border-t border-slate-300 min-h-96">
            {children}
          </div>
        )}
    </div>
  );
};

AccordionItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

AccordionItem.defaultProps = {
  id: null,
};

export default AccordionItem;
