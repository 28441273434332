const useVendorLogo = (vendor) => {
  switch (vendor) {
    case 'guardian':
      return <img src="/logos/guardian.svg" width={200} alt="Guardian" className="mb-6" />;
    default:
      return null;
  }
};

export default useVendorLogo;
