import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';

const useUserId = () => {
  const user = useSelector(getUser);
  const params = useParams('/:path/:id');
  return params?.id || user.id;
};

export default useUserId;
