/* eslint-disable camelcase */
import Button from 'components/Button/Button';
import {
  Field, Form, Formik,
} from 'formik';
import { t } from 'i18next';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateProfileMutation } from 'store/services/api';
import { getUser } from 'store/slices/user';
import toast from 'react-hot-toast';
import ProfileCheckbox from './ProfileCheckbox';
import ProfileContext from './ProfileContext';
import ProfileGroup from './ProfileGroup';

const ProfileAppPreferences = () => {
  const [updateProfile] = useUpdateProfileMutation();
  const { id } = useSelector(getUser);
  const { prefs } = useContext(ProfileContext);

  const formikInitialValues = {
    numeric_system: prefs.numeric_system,
    email_shared_codes: prefs.notifications.email_shared_codes,
    email_shared_glazings: prefs.notifications.email_shared_glazings,
    email_shared_projects: prefs.notifications.email_shared_projects,
    email_shared_reports: prefs.notifications.email_shared_reports,
  };

  const onSubmit = async (userData) => {
    const { numeric_system, ...notifications } = userData;

    try {
      const result = await updateProfile({
        id,
        userData: {
          prefs: {
            numeric_system,
            notifications: { ...notifications },
          },
        },
      });
      result.data ? toast.success(t('toasterMessage.success.userInfo')) : toast.error(t('toasterMessage.error.userInfo'));
    } catch (error) {
      toast.error(t('toasterMessage.error.userInfo'));
    }
  };

  return (
    <div>
      <Formik
        initialValues={formikInitialValues}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <ProfileGroup title={t('Profile.appPreferences')} description="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">

              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="numeric_system" className="block text-sm font-medium text-base-secondary">
                  {t('Profile.numericSystem')}
                </label>
                <Field
                  as="select"
                  id="numeric_system"
                  name="numeric_system"
                  autoComplete="numeric_system"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
                >
                  <option value="metric">{t('Profile.metricSystem')}</option>
                  <option value="default">{t('Profile.imperialSystem')}</option>
                </Field>
              </div>
            </ProfileGroup>

            <ProfileGroup title={t('Profile.emailNotifications')} description="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">

              <div className="col-span-6 sm:col-span-3">
                <fieldset>
                  <div className="mt-4 space-y-4">

                    <ProfileCheckbox
                      id="reports"
                      name="email_shared_reports"
                      label={t('Profile.sharedReports')}
                    />

                    <ProfileCheckbox
                      id="codes"
                      name="email_shared_codes"
                      label={t('Profile.sharedCodes')}
                    />

                    <ProfileCheckbox
                      id="projects"
                      name="email_shared_projects"
                      label={t('Profile.sharedProjects')}
                    />

                    <ProfileCheckbox
                      id="glazing"
                      name="email_shared_glazings"
                      label={t('Profile.sharedGlazing')}
                    />
                  </div>
                </fieldset>
              </div>

            </ProfileGroup>

            <div className="flex justify-end py-6">
              <Button type="submit">
                {t('Profile.updateProfile')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  );
};

export default ProfileAppPreferences;
