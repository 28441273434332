import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

const PaginationLabel = ({ offset, limit, totalItems }) => (
  <div>
    <p className="text-sm text-gray-700">
      <Trans>
        Showing
        {' '}
        <span className="font-medium">{{ offset }}</span>
        {' '}
        to
        {' '}
        <span className="font-medium">{{ limit }}</span>
        {' '}
        of
        {' '}
        <span className="font-medium">{{ totalItems }}</span>
        {' '}
        results
      </Trans>
    </p>
  </div>
);

PaginationLabel.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default PaginationLabel;
