import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import {
  useLazyGetMsiUrlQuery,
} from 'store/services/api';
import Button from '../Button/Button';

const DownloadModal = ({
  open, onClose,
}) => {
  const onCloseButtonClickHandler = () => {
    onClose();
  };

  const [trigger] = useLazyGetMsiUrlQuery();

  const downloadMsi = async () => {
    await window.open('https://api.fenestrapro.com/v0/msi/download/guardian_beta/', '_blank');
    await trigger();
  };

  return (
    <Modal open={open} setOpen={onCloseButtonClickHandler}>
      <div className="px-2">
        <div className="grid grid-cols-3 gap-3">
          <img src="/images/guardian-bim.png" width={125} alt="Guardian" />
          <img src="/images/fenestrapro.png" width={125} alt="Fenestrapro" />
          <img src="/images/autodesk-revit.png" width={125} alt="Guardian" />
        </div>
      </div>
      <div className="bg-slate-100 rounded-md mt-4 mb-4 p-2">
        <h6 className="font-bold text-base-primary pb-2 text-l pl-2 text-start">
          System Requirements:
        </h6>
        <ul className="font-base list-disc pl-8">
          <li>Autodesk Revit 2021 - 2023</li>
          <li>Windows: 7, 8 or 10, 64-bit preferable</li>
          <li>4GB RAM minimum, 8GB+ RAM recommended</li>
          <li>Installation size: 22 MB</li>
          <li>Internet access required for API access to our servers</li>
        </ul>
      </div>
      <div className="flex justify-center">
        <Button
          variant="download"
          onClick={downloadMsi}
          type="button"
        >
          <div className="flex flex-col">
            <h2 className="text-xl font-bold">Download Guardian Glass for BIM</h2>
            <p className="text-base">
              <b>Version 2.1.5 </b>
              - 31th of August 2023
            </p>
          </div>
        </Button>
      </div>

    </Modal>
  );
};

DownloadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DownloadModal;
