export function applyTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function createTheme({
  highlightPrimary,
  highlightSecondary,
  basePrimary,
  baseSecondary,
  lowlightPrimary,
  lowlightSecondary,
  logo,
  headerPattern,
  loginImage,
  loginLogo,
  bannerA,
  bannerB,
  bannerC,
  bannerD,
  brochureBanner,
}) {
  return {
    '--theme-highlight-primary': highlightPrimary,
    '--theme-highlight-secondary': highlightSecondary,
    '--theme-base-primary': basePrimary,
    '--theme-base-secondary': baseSecondary,
    '--theme-lowlight-primary': lowlightPrimary,
    '--theme-lowlight-secondary': lowlightSecondary,
    '--theme-logo': logo,
    '--theme-header-pattern': headerPattern,
    '--theme-login-image': loginImage,
    '--theme-login-logo': loginLogo,
    '--theme-banner-a': bannerA,
    '--theme-banner-b': bannerB,
    '--theme-banner-c': bannerC,
    '--theme-banner-d': bannerD,
    '--theme-brochure-banner': brochureBanner,
  };
}
