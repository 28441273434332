import { t } from 'i18next';
import Seo from 'components/Seo/Seo';
import Button from 'components/Button/Button';
import { useState, useMemo } from 'react';
import ReportsProjectInformation from './ReportsProjectInformation';
import ReportsProjectPerformance from './ReportsProjectPerformance';
import ReportsProjectMaterial from './ReportsProjectMaterial';
import { ReportContext } from './ReportContext';

const ReportsDetails = () => {
  const [report, setReport] = useState({
    facade: false,
    space: false,
    other: false,
    test: false,
  });

  const value = useMemo(() => ({ report, setReport }));

  const reportConfigFalse = Object.values(report).every(
    (val) => val === false,
  );

  return (
    <>
      <Seo title={t('reports.title')} />

      <ReportContext.Provider value={value}>
        <div className="py-6">
          <ReportsProjectInformation />
          <ReportsProjectMaterial />
          <ReportsProjectPerformance />

          <aside className="flex flex-row-reverse gap-4">
            {/* Check there is at least one true value */}

            <Button
              disabled={reportConfigFalse}
              onClick={() => alert(JSON.stringify(report))}
            >
              Generate PDF Report
            </Button>

            <Button>Share Report</Button>
          </aside>

        </div>
      </ReportContext.Provider>
    </>
  );
};

export default ReportsDetails;
