import { t } from 'i18next';
import Metrics from 'components/Metrics/Metrics';
import DataGrid from 'components/DataGrid/DataGrid';
import { useLazyGetRegionalsTabQuery, useGetRegionalsMetricQuery } from 'store/services/api';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import { useNavigate } from 'react-router-dom';
import { ASM_DASHBOARD_PATH, DESIGNER_DASHBOARD_PATH, PROJECTS_PATH } from 'routes';
import Seo from 'components/Seo/Seo';
import { serviceUrl } from 'store/services/settings';
import { UserIcon } from '@heroicons/react/solid';
import { BriefcaseIcon, GlobeIcon, ArrowsExpandIcon } from '@heroicons/react/outline';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { CONTAINER_REGIONAL, ROLE_ASM } from 'constants';
import useAreaUnit from 'hooks/useAreaUnit';

const DashboardRegional = () => {
  const navigate = useNavigate();

  const asmsDataGridProperties = [{

    name: t('DataGrid.tabs.asms'),
    urlSlug: serviceUrl.asms,
    onItemClick: ({ id }) => navigate(`${ASM_DASHBOARD_PATH}/${id}`),

    dataGridProps: [{
      key: 'name', label: t('DataGrid.tableHeader.regional.name'), sort: true, filter: true, width: '20%', defaultSortOrder: SORT_ORDER_ASC,
    }, {
      key: 'no_users', label: t('DataGrid.tableHeader.regional.noUsers'), sort: true, filter: true, width: '10%',
    }, {
      key: 'no_orgs', label: t('DataGrid.tableHeader.regional.noOrgs'), sort: true, filter: true, width: '10%',
    }, {
      key: 'no_prjs', label: t('DataGrid.tableHeader.regional.noProjects'), sort: true, filter: true, width: '10%',
    }, {
      key: 'area', label: t('DataGrid.tableHeader.regional.area'), sort: true, filter: true, width: '10%', dataType: DATA_TYPES.AREA, unit: useAreaUnit(),
    }, {
      key: 'no_courses', label: t('DataGrid.tableHeader.regional.noCourses'), sort: true, filter: true, width: '10%',
    }],

  }];

  const dataGridTabsProperties = [{
    name: t('DataGrid.tabs.usersPassive'),
    urlSlug: serviceUrl.users,
    onItemClick: ({ id }) => navigate(`${DESIGNER_DASHBOARD_PATH}/${id}`),
    dataGridProps: [{
      key: 'name', label: t('DataGrid.tableHeader.users.name'), sort: true, filter: true, width: '20%', defaultSortOrder: SORT_ORDER_ASC,
    }, {
      key: 'email', label: t('DataGrid.tableHeader.users.email'), sort: true, filter: true, width: '20%',
    }, {
      key: 'is_msi_downloaded', label: t('DataGrid.tableHeader.users.isMsiDownloaded'), sort: true, filter: true, width: '10%',
    }, {
      key: 'last_session', label: t('DataGrid.tableHeader.users.lastSession'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.DATE,
    }, {
      key: 'no_projects', label: t('DataGrid.tableHeader.users.noProjects'), sort: true, filter: true, width: '10%',
    }, {
      key: 'area', label: t('DataGrid.tableHeader.users.area'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.AREA, unit: useAreaUnit(),
    }, {
      key: 'no_courses', label: t('DataGrid.tableHeader.users.noCourses'), sort: true, filter: true, width: '10%',
    }],
  }, {
    name: t('DataGrid.tabs.projectsPassive'),
    urlSlug: serviceUrl.projects,
    onItemClick: ({ id }) => navigate(`${PROJECTS_PATH}/${id}`),
    dataGridProps: [{
      key: 'name', label: t('DataGrid.tableHeader.projects.name'), sort: true, filter: true, width: '20%', defaultSortOrder: SORT_ORDER_ASC,
    }, {
      key: 'country', label: t('DataGrid.tableHeader.projects.country'), sort: true, filter: true, width: '15%',
    }, {
      key: 'area',
      label: t('DataGrid.tableHeader.projects.area'),
      sort: true,
      filter: true,
      width: '15%',
      dataType: DATA_TYPES.AREA,
      unit: useAreaUnit(),
    }, {
      key: 'no_users', label: t('DataGrid.tableHeader.projects.noUsers'), sort: true, filter: true, width: '10%',
    }, {
      key: 'last_session', label: t('DataGrid.tableHeader.projects.lastSession'), sort: true, filter: true, width: '10%', dataType: DATA_TYPES.DATE,
    }],
  }];

  const metricsProperties = {
    asms: {
      icon: <GlobeIcon />,
    },
    projects: {
      icon: <BriefcaseIcon />,
    },
    users: {
      icon: <UserIcon />,
    },
    area: {
      icon: <ArrowsExpandIcon />,
    },
  };

  return (
    <>
      <Seo title={t('dashboard.title')} />

      <div className="flex gap-8 w-full">

        <section className="flex-grow flex-shrink py-6">

          <Breadcrumb
            current={CONTAINER_REGIONAL}
            isInviteUser
            addUserRole={ROLE_ASM}
            modalTitle={t('regionalDashboard.inviteUser')}
          />

          <Metrics
            fetchDataHook={useGetRegionalsMetricQuery}
            properties={metricsProperties}
          />

          <DataGrid
            title={t('regionalDashboard.unassignedPassive')}
            properties={dataGridTabsProperties}
            itemsPerPage={5}
            isExport
            isPagination
            informationMessage="Click above to invite Users as passive users by inputting their name and email address. They will receive an invite with a link to register."
            lazyFetchDataHook={useLazyGetRegionalsTabQuery}
          />

          <DataGrid
            title={t('regionalDashboard.listAsms')}
            properties={asmsDataGridProperties}
            itemsPerPage={5}
            isExport
            isPagination
            informationMessage="Click above to invite ASM/ADMs by inputting their name and email address. They will receive an invite with a link to register."
            lazyFetchDataHook={useLazyGetRegionalsTabQuery}
          />

        </section>

      </div>
    </>
  );
};

export default DashboardRegional;
