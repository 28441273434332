import PropTypes from 'prop-types';
import { Field } from 'formik';
import FormLabel from './FormLabel';

const FormSelect = ({ name, label, options }) => (
  <div className="flex flex-col">

    <FormLabel name={name} label={label} />

    <Field as="select" name={name} className="rounded border border-gray-300 py-2">
      <option>Select</option>
      {options.map(({ key, value }) => (
        <option key={`select_${key}`} value={key}>
          {value}
        </option>
      ))}
    </Field>
  </div>
);

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default FormSelect;
