import PropTypes from 'prop-types';

const IconButton = ({ icon, onClick }) => (
  <button
    className="h-10 w-10 p-3 rounded-full cursor-pointer text-base-secondary hover:bg-gray-50 hover:text-base-primary active:bg-gray-100 active:text-highlight-primary"
    type="button"
    onClick={onClick}
  >
    {icon}
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconButton;
