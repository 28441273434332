import Button from 'components/Button/Button';
import useUserId from 'hooks/useUserId';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { exportSpreadsheet } from 'utils/fileExports';
import DataGridContext from '../DataGridContext';

const DataGridHeaderExport = () => {
  const userId = useUserId();
  const { lazyFetchDataHook } = useContext(DataGridContext);
  const [trigger] = lazyFetchDataHook();
  const { t } = useTranslation();

  const {
    queryParams, selectedTab,
  } = useContext(DataGridContext);

  const onExportButtonClickHandler = async (fileType) => {
    const { data } = await trigger({
      queryParams: {
        search: queryParams.search,
        sort: queryParams.sort,
      },
      userId,
      tabUrlSlug: selectedTab?.urlSlug,
    });

    try {
      const spreadsheetHeader = selectedTab.dataGridProps.map(({ label }) => label);
      const spreadsheetBody = data.data.map((element) => (
        selectedTab.dataGridProps.map(({ key }) => element[key])
      ));

      exportSpreadsheet([spreadsheetHeader, ...spreadsheetBody], selectedTab.name, fileType);
    } catch (error) {
      toast.error(t('toasterMessage.error.exportFailed'));
    }
  };

  return (
    <>
      <div className="text-slate-500 pl-4 pr-2 text-sm self-center">
        {t('DataGrid.export')}
      </div>
      <Button onClick={() => onExportButtonClickHandler('xlsx')}>XLS</Button>
      <Button onClick={() => onExportButtonClickHandler('csv')}>CSV</Button>
    </>
  );
};

export default DataGridHeaderExport;
