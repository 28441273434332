import PropTypes from 'prop-types';

const ProfileGroup = ({ title, description, children }) => (

  <div className="px-4 py-8">
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <h3 className="text-lg font-medium leading-6 text-base-primary">{title}</h3>
        <p className="mt-1 text-sm text-base-secondary">{description}</p>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="grid grid-cols-6 gap-6">
          {children}
        </div>
      </div>
    </div>
  </div>
);

ProfileGroup.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ProfileGroup.defaultProps = {
  description: null,
};

export default ProfileGroup;
