import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';

const Seo = ({ title }) => (
  <Helmet>
    <title>
      {`${title} | ${t('appTitle')}`}
    </title>
  </Helmet>
);

Seo.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Seo;
