import { DASHBOARD_PATH } from 'routes';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="min-h-full pt-16 pb-12 flex flex-col bg-lowlight-primary">
    <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex-shrink-0 flex justify-center">
        <a href="/" className="inline-flex">
          <span className="sr-only">Workflow</span>
          <img
            className="h-12 w-auto"
            src="/logos/fenestra.svg"
            alt=""
          />
        </a>
      </div>
      <div className="py-16">
        <div className="text-center">
          <p className="text-sm font-semibold text-highlight-primary uppercase tracking-wide">404 error</p>
          <h1 className="mt-2 text-4xl font-bold text-base-primary tracking-tight sm:text-5xl">Page not found.</h1>
          <p className="mt-2 text-base text-base-secondary">Sorry, we couldn’t find the page you’re looking for.</p>
          <div className="mt-6">
            <Link to={DASHBOARD_PATH} className="text-base font-medium text-highlight-primary hover:text-sky-500">
              Go back home
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </div>
    </main>
  </div>
);

export default NotFound;
