import { t } from 'i18next';
import Seo from 'components/Seo/Seo';
import TabBar from 'components/TabBar/TabBar';
import TabBarItem from 'components/TabBar/TabBarItem';
import { Outlet } from 'react-router-dom';
import {
  PROFILE_PASSWORD_PATH, PROFILE_PATH, PROFILE_PREFERENCES_PATH, PROFILE_USER_PATH,
} from 'routes';
import { useGetProfileByIdQuery } from 'store/services/api';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';
import { useMemo } from 'react';
import ProfileContext from './ProfileContext';

const Profile = () => {
  const { id } = useSelector(getUser);
  const { data } = useGetProfileByIdQuery(id);

  const profileContextValue = useMemo(() => data && {
    info: data.info,
    prefs: data.prefs,
  }, [data]);

  return profileContextValue ? (
    <>
      <Seo title={t('Profile.title')} />
      <div className="py-6 space-y-6">
        <div className="bg-white shadow px-4 md:py-0 sm:rounded-lg sm:p-6">
          <TabBar>
            <TabBarItem href={`${PROFILE_PATH}/${PROFILE_USER_PATH}`}>{t('Profile.userInformation')}</TabBarItem>
            <TabBarItem href={`${PROFILE_PATH}/${PROFILE_PREFERENCES_PATH}`}>{t('Profile.appPreferences')}</TabBarItem>
            <TabBarItem href={`${PROFILE_PATH}/${PROFILE_PASSWORD_PATH}`}>{t('Profile.passwordSettings')}</TabBarItem>
          </TabBar>
          <ProfileContext.Provider value={profileContextValue}>
            <Outlet />
          </ProfileContext.Provider>
        </div>
      </div>
    </>
  ) : null;
};

export default Profile;
