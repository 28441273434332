import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Transition, Menu,
} from '@headlessui/react';
import { Fragment } from 'react';

const DropdownMenu = ({ children, menuButton, position }) => (
  <Menu as="div" className="relative inline-block text-left">
    <div>
      <Menu.Button className="hover:bg-gray-100 rounded-full p-1 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-sky-500">
        {menuButton}
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={classNames('origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50', {
          'top-0': position === 'below',
          'bottom-0': position === 'above',
          'right-0': position === 'left',
        })}
      >
        <div className="py-1">
          {children}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>

);

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  menuButton: PropTypes.node.isRequired,
  position: PropTypes.string,
};

DropdownMenu.defaultProps = {
  position: 'below',
};

export default DropdownMenu;
