import PageHeader from 'components/PageHeader/PageHeader';
import { t } from 'i18next';
import Seo from 'components/Seo/Seo';
import DataGrid from 'components/DataGrid/DataGrid';
import { serviceUrl } from 'store/services/settings';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import { useLazyGetReportsQuery } from 'store/services/api';
import { useNavigate } from 'react-router-dom';
import { REPORTS_PATH } from 'routes';

const Reports = () => {
  const navigate = useNavigate();

  const dataGridTabsProperties = [{
    name: 'Active Reports',
    urlSlug: serviceUrl.standard,
    onItemClick: ({ id }) => navigate(`${REPORTS_PATH}/${id}`),
    dataGridProps: [{
      key: 'name', label: 'Name', sort: true, width: '40%', bold: true, defaultSortOrder: SORT_ORDER_ASC, dataType: DATA_TYPES.STRING,
    }, {
      key: 'type', label: 'Type', sort: true, width: '20%', dataType: DATA_TYPES.STRING,
    }, {
      key: 'author', label: 'Author', sort: true, dataType: DATA_TYPES.STRING, width: '20%',
    }, {
      key: 'date', label: 'Created', sort: true, dataType: DATA_TYPES.DATE, width: '10%',
    }],
  }];

  return (
    <>
      <Seo title={t('reports.title')} />

      <div className="py-6">
        <PageHeader title={t('reports.title')} />

        <DataGrid
          properties={dataGridTabsProperties}
          informationMessage="Download Guardian Glass for BIM, open it on a project and under the Outputs tab click to generate a report."
          isPagination
          itemsPerPage={10}
          lazyFetchDataHook={useLazyGetReportsQuery}
        />

      </div>
    </>
  );
};

export default Reports;
