import { QrcodeIcon } from '@heroicons/react/solid';
import {
  ASSEMBLIES_PATH,
  BIM_PATH,
  CODES_PATH,
  DASHBOARD_PATH,
  REPORTS_PATH,
} from 'routes';

import IconGlassDatabase from 'components/Icon/IconGlassDatabase';
import IconReport from 'components/Icon/IconReport';
import { LogoutIcon } from '@heroicons/react/outline';
import IconHome from '../Icon/IconHome';

export const INTERNAL_LINKS = [
  {
    name: 'Sidebar.bim',
    href: BIM_PATH,
    svg: <IconHome />,
  },
  {
    name: 'Sidebar.yourReports',
    href: REPORTS_PATH,
    svg: <IconReport />,
  },
  {
    name: 'Sidebar.yourGlazingDatabase',
    href: ASSEMBLIES_PATH,
    svg: <IconGlassDatabase />,
  },
  {
    name: 'codes.title',
    href: CODES_PATH,
    icon: QrcodeIcon,
  },
];

export const WEBTOOLS = [
  {
    name: 'Sidebar.hub',
    href: 'https://www.guardianglass.com/',
    svg: <LogoutIcon />,
  },
];

export const BIMTOOLS = [
  {
    name: 'Sidebar.home',
    href: DASHBOARD_PATH,
    svg: <IconHome />,
  },
];

export const RESOURCES = [];
