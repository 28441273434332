import PropTypes from 'prop-types';
import { Field } from 'formik';
import FormLabel from './FormLabel';

const FormTextArea = ({ name, label }) => (
  <div>
    <FormLabel name={name} label={label} />

    <div className="mt-1">
      <Field
        component="textarea"
        id={name}
        name={name}
        rows={5}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
      />
    </div>
  </div>
);

FormTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormTextArea;
