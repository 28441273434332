import PropTypes from 'prop-types';

const LoginInput = ({
  value, error, displayError, label, type, name, id, onChange, onBlur,
}) => (
  <label
    htmlFor={id}
    className="block text-sm font-medium text-gray-700"
  >
    {label}
    <div className="mt-1">
      <input
        id={id}
        name={name}
        type={type}
        autoComplete="current-password"
        required
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
    </div>
    <div className="h-5">
      {displayError && <span className="text-xs text-red-400">{error}</span>}
    </div>
  </label>
);
LoginInput.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  displayError: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

LoginInput.defaultProps = {
  error: '',
};

export default LoginInput;
