import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

export const exportSpreadsheet = (data, fileName, fileType) => {
  const worksheet = utils.aoa_to_sheet(data);
  const newWorkbook = utils.book_new();

  utils.book_append_sheet(newWorkbook, worksheet, 'Sheet 1');

  const ws = newWorkbook.Sheets.Sheet1;

  if (fileType === 'xlsx') {
    utils.sheet_add_aoa(ws, [[`Created ${new Date().toISOString()}`]], { origin: -1 });
  } else if (fileType === 'csv') {
    utils.sheet_to_csv(ws);
  } else {
    throw new Error('Wrong file extension! It must be "xlsx" or "csv".');
  }

  writeFile(newWorkbook, `${fileName}.${fileType}`);
};

export const exportSVG = (htmlElement, fileName) => {
  const head = `<svg title="${fileName}" version="1.1" xmlns="http://www.w3.org/2000/svg">`;
  const completeSVG = `${head}${htmlElement.innerHTML}</svg>`;
  const blob = new Blob([completeSVG], { type: 'image/svg+xml' });

  saveAs(blob, `${fileName}.svg`);
};

export const exportPNG = (htmlElement, fileName) => {
  html2canvas(htmlElement).then((canvas) => {
    canvas.toBlob((blob) => {
      saveAs(blob, `${fileName}.png`);
    });
  });
};
