import PropTypes from 'prop-types';
import {
  BarChart,
  Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, Legend, ReferenceArea,
} from 'recharts';
import getColor from 'utils/colors';
import ReportChartYAxis from './ReportChartYAxis';
import ReportChartTooltip from './ReportChartTooltip';
import ReportChartXAxis from './ReportChartXAxis';
import ReportChartOverlay from './ReportChartOverlay';

const defaultBarGap = 5;

const ReportChartBar = ({ chartData, properties, xAxisLabel }) => {
  let transformedChartData = chartData[0].data.map((item) => ({ name: item.key }));

  transformedChartData = transformedChartData.map((item, itemIndex) => Object.assign(
    ...chartData.map((value, valueIndex) => (
      {
        ...item,
        [`value_${valueIndex}`]: chartData[valueIndex].data[itemIndex].value,
        [`unit_${valueIndex}`]: value.units,
      })),
  ));

  return (
    <div className="h-72 py-6 relative">

      {!transformedChartData.length
        && <ReportChartOverlay label="No data available" />}

      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={transformedChartData}
          barGap={properties[0]?.barGap || defaultBarGap}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >

          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            dataKey="name"
            tick={<ReportChartXAxis />}
          >
            <Label value={xAxisLabel} offset={-2} position="insideBottomLeft" fill="#666" fontSize={13} />
          </XAxis>

          <YAxis
            tick={<ReportChartYAxis />}
            label={{
              value: chartData[0].units, fill: '#666', angle: -90, fontSize: 13, position: 'insideLeft',
            }}
          />

          {properties[0]?.referenceAreas && properties[0]?.referenceAreas.map(({
            y1, y2, fill,
          }) => (
            <ReferenceArea
              y1={y1}
              y2={y2}
              fill={fill}
              fillOpacity={0.2}
              ifOverflow="extendDomain"
            />
          ))}

          <Legend />

          <Tooltip content={<ReportChartTooltip />} cursor={{ opacity: 0.1 }} />

          {properties.map((property, index) => (
            <Bar
              key={`bar_${property.label}`}
              dataKey={`value_${index}`}
              name={property.label}
              unit={chartData[index].units}
              fill={property.color || getColor(index)}
              stackId={property.stackId || null}
              barSize={property.barSize || null}

            />
          ))}

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReportChartBar;

ReportChartBar.propTypes = {
  chartData: PropTypes.object.isRequired,
  properties: PropTypes.array.isRequired,
  xAxisLabel: PropTypes.string,
};

ReportChartBar.defaultProps = {
  xAxisLabel: null,
};
