import { t } from 'i18next';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ExternalLinkIcon } from '@heroicons/react/outline';

const isExternalUrl = (string) => (/(http(s?)):\/\//i.test(string));

const SidebarItem = ({ item }) => {
  if (isExternalUrl(item.href)) {
    return (
      <a
        key={t(item.name)}
        href={item.href}
        target="_blank"
        className="group flex items-center px-3 py-3 text-sm font-medium rounded-md text-white/80 hover:bg-white/10"
        rel="noreferrer"
      >
        <div className="flex w-full">
          {
            item.icon
            && (
              <span>
                <item.icon
                  className="mr-3 flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
              </span>
            )
          }

          {item.svg
            && (
              <span className="mr-3 flex-shrink-0 h-5 w-5">
                {item.svg && (item.svg)}
              </span>
            )}
          <span className="flex-grow">
            {t(item.name)}
          </span>

          <ExternalLinkIcon className="w-4 text-white/60" />
        </div>
      </a>
    );
  }
  return (
    <NavLink
      key={t(item.name)}
      to={item.href}
      className={({ isActive }) => classNames(
        isActive
          ? 'bg-gray-100/30 text-white'
          : 'text-white/80 hover:bg-white/10',
        'group flex items-center px-3 py-3 text-sm font-medium rounded-md',
      )}
    >
      {item.icon
        && (
          <item.icon
            className="mr-3 flex-shrink-0 h-5 w-5"
            aria-hidden="true"
          />
        )}
      {item.svg
        && (
          <span className="mr-4 flex-shrink-0 h-4 w-4">
            {item.svg && (item.svg)}
          </span>
        )}
      {t(item.name)}
    </NavLink>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default SidebarItem;
