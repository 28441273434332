import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';

const useAreaUnit = () => {
  const { region } = useSelector(getUser);

  // Note from Michael
  // The JWT isn't currently returning the user's unit system (metric/US).
  // In this case, we'll determine the user's preference from their region for the time being
  // If regional === NAC - return ft2, otherwise m2
  // This should be refactored accordingly in the future

  const US_UNIT_REGION = 'NAC';

  if (region === US_UNIT_REGION) {
    return (
      <span>
        ft
        <sup>2</sup>
      </span>
    );
  }
  return (
    <span>
      m
      <sup>2</sup>
    </span>
  );
};

export default useAreaUnit;
