import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const TabBarItem = ({ href, children }) => (
  <NavLink
    to={href}
    className={({ isActive }) => classNames('whitespace-nowrap py-4 px-1 border-b-2 font-medium text cursor-pointer', {
      'border-sky-500 text-highlight-primary': isActive,
      'border-transparent text-base-secondary hover:text-gray-700 hover:border-gray-300': !isActive,
    })}
  >
    {children}
  </NavLink>
);

TabBarItem.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default TabBarItem;
