import PropTypes from 'prop-types';
import Pagination from 'components/Pagination/Pagination';
import {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import Modal from 'components/Modal/Modal';
import { t } from 'i18next';
import useOnUserInteractionPause from 'hooks/useOnUserInteractionPause';
import useUserId from 'hooks/useUserId';
import DataGridTable from './DataGridTable/DataGridTable';
import DataGridHeader from './DataGridHeader/DataGridHeader';
import DataGridContext from './DataGridContext';
import DataGridDetails from './DataGridDetails';
import DataGridItemChangeModal from './DataGridItemChangeModal/DataGridItemChangeModal';
import DataGridDeleteConfirmModal from './DataGridDeleteConfirmModal';
import DataGridShareModal from './DataGridShareModal/DataGridShareModal';
import { AUTOFETCH_INTERVAL } from './DataGridConstants';
import DataGridInlineFilter from './DataGridInlineFilter/DataGridInlineFilter';
import DataGridContactModal from './DataGridContactModal';

const getInitialSortOrder = (properties) => {
  const propWithDefaultSortOrder = properties.find((prop) => !!prop.defaultSortOrder);

  return propWithDefaultSortOrder ? `${propWithDefaultSortOrder.key}:${propWithDefaultSortOrder.defaultSortOrder}` : '';
};

const getQueryParamsInitialState = (itemsPerPage, dataGridProperties) => ({
  limit: itemsPerPage,
  offset: 0,
  search: '',
  sort: getInitialSortOrder(dataGridProperties),
  filter: '',
});

const DataGrid = ({
  title,
  properties,
  detailsProperties,
  inlineFilterProperties,
  isPagination,
  isContact,
  isExport,
  isSearch,
  isLink,
  isFilter,
  isInlineFilter,
  isCreate,
  isEdit,
  isDelete,
  isShare,
  shareType,
  itemsPerPage,
  isInviteUser,
  itemActionLabel = t('DataGrid.defaultItemActionLabel'),
  informationMessage,
  lazyFetchDataHook,
  displayModalOnItemClick,
  onItemCreate,
  onItemEdit,
  onItemDelete,
  autoUpdate,
}) => {
  const [selectedTab, setSelectedTab] = useState(properties[0]);
  const [queryParams, setQueryParams] = useState(
    getQueryParamsInitialState(itemsPerPage, properties[0].dataGridProps),
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isItemChangeModalOpen, setIsItemChangeModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const [itemToChange, setItemToChange] = useState(null);
  const paginationRef = useRef();
  const dataGridContainerElement = useRef();
  const [trigger, result] = lazyFetchDataHook();
  const userId = useUserId();

  const onPageChangeHandler = (newPageNumber) => {
    const offset = itemsPerPage * newPageNumber - itemsPerPage;

    setQueryParams({ ...queryParams, offset });
  };

  const onSearchChange = (search) => {
    setQueryParams({
      ...getQueryParamsInitialState(itemsPerPage, selectedTab.dataGridProps),
      sort: queryParams.sort,
      search,
    });
    paginationRef.current.resetPagination();
  };

  const onSortOrderChange = (prop, sortOrder) => {
    setQueryParams({ ...queryParams, sort: `${prop}:${sortOrder}` });
  };

  const onFilterChange = (filters) => {
    setQueryParams({
      ...queryParams,
      filter: filters.reduce((acc, filter) => (
        filter.value ? [...acc, `${filter.key}[${filter.operator.value}]:${filter.value}`] : acc
      ), []).join(','),
    });
  };

  const onTabChange = (tab) => {
    if (tab.name !== selectedTab.name) {
      setQueryParams(getQueryParamsInitialState(itemsPerPage, tab.dataGridProps));
      setSelectedTab(tab);
    }
  };

  const onItemClickHandler = (item) => {
    if (displayModalOnItemClick) {
      setSelectedItem(item);
      setIsDetailsModalOpen(true);
    } else {
      selectedTab.onItemClick(item);
    }
  };

  const triggerDataFetch = async () => {
    await trigger({
      queryParams,
      userId,
      tabUrlSlug: selectedTab?.urlSlug,
    });
  };

  const onAddButtonClickHandler = () => {
    setIsItemChangeModalOpen(true);
  };

  const onItemChangeModalClose = () => {
    setItemToChange(null);
    setIsItemChangeModalOpen(false);
  };

  const onItemCreateHandler = (newItem) => {
    itemToChange
      ? isEdit && onItemEdit(newItem, triggerDataFetch)
      : isCreate && onItemCreate(newItem, triggerDataFetch);

    onItemChangeModalClose();
  };

  const onItemEditHandler = (item) => {
    setItemToChange(item);
    setIsItemChangeModalOpen(true);
  };

  const onItemContactHandler = (item) => {
    setItemToChange(item);
    setIsContactModalOpen(true);
  };

  const onItemDeleteHandler = (item) => {
    setItemToChange(item);
    setIsDeleteConfirmationModalOpen(true);
  };

  const onItemShareHandler = (item) => {
    setItemToChange(item);
    setIsShareModalOpen(true);
  };

  const onDeleteConfirmHandler = () => {
    onItemDelete(itemToChange, triggerDataFetch);
    setIsDeleteConfirmationModalOpen(false);
    setItemToChange(null);
  };

  const dataGridContextValue = useMemo(() => ({
    title,
    gridData: result.isSuccess ? result.data?.data : [],
    properties,
    selectedTab,
    isFetching: result.isFetching,
    queryParams,
    lazyFetchDataHook,
    itemActionLabel,
    displayModalOnItemClick,
    isEdit,
    isLink,
    isDelete,
    isShare,
    shareType,
    isContact,
    isInviteUser,
    onItemClick: (!!selectedTab.onItemClick || displayModalOnItemClick) && onItemClickHandler,
    onSortOrderChange,
    onFilterChange,
    onTabChange,
    onItemEditHandler,
    onItemContactHandler,
    onItemDeleteHandler,
    onItemShareHandler,
  }), [result, selectedTab, queryParams]);

  useEffect(() => {
    triggerDataFetch();
  }, [queryParams, userId, selectedTab]);

  autoUpdate && useOnUserInteractionPause(dataGridContainerElement, () => {
    triggerDataFetch();
  }, AUTOFETCH_INTERVAL, [queryParams, userId, selectedTab]);

  return (
    <DataGridContext.Provider
      value={dataGridContextValue}
    >
      <div className="py-6" ref={dataGridContainerElement}>
        <DataGridHeader
          isSearch={isSearch}
          isExport={isExport}
          isFetching={result.isFetching}
          isFilter={isFilter}
          isCreate={isCreate}
          isInviteUser={isInviteUser}
          onSearchChange={onSearchChange}
          onAddButtonClick={onAddButtonClickHandler}
        />

        {isInlineFilter
          && (
            <DataGridInlineFilter
              onUpdate={(filters) => onFilterChange(filters)}
              properties={inlineFilterProperties}
            />
          )}

        <DataGridTable
          informationMessage={informationMessage}
        />

        {isPagination && (
          <Pagination
            totalItems={result.data?.count || 0}
            itemsPerPage={itemsPerPage}
            onChange={onPageChangeHandler}
            maxNumberOfTabs={7}
            ref={paginationRef}
          />
        )}
      </div>

      <Modal open={isDetailsModalOpen} setOpen={() => setIsDetailsModalOpen(false)}>
        <DataGridDetails properties={detailsProperties} selectedItem={selectedItem} />
      </Modal>

      <DataGridItemChangeModal
        open={isItemChangeModalOpen}
        onClose={onItemChangeModalClose}
        onItemCreate={onItemCreateHandler}
        itemToChange={itemToChange}
      />

      <DataGridDeleteConfirmModal
        open={isDeleteConfirmationModalOpen}
        itemName={itemToChange?.name}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={onDeleteConfirmHandler}
      />

      <DataGridShareModal
        open={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        itemName={itemToChange?.name}
        itemId={itemToChange?.id}
        shareType={shareType}
      />

      <DataGridContactModal
        open={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        itemName={itemToChange?.name}
      />

    </DataGridContext.Provider>
  );
};

DataGrid.propTypes = {
  title: PropTypes.string,
  properties: PropTypes.array.isRequired,
  detailsProperties: PropTypes.array,
  inlineFilterProperties: PropTypes.array,
  isContact: PropTypes.bool,
  isSearch: PropTypes.bool,
  isExport: PropTypes.bool,
  isPagination: PropTypes.bool,
  isFilter: PropTypes.bool,
  isLink: PropTypes.bool,
  isInlineFilter: PropTypes.bool,
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  isShare: PropTypes.bool,
  shareType: PropTypes.string,
  isInviteUser: PropTypes.bool,
  displayModalOnItemClick: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  itemActionLabel: PropTypes.string,
  lazyFetchDataHook: PropTypes.func.isRequired,
  autoUpdate: PropTypes.bool,
  informationMessage: PropTypes.string,
  onItemCreate: PropTypes.func,
  onItemEdit: PropTypes.func,
  onItemDelete: PropTypes.func,
};

DataGrid.defaultProps = {
  title: null,
  detailsProperties: null,
  inlineFilterProperties: null,
  isContact: false,
  isSearch: false,
  isExport: false,
  isPagination: false,
  isFilter: false,
  isLink: false,
  isInlineFilter: false,
  isCreate: false,
  isEdit: false,
  isDelete: false,
  isShare: false,
  shareType: '',
  isInviteUser: false,
  displayModalOnItemClick: false,
  itemsPerPage: 10,
  itemActionLabel: t('DataGrid.defaultItemActionLabel'),
  autoUpdate: false,
  informationMessage: t('DataGrid.noDataAvailable'),
  onItemCreate: () => { },
  onItemEdit: () => { },
  onItemDelete: () => { },
};

export default DataGrid;
