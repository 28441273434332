import useFormat from 'hooks/useFormat';
import PropTypes from 'prop-types';

const DataGridDetails = ({ properties, selectedItem }) => {
  const { format } = useFormat();

  return (
    <div className="my-6 mx-4">
      <div className="mt-6 max-w-5xl mx-auto">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          {properties.map(({
            key, label, dataType, unit,
          }) => (
            <div key={key} className="sm:col-span-1">
              <dt className="text-sm font-medium text-base-secondary">{label}</dt>
              <dd className="mt-1 text-sm">
                {selectedItem?.[key] ? (
                  <span className="text-gray-900">
                    {format(selectedItem?.[key], dataType)}
                    {' '}
                    <span className="text-gray-600">{unit}</span>
                  </span>
                ) : <span>-</span>}
              </dd>
            </div>
          ))}
        </dl>
      </div>

    </div>
  );
};

DataGridDetails.propTypes = {
  properties: PropTypes.array,
  selectedItem: PropTypes.object,
};

DataGridDetails.defaultProps = {
  properties: [],
  selectedItem: {},
};

export default DataGridDetails;
