import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';
import { useLazyGetIsUserFirstTimeQuery } from 'store/services/api';
import Button from '../Button/Button';

const FirstTimeModal = ({
  open, onClose,
}) => {
  const onCloseButtonClickHandler = () => {
    alert('Please select an action from the pop-up');
  };

  const [trigger] = useLazyGetIsUserFirstTimeQuery();

  const triggerFirstTime = async () => {
    await trigger();
    onClose();
    window.location.reload(false);
  };
  return (
    <Modal open={open} setOpen={onCloseButtonClickHandler}>
      <div className="px-2">
        <div className="grid grid-cols-2 gap-2">
          <img src="/images/guardian-bim.png" width={150} alt="Guardian" />
          <img src="/images/autodesk-revit.png" width={150} alt="Guardian" />
        </div>
      </div>

      <h6 className="font-bold text-base-primary pb-2 text-l pl-2 text-center">In order to use Guardian BIM you must be a Revit User. If you are a Revit User, or want to learn more: </h6>
      <div className="pt-2 flex justify-center">
        <Button
          className="group flex items-center px-3 py-3 text-sm font-medium rounded-md text-white/80 hover:bg-white/10"
          onClick={() => triggerFirstTime()}
          type="button"
        >
          Continue to Guardian Glass for BIM
        </Button>
      </div>
      <h6 className="font-bold text-base-primary pb-2 text-l pl-2 pt-2 text-center">
        If you are not a Revit User you can
        <a href="https://www.guardianglass.com/eu/en/portal/home" className="underline" target="_blank" rel="noopener noreferrer"> click here </a>
        to access Guardian Glass Resource Hub
      </h6>

    </Modal>
  );
};

FirstTimeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FirstTimeModal;
