import { t } from 'i18next';
import Seo from 'components/Seo/Seo';
import { Outlet } from 'react-router-dom';

const PasswordRecovery = () => (
  <>
    <Seo title={t('recovery.passwordRecovery')} />

    <div className="h-screen">
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-cover bg-login-image bg-no-repeat">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default PasswordRecovery;
