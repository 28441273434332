import PropTypes from 'prop-types';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceArea,
} from 'recharts';
import ReportChartYAxis from './ReportChartYAxis';
import ReportChartXAxis from './ReportChartXAxis';
import ReportChartTooltip from './ReportChartTooltip';

const ReportChartLine = ({ chartData, properties }) => (
  <div className="h-72 py-6">
    <ResponsiveContainer>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" allowDuplicatedCategory={false} tick={<ReportChartXAxis />} />

        <YAxis
          tick={<ReportChartYAxis />}
          label={{
            value: chartData[0].units, fill: '#666', angle: -90, fontSize: 13,
          }}
        />

        <Tooltip content={<ReportChartTooltip />} />

        {properties[0]?.referenceAreas && properties[0]?.referenceAreas.map(({
          y1, y2, fill,
        }) => (
          <ReferenceArea
            key={`referenceArea_${y1}`}
            y1={y1}
            y2={y2}
            fill={fill}
            fillOpacity={0.2}
            ifOverflow="extendDomain"
          />
        ))}

        <Legend />

        {chartData.map(({
          name, data, units,
        }) => (
          <Line
            type="monotone"
            key={name}
            dataKey="value"
            name={name}
            data={data}
            unit={units}
            strokeWidth={2}
          />
        ))}

      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default ReportChartLine;

ReportChartLine.propTypes = {
  chartData: PropTypes.array.isRequired,
  properties: PropTypes.object.isRequired,
};
