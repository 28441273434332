const MetricsSkeleton = () => (
  <>
    <div className="p-6 flex flex-col gap-2 w-full md:w-1/4 animate-pulse h-28 justify-center">
      <div className="block w-1/2 h-4 bg-gray-300 rounded" />
      <div className="block w-4/5 h-4 bg-gray-300 rounded" />
    </div>

    <div className="p-6 flex flex-col gap-2 w-full md:w-1/4 animate-pulse h-28 justify-center">
      <div className="block w-1/2 h-4 bg-gray-300 rounded" />
      <div className="block w-4/5 h-4 bg-gray-300 rounded" />
    </div>

    <div className="p-6 flex flex-col gap-2 w-full md:w-1/4 animate-pulse h-28 justify-center">
      <div className="block w-1/2 h-4 bg-gray-300 rounded" />
      <div className="block w-4/5 h-4 bg-gray-300 rounded" />
    </div>

    <div className="p-6 flex flex-col gap-2 w-full md:w-1/4 animate-pulse h-28 justify-center">
      <div className="block w-1/2 h-4 bg-gray-300 rounded" />
      <div className="block w-4/5 h-4 bg-gray-300 rounded" />
    </div>
  </>
);

export default MetricsSkeleton;
