import { createSlice } from '@reduxjs/toolkit';
import { resetApiState } from 'store/services/api';

const initialState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      const user = JSON.parse(atob(payload.split('.')[1]));
      return {
        ...user,
        firstName: user.firstname,
        lastName: user.lastname,
        accessToken: payload,
        currency: 'USD', // Hardcoded for now
      };
    },
    clearUserToken: (state) => {
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetApiState, () => initialState);
  },
});

export const { setUser, clearUserToken } = userSlice.actions;

export default userSlice.reducer;

// Selectors

export const getUser = ({ user }) => user;

export const isUserAuthenticated = ({ user }) => user && user.accessToken;
