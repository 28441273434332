import PropTypes from 'prop-types';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DropdownMenuItem from 'components/DropdownMenu/DropdownMenuItem';
import { ChevronDownIcon } from '@heroicons/react/solid';

const DataGridFilteringModalOperatorDropdown = ({
  operators, selectedOperator, onItemClick, isNumber,
}) => (
  isNumber ? (
    <DropdownMenu
      position="below"
      menuButton={(
        <span className="w-16 h-11 px-3 flex border justify-center items-center border-gray-200 rounded-md">
          {selectedOperator.layout}
          {isNumber && (
          <ChevronDownIcon
            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
          />
          )}
        </span>
      )}
    >
      {operators.map((operator) => (
        <DropdownMenuItem
          key={operator.layout}
          onItemClick={() => onItemClick(operator)}
        >
          {operator.selectLayout}
        </DropdownMenuItem>
      ))}
    </DropdownMenu>
  ) : (
    <span className="w-16 h-11 px-3 flex border justify-center items-center border-gray-200 rounded-md">
      {operators[0].layout}
    </span>
  ));

DataGridFilteringModalOperatorDropdown.propTypes = {
  operators: PropTypes.array.isRequired,
  selectedOperator: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
  isNumber: PropTypes.bool,
};

DataGridFilteringModalOperatorDropdown.defaultProps = {
  isNumber: false,
};

export default DataGridFilteringModalOperatorDropdown;
