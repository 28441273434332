import { useEffect, useState } from 'react';
import Accordion from 'components/Accordion/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';
import AccordionReportContent from 'components/Accordion/AccordionReportContent';
import {
  useLazyGetProjectGlazingQuery,
  useLazyGetSolidGlazedAreasQuery,
  useLazyGetSurfaceFacadeHeatgainAverageQuery,
} from 'store/services/api';
import { REPORT_BARCHART, REPORT_LINECHART } from 'components/ReportChart/constants';
import { useParams } from 'react-router-dom';
import AlertBar from 'components/AlertBar/AlertBar';
import { t } from 'i18next';
import useVendorLogo from 'hooks/useVendorLogo';
import AccordionReportContentSwitch from 'components/Accordion/AccordionReportContentSwitch';

const ReportsProjectMaterial = () => {
  const { id } = useParams();

  const [trigger, result] = useLazyGetProjectGlazingQuery();

  const triggerDataFetch = async () => {
    await trigger({ id });
  };

  useEffect(() => {
    if (!result.data) {
      triggerDataFetch();
    }
  }, [id]);

  const [showMore, setShowMore] = useState(false);
  const seeMoreLimit = 3;
  let glasses = [];
  if (result.isSuccess) {
    glasses = showMore ? result.data : result.data.slice(0, seeMoreLimit);
  }

  const horizontalMetic = (label, value) => (
    <div className="flex gap-x-2">
      <span className="w-1/2 text-right text-white/80">
        {label}
        :
      </span>
      <span className="w-1/2">{value}</span>
    </div>
  );

  const verticalMetric = (label, value) => (
    <div className="flex flex-col gap-x-2 mb-4">
      <span className="text-white/80">
        {label}
        :
      </span>
      <span className="break-all">{value}</span>
    </div>
  );

  return (
    <div className="py-6">

      <h2 className="font-bold text-slate-600 mb-4">
        {t('reports.glazingSpecification.glazingSpecification')}
      </h2>

      {/* Not ideal. The API is returning a 404 when there is no glazing
      for a project.  */}
      {result.isError && <AlertBar label="No glazing for this project" />}

      {result.isFetching && (
        <div className="bg-white flex h-40 rounded mb-6 p-6 animate-pulse">
          <div className="bg-gray-400 h-6 w-4/5 rounded" />
        </div>
      )}

      {glasses && glasses.map((item) => {
        const vendorLogo = useVendorLogo(item.vendor);
        return (
          <div key={item.id} className={item.vendor === 'guardian' ? 'bg-blue-600 rounded-md p-6 mb-6 flex text-white text-xs gap-x-8' : 'bg-[#ffcc42] rounded-md p-6 mb-6 flex text-white text-xs gap-x-8'}>

            {item.vendor === 'guardian'
              && (
                <div className="w-1/3">
                  {vendorLogo}
                  {verticalMetric('Description', item.desc)}
                </div>
              )}

            {item.vendor !== 'guardian'
              && (
                <div className="w-1/3">
                  <h1 className="pb-8">Custom Glazing</h1>
                  {verticalMetric('Description', item.desc)}
                </div>
              )}

            <div className="w-1/3 flex flex-col gap-4">
              <p className="font-bold">{t('reports.glazingSpecification.glazingPerformanceInformation')}</p>

              {horizontalMetic('U-value', item.uval)}
              {horizontalMetic('Visual Light Transmittance', item.vlt)}
              {horizontalMetic('Solar heat gain coefficient', item.shgc)}
              {horizontalMetic('Calculation Standard', item.calc_standard)}
            </div>

            <div className="w-1/3 overflow-hidden">
              {verticalMetric('Facades', item.facades)}
            </div>
          </div>
        );
      })}

      {result.isSuccess && result.data.length > seeMoreLimit
        && (
          <button
            type="button"
            className="text-slate-700 hover:underline text-center mb-8 w-full"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? t('general.seeLess') : t('general.seeMore')}
          </button>
        )}

      <Accordion>
        <AccordionItem label={t('reports.glazingSpecification.solidAndGlazedAreas')} id="facade">
          <AccordionReportContentSwitch
            key="otherone"
            properties={[
              {
                label: t('reports.performance.orientations'),
                component: <AccordionReportContent
                  key="solidAndGlazedAreasOrientationChart"
                  lazyFetchDataHook={useLazyGetSolidGlazedAreasQuery}
                  queryParams={{ mode: 'orientation' }}
                  properties={[
                    {
                      label: 'Area Total', color: '#AAA', barSize: 120, barGap: -120,
                    },
                    { label: 'Area Glazed', barSize: 120 },
                  ]}
                  displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                  toggleTableOption
                />,
              },
              {
                label: t('reports.performance.facades'),
                component: <AccordionReportContent
                  key="solidAndGlazedAreasFacadeChart"
                  lazyFetchDataHook={useLazyGetSolidGlazedAreasQuery}
                  queryParams={{ mode: 'facade' }}
                  properties={[
                    {
                      label: 'Area Total', color: '#999', barSize: 30, barGap: -30,
                    },
                    { label: 'Area Glazed', barSize: 30 },
                  ]}
                  displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                  toggleTableOption
                />,
              },
            ]}
          />

        </AccordionItem>
        <AccordionItem label={t('reports.glazingSpecification.glassToWallRatio')} id="space">

          <AccordionReportContentSwitch
            key="otherone"
            properties={[
              {
                label: t('reports.performance.orientations'),
                component: <AccordionReportContent
                  key="item1"
                  lazyFetchDataHook={useLazyGetSurfaceFacadeHeatgainAverageQuery}
                  queryParams={{ mode: 'orientation' }}
                  properties={[
                    { label: t('reports.glazingSpecification.percentageGlazing') },
                  ]}
                  displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                  toggleTableOption
                />,
              },
              {
                label: t('reports.performance.facades'),
                component: <AccordionReportContent
                  key="item2"
                  lazyFetchDataHook={useLazyGetSurfaceFacadeHeatgainAverageQuery}
                  queryParams={{ mode: 'facade' }}
                  properties={[
                    { label: t('reports.glazingSpecification.percentageGlazing') },
                  ]}
                  displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                  toggleTableOption
                />,
              },
            ]}
          />

        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ReportsProjectMaterial;
