import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUnauthenticatedQuery, serviceUrl } from './settings';

export const loginApi = createApi({
  reducerPath: 'loginApi',
  baseQuery: baseUnauthenticatedQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: serviceUrl.login,
        method: 'POST',
        body: credentials,
      }),
    }),

    requestPasswordReset: builder.mutation({
      query: (email) => ({
        url: serviceUrl.forgot,
        method: 'POST',
        body: { email },
      }),
    }),

    getSsoAuthToken: builder.query({
      query: (code) => ({
        url: `${serviceUrl.login}/sso/${code}`,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRequestPasswordResetMutation,
  useGetSsoAuthTokenQuery,
} = loginApi;
