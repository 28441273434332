export const translation = {
  appTitle: 'Fenestra Pro',
  general: {
    signout: 'Sign Out',
    viewProfile: 'View Profile',
    help: 'Help',
    seeMore: 'See More',
    seeLess: 'See Less',
  },
  dashboard: {
    title: 'Dashboard',
    globalTitle: 'Global',
    welcome: 'Welcome',
    download: 'Download',
    amsDataGrid: 'ASM Data',
    chartTitle: 'Timeseries Chart',
    global: {
      chartTitle: 'Regional Managers',
    },
  },
  globalDashboard: {
    title: 'Global',
    regionalManagers: 'Regional Managers',
    inviteUser: 'Invite Regional Manager',
  },
  regionalDashboard: {
    title: 'Regional',
    listAsms: 'List of ASM/ADMs',
    unassignedPassive: 'Unassigned / Passive Users & Projects',
    inviteUser: 'Invite ASM',
  },
  asmDashboard: {
    title: 'ASM',
    assignedUsersProjects: 'Assigned Users & Projects',
    inviteUser: 'Invite User',
  },
  designerDashboard: {
    title: 'Designer',
    users: 'Users',
    projects: 'Projects',
    reports: 'Reports',
    inviteUser: 'Invite User',
    meta: {
      name: 'Name',
      name_of_firm: 'Firm',
      role: 'Role',
      email: 'Email',
      location: 'Region',
      last_login: 'Last login',
      no_projects: 'No. of projects',
      area: 'Total Area Glazing',
    },
  },
  InviteUserModal: {
    inviteUser: 'Invite User',
    inviteSuccess: 'User invited successfully',
    inviteError: 'Could not invite user. Please try again',
    inviteAction: 'Invite user',
  },
  managerDashboard: {
    title: 'Manager',
    salesLeads: 'Sales Leads',
    interactions: 'Interactions',
    timeseriesChartTitle: 'Chart title here',
  },
  reports: {
    title: 'Your Reports',
    dragAndDropNote: 'Drag and drop report options to begin',
    generate: 'Generate Report',
    projectKpis: 'Project KPIs',
    projectReports: 'Project reports',
    thirdPartyMacro: '3rd party macro reports',
    assemblySpecs: 'Glass Assembly Specs',
    geometry: {
      percentageGlazing: 'Percentage Glazing',
      totalAreaFacades: 'Total Area of Façades',
      totalAreaGlazing: 'Total Area of Glazing',
    },
    performance: {
      daylightFactor: 'Daylight Factor',
      uValue: 'U-value',
      solarHeatGain: 'Solar Heat Gain',
      orientations: 'Orientations',
      facades: 'Façades',
      annual: 'Annual',
    },
    glazingSpecification: {
      glazingSpecification: 'Glazing Specification',
      glazingPerformanceInformation: 'Glazing Performance Information',
      solidAndGlazedAreas: 'Solid and Glazed Areas',
      glassToWallRatio: 'Glass To Wall Ratio',
      percentageGlazing: '% Glazing',
      totalArea: 'Total Area',
    },
  },
  msi: {
    title: 'MSI Reports',
    addCompiler: 'Add to Compiler',
    timeseriesChartTitle: 'Chart name here',
  },
  projects: {
    title: 'Projects',
    id: 'ID',
    name: 'Name',
    type: 'Type',
    date: 'Date',
    author: 'Author',
    description: 'Description',
    updatedAt: 'Updated At',
    timeseriesChart: 'Design Timeline',
    dataGridTitle: 'Project Reports',
    assemblies: {
      title: 'Glazing Make Ups Specified',
      desc: 'Desc',
      area: 'Area',
      count: 'Count',
      shgc: 'SHGC',
      embodied: 'Embodied CO2 per m2',
      embodiedTotal: 'Total Embodied Carbon',
      vlt: 'VLT',
      uval: 'U-val',
    },
    meta: {
      name: 'Project name',
      no_users: 'No of users',
      location: 'Location',
      area_facade: 'Total area glazed',
      percentage_glazing: 'Percentage area glazed',
      last_user: 'Last user',
      last_session: 'Last session',
      total_carbon: 'Embodied Carbon for Guardian Glass (T CO2e)',
    },
  },
  resources: {
    title: 'Resources',
  },
  assemblies: {
    title: 'Guardian Glass Database of Standard Glazing Make-Ups',
    datagrid: 'Assemblies',
    tableHeader: {
      name: 'Name',
      description: 'Description',
      region: 'Region',
      standard: 'Standard',
      shgc: 'SHGC',
      uval: 'UVAL',
      vlt: 'VLT',
      desc: 'Description',
      vendor: 'Vendor',
      epd: 'EPD',
      productUrl: 'Product URL',
    },
    tabs: {
      standard: 'Standard',
      custom: 'Custom',
    },
  },
  codes: {
    title: 'Custom Regional Codes and Regulations',
    datagrid: 'Active Codes',
    dataGridHeader: {
      name: 'Name',
      floor: 'Floor',
      glass: 'Glass',
      roof: 'Roof',
      wall: 'Wall',
      percentGlazing: 'Glazing',
      creator: 'Creator',
      id: 'ID',
    },
  },
  DataGrid: {
    export: 'Export',
    searchPlaceholder: 'Search anything',
    paginationLabel:
      'Showing <1>{{name}}</1>, to <3>{{limit}}</3> of <4>{{totalItems}}</4> results',
    addFilter: 'Add filter',
    defaultItemActionLabel: 'View',
    noDataAvailable: 'No data available',
    noResultsFound: 'No results found',
    openProductUrl: 'Open Product URL',
    contact: {
      contact: 'Contact',
      name: 'Name',
      email: 'E-mail',
      topic: 'Practice',
      message: 'Message',
      sendEnquiry: 'Send Enquiry',
      enquirySent: 'Enquiry Sent',
      enquiryError: 'Error sending enquiry',
    },
    tableHeader: {
      global: {
        name: 'Name',
        email: 'E-mail',
        noAsms: 'No. of ASM/ADMs',
        noPassiveUsers: 'No. of Passive Users',
        region: 'Region',
      },
      regional: {
        name: 'Name',
        email: 'E-mail',
        noOrgs: 'No. of Firms',
        noProjects: 'No. of Projects',
        noUsers: 'No. of Users',
        noAssemblies: '# Assemblies',
        area: 'Area Glazing',
        noCourses: 'Courses Completed',
      },
      asm: {
        name: 'Name',
        country: 'Country',
        lastUser: 'Last User',
        noUniqueAssemblies: '# Unique Assemblies',
        noUsers: 'No. of Users',
        areaGlazed: 'Area',
        lastSession: 'Last Session',
        noCourses: 'Courses Completed',
      },
      manager: {
        projectName: 'Project Name',
        country: 'Country',
        lastUser: 'Last User',
        noUsers: 'No. of Users',
      },
      designer: {
        name: 'Name',
        country: 'Country',
        lastUser: 'Last User',
        noUsers: 'No. of Users',
        lastSession: 'Last Session',
        area: 'Area Glazing',
        percentGlazing: '% Glazed',
        solarload: 'Average Heat Gain',
        daylighting: 'Ave. Design Daylight',
        embodied: 'Embodied Carbon',
      },
      usersPassive: {
        name: 'Name',
        email: 'E-mail',
        platform: 'Platform',
        role: 'Role',
        noProjects: 'No. of Projects',
        noCourses: 'Courses Completed',
        area: 'Area Glazing',
        lastSession: 'Last Session',
      },
      users: {
        name: 'Name',
        email: 'E-mail',
        platform: 'Platform',
        role: 'Role',
        noProjects: 'No. of Projects',
        noCourses: 'Courses Completed',
        area: 'Area Glazing',
        lastSession: 'Last Revit Session',
        isMsiDownloaded: 'Downloaded',
      },
      projectsPassive: {
        name: 'Name',
        country: 'Country',
        lastUser: 'Last User',
        noAssemblies: '# Assemblies',
        noUsers: 'No. of Users',
        area: 'Area Glazing',
        lastSession: 'Last Session',
      },
      projects: {
        name: 'Name',
        country: 'Country',
        lastUser: 'Last User',
        noAssemblies: '# Assemblies',
        noUsers: 'No. of Users',
        area: 'Area Glazing',
        lastSession: 'Last Session',
      },
    },
    filterOperators: {
      eq: 'equals',
      lte: 'less than or equal',
      gte: 'greater than or equal',
    },
    tabs: {
      asms: 'ASM/ADMs',
      users: 'Users',
      projects: 'Projects',
      usersPassive: 'Passive Users',
      projectsPassive: 'Passive Projects',
    },
    addButton: 'Create Custom Code',
    importButton: 'Import Code',
    editButton: 'Edit',
    deleteButton: 'Delete',
    shareButton: 'Share',
    contactButton: 'Contact',
    itemChangeModal: {
      createItemTitle: 'New Item',
      updateItemTitle: 'Update Item',
      createItemButton: 'Create',
      updateItemButton: 'Update',
    },
    deleteConfirmationModal: {
      title: 'Are you sure you want to delete this item?',
      yes: 'Yes, Delete',
      no: 'No thanks',
    },
    shareModal: {
      title: 'Share: {{item}}',
      queryTitle: 'Glazing: {{item}}',
      user: 'User',
      team: 'Team',
      buttons: {
        shareWithUser: 'Share with user',
        shareWithTeam: 'Share with team',
        shareWithEmail: 'Share by email',
        users: 'Users',
        teams: 'Teams',
        email: 'Email',
      },
    },
  },
  TimeseriesChart: {
    export: 'Export',
    svg: 'SVG',
    png: 'PNG',
    labels: {
      solarload: 'Ave Solarload',
      daylighting: 'Ave Daylighting',
      assemblies: 'List of Assemblies',
      area: 'Area Glazed',
    },
  },
  toasterMessage: {
    success: {
      loginSuccessful: 'Login successful!',
      logoutSuccessful: 'Logout successful!',
      userInfo: 'User info was saved successfully!',
      codeCreation: 'Code created successfully',
      codeUpdate: 'Code updated successfully',
      codeDelete: 'Code deleted successfully',
      requestPassReset: 'Password recovery instructions sent.',
      msiDownloadSuccessfully: 'Success!',
    },
    error: {
      exportFailed: 'Export failed! Please try again.',
      loginFailed: 'Login failed! Please try again.',
      logoutFailed: 'Logout failed! Please try again.',
      unauthorizedUser: 'User is not authenticated. Please login again.',
      userInfo: 'Failed to save user info! Please try again.',
      codeCreation: 'Code creation failed! Please try again.',
      codeUpdate: 'Code update failed! Please try again.',
      codeDelete: 'Code delete failed! Please try again.',
      requestPassReset: 'Your request has failed. Please try again.',
      msiDownloadFailed: 'Failed! Please try again.',
    },
    info: {
      filterApplied_one: 'Filter applied',
      filterApplied_other: 'Filters applied',
      filtersRemoved_one: 'Filter removed',
      filtersRemoved_other: 'Filters removed',
    },
  },
  loginScreen: {
    header: 'Sign in',
    emailInputLabel: 'Email address',
    passInputLabel: 'Password',
    rememberMeLabel: 'Remember me',
    forgotYourPassword: 'Forgot your password?',
    signInButton: 'Sign in',
  },
  recovery: {
    passwordRecovery: 'Password Recovery',
    resetPassword: 'Reset Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    emailAddress: 'Email Address',
    requestReset: 'Request Password Reset',
  },
  validationErrors: {
    email: 'Email format is invalid',
    required: 'This field is required',
    tooLong: 'Input value is too long',
    tooShort: 'Input value is too short',
    passMatch: 'Passwords must match',
    weakPassword: 'Must contain an uppercase, a lowercase, a number and a special case character',
    number: 'Value must numeric',
  },
  sso: {
    redirecting: 'Redirecting',
    checking: 'Checking SSO',
    welcome: 'Welcome',
  },
  metrics: {
    from: 'from',
    by: 'by',
    learningPlatform: 'Learning Platform',
    of: 'of',
    completed: 'Completed',
    actions: {
      create_label: 'Create',
      create_desc: 'a bespoke glazing make up',

      learn_label: 'Access Learning',
      learn_desc: 'on how to use applications',

      specify_label: 'Invite Colleagues',
      specify_desc: 'to user Guardian Glass for BIM',

      analyse_label: 'View Reports',
      analyse_desc: 'to review performance data',
    },
  },
  events: {
    header: 'Events',
    noItems: 'No Events',
  },
  Sidebar: {
    download: 'Download Latest Version',
    bim: 'Introduction to Guardian Glass for BIM',
    hub: 'Return to Guardian Glass Resource Hub',
    home: 'Dashboard',
    webTools: 'Resource Hub',
    bimTools: 'BIM Tools',
    resources: 'Resources',
    performanceCalculator: 'Performance Calculator',
    buildingEnergyCalculator: 'Building Energy Calculator',
    glassVisualiser: 'Glass Visualiser',
    sustainabilityCalculator: 'Sustainability Calculator',
    thermalStressAnalysis: 'Thermal Stress Analysis',
    parametricAnalysis: 'Parametric Analysis',
    intro: 'GG4BIM - Home',
    conceptual: 'Conceptual Design Tools',
    detailed: 'Detail Design Tools',
    thermal: 'Thermal Analysis Tools',
    modelAuditor: 'GG4BIM - Model Auditor',
    yourReports: 'Your Reports',
    yourProjects: 'Your Projects',
    yourGlazingDatabase: 'Your Glazing Database',
    learning: 'Learning',
    support: 'Support',
    interactions: 'Interactions',
    accousticAssistant: 'Acoustic Assistant',
  },
  alerts: {
    profile: {
      label: 'We\'re missing your first and/or last name',
      action: 'Update Profile',
    },
  },
  Profile: {
    title: 'User Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'E-mail',
    username: 'Username',
    numericSystem: 'Numeric System',
    userInformation: 'User Information',
    appPreferences: 'App Preferences',
    updateProfile: 'Update Profile',
    passwordSettings: 'Update Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    emailNotifications: 'Email Notifications',
    sharedReports: 'Shared Reports',
    sharedCodes: 'Shared Codes',
    sharedProjects: 'Shared Projects',
    sharedGlazing: 'Shared Glazing Makeup',
    metricSystem: 'Metric',
    imperialSystem: 'Imperial (US)',
  },
};
