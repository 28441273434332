import { useContext, useEffect, useState } from 'react';
import { classNames } from '../../utils/classNames';
import DataGridContext from './DataGridContext';

const Tabs = () => {
  const [tabs, setTabs] = useState([]);
  const { properties, onTabChange } = useContext(DataGridContext);

  const onTabClickHandler = (selectedTab, tabIndex) => {
    setTabs(tabs.map((tab, index) => ({
      ...tab,
      current: index === tabIndex,
    })));

    onTabChange(selectedTab);
  };

  useEffect(() => {
    setTabs(properties.map((tab, index) => ({
      ...tab,
      current: index === 0,
    })));
  }, [properties]);

  return tabs.length > 1 ? (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab, index) => (
              <option
                key={tab.name}
                onClick={() => onTabClickHandler(tab, index)}
              >
                {tab.name}
              </option>
            ))}
          </select>
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <div
              key={tab.name}
              role="tab"
              tabIndex={index}
              aria-hidden="true"
              className={classNames(
                tab.current
                  ? 'bg-sky-100 text-sky-700'
                  : 'text-base-secondary hover:text-gray-700',
                'px-3 py-2 font-medium text-sm rounded-md cursor-pointer',
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={() => onTabClickHandler(tab, index)}
            >
              {tab.name}
            </div>
          ))}
        </nav>
      </div>
    </div>
  ) : null;
};

export default Tabs;
