import { createContext } from 'react';

const DataGridContext = createContext({
  gridData: null,
  properties: null,
  selectedTab: null,
  tabsProperties: null,
  isFetching: false,
  queryParams: null,
  fetchDataHook: null,
  itemActionLabel: '',
  displayModalOnItemClick: false,
  isContact: false,
  isEdit: false,
  isLink: false,
  isDelete: false,
  isShare: false,
  isInviteUser: false,
  onItemClick: () => {},
  onSortOrderChange: () => {},
  onFilterChange: () => {},
  onTabChange: () => {},
  onItemEditHandler: () => {},
  onItemDeleteHandler: () => {},
  onItemShareHandler: () => {},
  onItemContactHandler: () => {},
});

export default DataGridContext;
