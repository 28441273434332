import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReportChartLine from 'components/ReportChart/ReportChartLine';
import ReportChartBar from 'components/ReportChart/ReportChartBar';
import ReportChartTable from 'components/ReportChart/ReportChartTable';
import {
  REPORT_LINECHART,
  REPORT_BARCHART,
  REPORT_TABLE,
  REPORT_BARCHART_ALTERNATE,
  REPORT_BARCHART_NAVIGATE,
} from 'components/ReportChart/constants';
import { ChartBarIcon, PresentationChartLineIcon, TableIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Spinner from 'components/Spinner/Spinner';
import ReportChartBarAlternate from 'components/ReportChart/ReportChartBarAlternate';
import ReportChartBarNavigate from 'components/ReportChart/ReportChartBarNavigate';

const AccordionReportContent = ({
  displayTypes, lazyFetchDataHook, properties, queryParams, toggleTableOption, tableProperties,
}) => {
  const { id } = useParams();

  const [activeDisplayType, setActiveDisplayType] = useState(displayTypes[0]);
  const [displayTable, setDisplayTable] = useState(false);

  const [trigger, result] = lazyFetchDataHook();

  const triggerDataFetch = async () => {
    await trigger({ id, queryParams });
  };

  useEffect(() => {
    if (!result.data) {
      triggerDataFetch();
    }
  }, [id]);

  const getDisplayType = () => {
    switch (activeDisplayType) {
      case REPORT_LINECHART:
        return (
          <ReportChartLine
            chartData={Array.isArray(result.data) ? result.data : [result.data]}
            labels={['Update Me']}
            properties={properties}
          />
        );
      case REPORT_BARCHART:
        return (
          <ReportChartBar
            chartData={Array.isArray(result.data) ? result.data : [result.data]}
            properties={properties}
          />
        );
      case REPORT_BARCHART_NAVIGATE:
        return (
          <ReportChartBarNavigate
            chartData={Array.isArray(result.data) ? result.data : [result.data]}
            properties={properties}
          />
        );
      case REPORT_BARCHART_ALTERNATE:
        return (
          <ReportChartBarAlternate
            chartData={result.data}
            properties={properties}
          />
        );
      case REPORT_TABLE:
      default:
        return (
          <ReportChartTable
            data={result.data}
            // fix dis...
            properties={['name', 'value']}
            labels={['Something Else']}
          />
        );
    }
  };

  const getDisplayTypeIcon = (type) => {
    switch (type) {
      case REPORT_LINECHART:
        return <PresentationChartLineIcon className="w-5 text-gray-500" />;
      case REPORT_BARCHART:
        return <ChartBarIcon className="w-5 text-gray-500" />;
      case REPORT_TABLE:
      default:
        return <TableIcon className="w-5 text-gray-500" />;
    }
  };

  if (result.isFetching) {
    return (
      <div className="py-4 h-72 row-start-2 col-span-2">
        <div className="flex items-center mx-14 my-5 h-48 bg-slate-100 border-l-2 border-b-2 border-slate-300 animate-pulse">
          <div className="w-full text-center"><Spinner isLoading /></div>
        </div>

      </div>
    );
  }

  if (result.isSuccess) {
    return (
      <>

        <div className="flex flex-row-reverse col-start-2 col-span-1 row-start-1 row-span-1 h-12 gap-4 items-center">
          {displayTypes.length > 1
            && (
              <fieldset>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    {displayTypes.map((type) => (
                      <button
                        type="button"
                        onClick={() => setActiveDisplayType(type)}
                        className={classNames('relative inline-flex items-center px-4 py-2 first:rounded-l-md first:border-r-0 last:rounded-r-md last:border-l-0 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', {
                          'bg-blue-100 hover:bg-blue-200': type === activeDisplayType,
                        })}
                      >
                        {getDisplayTypeIcon(type)}
                      </button>
                    ))}
                  </span>
                </div>
              </fieldset>
            )}

          {toggleTableOption
            && (
              <label className="text-gray-500 text-sm items-center">
                <input
                  type="checkbox"
                  checked={displayTable}
                  onChange={() => setDisplayTable(!displayTable)}
                  id="displayTable"
                  className="mr-2"
                />
                Display Table
              </label>
            )}
        </div>

        <div className="row-start-2 col-span-2 m-h-72">
          {getDisplayType()}

          {displayTable && result.data.map((table) => (
            <ReportChartTable
              data={table}
              key={`reportTable_${table.name}`}
              properties={tableProperties}
              labels={['Something Else']}
            />
          ))}
        </div>
      </>
    );
  }

  if (result.isSuccess && activeDisplayType === REPORT_LINECHART) {
    return <ReportChartLine chartData={[result.data]} labels={['Surface Area']} />;
  }

  if (result.isSuccess && activeDisplayType === REPORT_BARCHART) {
    return <ReportChartBar chartData={[result.data]} labels={['Something Else']} />;
  }

  if (result.isSuccess && activeDisplayType === REPORT_TABLE) {
    return (
      <ReportChartTable
        data={result.data}
        properties={['name', 'value']}
        labels={['Something Else']}
      />
    );
  }
};

export default AccordionReportContent;

AccordionReportContent.propTypes = {
  displayTypes: PropTypes.array.isRequired,
  lazyFetchDataHook: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
  queryParams: PropTypes.object.isRequired,
  toggleTableOption: PropTypes.bool,
  tableProperties: PropTypes.array,
};

AccordionReportContent.defaultProps = {
  toggleTableOption: false,
  tableProperties: ['key', 'value'],
};
