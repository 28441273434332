import PropTypes from 'prop-types';

const DataGridNotice = ({ label, icon }) => (
  <div className="flex bg-white rounded-lg shadow px-6 py-8 h-64 items-center justify-center">
    <div className="flex flex-col gap-4 items-center">
      {icon
        && <div className="text-center w-8 text-gray-600">{icon}</div>}
      <p className="text-sm text-gray-500 text-center font-medium">Currently No Data Available</p>
      <p className="text-sm text-gray-500 text-center font-medium">{label}</p>
    </div>
  </div>
);

DataGridNotice.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default DataGridNotice;
