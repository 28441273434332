import { OfficeBuildingIcon, UserAddIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const PageHeader = ({ title, showIcons }) => (
  <div className="flex justify-between">
    <h1 className="text-[#38c7ba] text-3xl pb-4 text-base-primary font-bold">
      {title}
    </h1>

    {showIcons
      && (
        <div className="flex gap-4">
          <span><UserAddIcon className="w-6 text-base-primary" /></span>
          <span><OfficeBuildingIcon className="w-6 text-base-primary" /></span>
        </div>
      )}
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showIcons: PropTypes.bool,
};

PageHeader.defaultProps = {
  showIcons: true,
};

export default PageHeader;
