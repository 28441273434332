import Button from 'components/Button/Button';
import { Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import { useRequestPasswordResetMutation } from 'store/services/login';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
});

const PasswordRecoveryRequest = () => {
  const [requestPasswordReset, { isLoading }] = useRequestPasswordResetMutation();
  const formikInitialValues = {
    email: '',
  };

  const onSubmitHandler = async ({ email }) => {
    try {
      const result = await requestPasswordReset(email);
      result.data ? toast.success(t('toasterMessage.success.requestPassReset')) : toast.error(t('toasterMessage.error.requestPassReset'));
    } catch {
      toast.error(t('toasterMessage.error.requestPassReset'));
    }
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ errors }) => (
        <>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mb-4 text-left text-2xl font-bold text-base-primary">{t('recovery.passwordRecovery')}</h2>
          </div>

          <Form className="space-y-6">

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('recovery.emailAddress')}
              </label>
              <div className="mt-1">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
                />
              </div>
            </div>

            <div>
              <Button
                isBlock
                disabled={Object.keys(errors).length > 0 || isLoading}
                type="submit"
                isLoading={isLoading}
              >
                {t('recovery.requestReset')}
              </Button>
            </div>

          </Form>
        </>
      )}
    </Formik>
  );
};

export default PasswordRecoveryRequest;
