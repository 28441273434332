import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import DataGridContext from '../DataGridContext';
import DataGridFilteringModalOperatorDropdown from './DataGridFilteringModalOperatorDropdown';
import { DATA_TYPES } from '../DataGridConstants';

const DataGridFilteringModal = ({ open, onClose }) => {
  const { selectedTab, title, onFilterChange } = useContext(DataGridContext);
  const [localFilters, setLocalFilters] = useState([]);
  const { dataGridProps } = selectedTab;

  const operators = [{
    selectLayout: t('DataGrid.filterOperators.eq'),
    layout: '=',
    value: 'eq',
  }, {
    selectLayout: t('DataGrid.filterOperators.lte'),
    layout: '<=',
    value: 'lte',
  }, {
    selectLayout: t('DataGrid.filterOperators.gte'),
    layout: '>=',
    value: 'gte',
  }];

  const onClearButtonClickHandler = () => {
    const resetFilters = localFilters.map((filter) => ({
      ...filter,
      value: '',
      operator: operators[0],
    }));
    setLocalFilters(resetFilters);
    onFilterChange(resetFilters);
    onClose();
    toast(t('toasterMessage.info.filtersRemoved', { count: localFilters.length }));
  };

  const onCloseButtonClickHandler = () => {
    onClose();
  };

  const onApplyButtonClickHandler = (event) => {
    event.preventDefault();
    onFilterChange(localFilters);
    onClose();
    toast(t('toasterMessage.info.filterApplied', { count: localFilters.length }));
  };

  const onFilterValueChangeHandler = (value, filterKey) => {
    setLocalFilters(localFilters.map((filter) => (
      filter.key === filterKey ? { ...filter, value } : filter
    )));
  };

  const onOperatorSelectHandler = (operator, filterKey) => {
    setLocalFilters(localFilters.map((filter) => (
      filter.key === filterKey ? { ...filter, operator } : filter
    )));
  };

  useEffect(() => {
    dataGridProps && setLocalFilters(dataGridProps.reduce((acc, property) => (
      property.filter ? [...acc, { ...property, value: '', operator: operators[0] }] : acc
    ), []));
  }, [selectedTab]);

  return (
    <Modal open={open} setOpen={onCloseButtonClickHandler}>
      <div className="px-2">
        <h1 className="font-bold text-base-primary pb-2 text-xl">{title}</h1>
        <form>
          {localFilters.map((filter) => (
            <div key={filter.key} className="text-gray-500 py-2">
              <div className="flex items-center gap-4">
                <div className="flex-1 py-2 px-4 h-11 border border-gray-200 rounded-md text-gray-700">{filter.label}</div>
                <DataGridFilteringModalOperatorDropdown
                  operators={operators}
                  selectedOperator={filter.operator}
                  onItemClick={(operator) => onOperatorSelectHandler(operator, filter.key)}
                  isNumber={filter?.dataType === DATA_TYPES.NUMBER}
                />
                <input
                  type={filter?.dataType === DATA_TYPES.NUMBER ? 'number' : 'text'}
                  placeholder="Value"
                  className="flex-1 w-full h-11 px-4 border border-gray-200 rounded-md text-gray-700"
                  value={filter.value}
                  onChange={(event) => onFilterValueChangeHandler(event.target.value, filter.key)}
                />
              </div>
            </div>
          ))}

          <div className="pt-4 flex flex-row-reverse gap-4">
            <Button onClick={onApplyButtonClickHandler} variant="action" type="submit">Apply</Button>
            <Button onClick={onClearButtonClickHandler}>Clear</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

DataGridFilteringModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DataGridFilteringModal;
