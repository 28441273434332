const IconGlassDatabase = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <defs><style>{'.cls-1{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'}</style></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Icons">
        <polygon className="cls-1" points="14.68 15 4.68 15 4.68 10 4.68 5 14.68 5 14.68 15" />
        <line className="cls-1" x1="14.68" y1="10" x2="4.68" y2="10" />
        <line className="cls-1" x1="9.68" y1="15" x2="9.68" y2="5" />
        <polygon className="cls-1" points="19 19 1 19 1 10 1 1 19 1 19 19" />
      </g>
    </g>
  </svg>
);

export default IconGlassDatabase;
