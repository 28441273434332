import BrochurePage from 'components/BrochurePage/BrochurePage';

const PageBim = () => {
  const tr = true;
  const fl = false;

  return (
    <BrochurePage
      title="Guardian Glass for BIM"
      color="blue"
      activeColor="bg-[#1e68b5]"
      youtubeUrl="https://www.youtube.com/embed/1N_5rGTOFRw?rel=0"
      image="/images/brochure/Home.png"
      links={[
        {
          label: 'Download Guardian Glass for BIM',
          url: 'https://api.fenestrapro.com/v0/msi/download/beta/',
        },
      ]}
      copy="Guardian Glass for BIM is an Autodesk Revit© application for the appropriate selection and specification of Guardian Glass. Guardian Glass for BIM provides key performance metrics for the full range of Guardian Glass types, including:"
      copyImportants={[
        'Thermal performance',
        'Passive solar heat gain',
        'Natural Daylight',
      ]}
      isNext={tr}
      isBack={fl}
      isLast={fl}
      nextIndex={1}
      backIndex={-1}
    />
  );
};

export default PageBim;
