import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ToastWrapper from 'components/ToastWrapper/ToastWrapper';
import { t } from 'i18next';
import MSI from 'containers/MSI/MSI';
import { applyTheme } from 'themes/utils';

import {
  ALLOWED_ROLES,
  DASHBOARD_PATH,
  REPORTS_PATH,
  PROJECTS_PATH,
  RESOURCES_PATH,
  ASSEMBLIES_PATH,
  CODES_PATH,
  LOGIN_PATH,
  MSI_PATH,
  GLOBAL_DASHBOARD_PATH,
  REGIONAL_DASHBOARD_PATH,
  ASM_DASHBOARD_PATH,
  PROFILE_PATH,
  PROFILE_USER_PATH,
  PROFILE_PREFERENCES_PATH,
  PROFILE_PASSWORD_PATH,
  PASSWORD_RECOVERY_PATH,
  PASSWORD_RECOVERY_REQUEST_PATH,
  PASSWORD_RECOVERY_RESET_PATH,
  DESIGNER_DASHBOARD_PATH,
  DETAILED_PATH,
  THERMAL_PATH,
  CONCEPTUAL_PATH,
  SSO_PATH,
  BIM_PATH,
  IMPORT_CUSTOM_GLAZING_PATH,
  SEND_GLAZING_QUERY,
} from 'routes';

import NotFound from 'containers/NotFound';
import MainLayout from 'layouts/MainLayout';
import SimpleLayout from 'layouts/SimpleLayout';
import ProtectedPage from 'utils/ProtectedPage';
import DashboardGlobal from 'containers/Dashboard/DashboardGlobal';
import DashboardRegional from 'containers/Dashboard/DashboardRegional';
import DashboardASM from 'containers/Dashboard/DashboardASM';
import themes from 'themes';
import Profile from 'containers/Profile/Profile';
import ProfileUser from 'containers/Profile/ProfileUser';
import ProfileAppPreferences from 'containers/Profile/ProfileAppPreferences';
import ProfilePassword from 'containers/Profile/ProfilePassword';
import PasswordRecovery from 'containers/PasswordRecovery/PasswordRecovery';
import PasswordRecoveryRequest from 'containers/PasswordRecovery/PasswordRecoveryRequest';
import PasswordRecoveryReset from 'containers/PasswordRecovery/PasswordRecoveryReset';
import DashboardDesigner from 'containers/Dashboard/DashboardDesigner';
import PageDetailed from 'containers/Page/PageDetailed';
import PageThermal from 'containers/Page/PageThermal';
import PageConceptual from 'containers/Page/PageConceptual';
import ReportsDetails from 'containers/Reports/ReportsDetails';
import Sso from 'containers/Login/Sso';
import PageBim from 'containers/Page/PageBim';
import Dashboard from './containers/Dashboard/Dashboard';
import Reports from './containers/Reports/Reports';
import Projects from './containers/Projects/Projects';
import Resources from './containers/Resources/Resources';
import Assemblies from './containers/Assemblies/Assemblies';
import Codes from './containers/Codes/Codes';
import Login from './containers/Login/Login';
import ImportCustomGlazing from './containers/ImportCustomGlazing/ImportCustomGlazing';
import GlazingQuery from './containers/Query/GlazingQuery';

const App = () => {
  useEffect(() => {
    applyTheme(themes.guardian);
  }, []);

  return (
    <>
      <ToastWrapper />

      <Helmet>
        <title>{t('appTitle')}</title>
      </Helmet>

      <Routes>

        <Route path="/" element={<MainLayout />}>

          <Route
            path="/"
            element={<Navigate to={DASHBOARD_PATH} />}
          />

          <Route
            path={DASHBOARD_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[DASHBOARD_PATH]}
              >
                <Dashboard />
              </ProtectedPage>
            )}
          />

          <Route
            path={DETAILED_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[DETAILED_PATH]}
              >
                <PageDetailed />
              </ProtectedPage>
            )}
          />

          <Route
            path={THERMAL_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[THERMAL_PATH]}
              >
                <PageThermal />
              </ProtectedPage>
            )}
          />

          <Route
            path={CONCEPTUAL_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[CONCEPTUAL_PATH]}
              >
                <PageConceptual />
              </ProtectedPage>
            )}
          />

          <Route
            path={BIM_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[BIM_PATH]}
              >
                <PageBim />
              </ProtectedPage>
            )}
          />

          <Route
            path={GLOBAL_DASHBOARD_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[GLOBAL_DASHBOARD_PATH]}
              >
                <DashboardGlobal />
              </ProtectedPage>
            )}
          />

          <Route
            path={REGIONAL_DASHBOARD_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[REGIONAL_DASHBOARD_PATH]}
              >
                <DashboardRegional />

              </ProtectedPage>
            )}
          >
            <Route
              path=":id"
              element={(
                <ProtectedPage
                  allowedRoles={ALLOWED_ROLES[REGIONAL_DASHBOARD_PATH]}
                >
                  <DashboardRegional />

                </ProtectedPage>
              )}

            />

          </Route>

          <Route
            path={DESIGNER_DASHBOARD_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[DESIGNER_DASHBOARD_PATH]}
              >
                <DashboardDesigner />

              </ProtectedPage>
            )}
          >
            <Route
              path=":id"
              element={(
                <ProtectedPage
                  allowedRoles={ALLOWED_ROLES[DESIGNER_DASHBOARD_PATH]}
                >
                  <DashboardDesigner />

                </ProtectedPage>
              )}

            />

          </Route>

          <Route
            path={ASM_DASHBOARD_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[ASM_DASHBOARD_PATH]}
              >
                <DashboardASM />

              </ProtectedPage>
            )}
          >
            <Route
              path=":id"
              element={(
                <ProtectedPage
                  allowedRoles={ALLOWED_ROLES[ASM_DASHBOARD_PATH]}
                >
                  <DashboardASM />

                </ProtectedPage>
              )}
            />

          </Route>

          <Route
            path={REPORTS_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[REPORTS_PATH]}
              >
                <Reports />
              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={`${REPORTS_PATH}/:id`}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[REPORTS_PATH]}
              >
                <ReportsDetails />

              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={`${PROJECTS_PATH}/:id`}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[PROJECTS_PATH]}
              >
                <Projects />
              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={RESOURCES_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[RESOURCES_PATH]}
              >
                <Resources />
              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={ASSEMBLIES_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[ASSEMBLIES_PATH]}
              >
                <Assemblies />
              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={CODES_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[CODES_PATH]}
              >
                <Codes />
              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={MSI_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[MSI_PATH]}
              >
                <MSI />
              </ProtectedPage>
            )}
            exact
          />

          <Route
            path={PROFILE_PATH}
            element={(
              <ProtectedPage
                allowedRoles={ALLOWED_ROLES[PROFILE_PATH]}
              >
                <Profile />
              </ProtectedPage>
            )}
          >
            <Route
              path={PROFILE_USER_PATH}
              element={(
                <ProtectedPage
                  allowedRoles={ALLOWED_ROLES[PROFILE_PATH]}
                >
                  <ProfileUser />
                </ProtectedPage>
              )}
            />

            <Route
              path={PROFILE_PREFERENCES_PATH}
              element={(
                <ProtectedPage
                  allowedRoles={ALLOWED_ROLES[PROFILE_PATH]}
                >
                  <ProfileAppPreferences />
                </ProtectedPage>
              )}
            />

            <Route
              path={PROFILE_PASSWORD_PATH}
              element={(
                <ProtectedPage
                  allowedRoles={ALLOWED_ROLES[PROFILE_PATH]}
                >
                  <ProfilePassword />
                </ProtectedPage>
              )}
            />

          </Route>

        </Route>

        <Route path={LOGIN_PATH} element={<SimpleLayout />}>
          <Route
            path={LOGIN_PATH}
            element={<Login />}
            exact
          />

          <Route
            path={SSO_PATH}
            element={<Sso />}
            exact
          />

        </Route>

        <Route path={PASSWORD_RECOVERY_PATH} element={<PasswordRecovery />}>
          <Route
            path={PASSWORD_RECOVERY_REQUEST_PATH}
            element={<PasswordRecoveryRequest />}
            exact
          />

          <Route
            path={`${PASSWORD_RECOVERY_RESET_PATH}/:id`}
            element={<PasswordRecoveryReset />}
            exact
          />
        </Route>

        <Route
          path={IMPORT_CUSTOM_GLAZING_PATH}
          element={<ImportCustomGlazing />}
          exact
        />

        <Route
          path={SEND_GLAZING_QUERY}
          element={<GlazingQuery />}
          exact
        />

        <Route
          path="*"
          element={<NotFound />}
        />

      </Routes>
    </>
  );
};

export default App;
