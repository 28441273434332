import { useParams } from 'react-router-dom';
import React, { useEffect, Suspense } from 'react';
import { useLazyGetOverviewGeometryQuery, useLazyGetProjectOverviewQuery } from 'store/services/api';
import PageHeader from 'components/PageHeader/PageHeader';
import Spinner from 'components/Spinner/Spinner';
import ReportMeta from 'components/ReportMeta/ReportMeta';
import { t } from 'i18next';

const WindRoseChart = React.lazy(() => import('components/WindRoseChart/WindRoseChart'));

const ReportsProjectInformation = () => {
  const { id } = useParams();
  const [trigger, result] = useLazyGetProjectOverviewQuery();

  const triggerDataFetch = async () => {
    await trigger({ id });
  };

  useEffect(() => {
    if (!result.data) {
      triggerDataFetch();
    }
  }, [id]);

  if (result.isFetching) {
    return (
      <div className="mb-4 animate-pulse">
        <div className="bg-gray-400 block h-8 w-1/4 rounded mb-4" />
        <div className="bg-gray-400 block h-6 w-1/5 rounded mb-6" />
        <div className="bg-white rounded-sm h-24 flex px-2 py-6 flex-wrap gap-x-6">
          <span className="bg-gray-400 rounded h-4 " />
          <span className="w-1/3 bg-gray-400 rounded h-4" />
          <span className="w-1/3 bg-gray-400 rounded h-4" />
        </div>
      </div>
    );
  }

  const projectMetrics = ['address', 'usage', 'number'];
  const reportMetrics = ['user', 'organisation', 'date'];
  const locationMetrics = ['latitude', 'longitude'];
  const fileMetrics = ['name'];

  if (result.isSuccess) {
    const {
      project, file, report, location,
    } = result.data;

    const showMetrics = (metrics, data) => metrics.map((metric) => (
      <p className="py-1" key={`metric_${metric}`}>
        <span className="text-gray-500">{metric}</span>
        :
        <span className="font-medium pl-2">{data[metric] || 'N/A'}</span>
      </p>
    ));

    return (
      <div>
        <PageHeader title={file.name} showIcons={false} />

        <h2 className="font-bold text-[#38c7ba] text-xl mb-4">Project Information</h2>

        <div className="flex gap-8 items-stretch h-96 mb-6">
          <div className="bg-white rounded p-6 text-slate-600 text-sm w-1/3">
            <h1 className="text-[#38c7ba] text-base font-bold">Project Details</h1>
            {showMetrics(fileMetrics, file)}
            {showMetrics(projectMetrics, project)}
            {showMetrics(reportMetrics, report)}
            {showMetrics(locationMetrics, location)}
          </div>

          <div className="bg-white rounded p-6 text-slate-600 text-sm w-1/3">
            <h1 className="text-[#38c7ba] text-base font-bold">Percentage & Area Glazing</h1>
            <ReportMeta
              lazyFetchDataHook={useLazyGetOverviewGeometryQuery}
              gauges={[{
                name: 'percent',
                label: t('reports.geometry.percentageGlazing'),
                limit: 100,
              }]}
              metrics={[
                { name: 'area_total', label: t('reports.geometry.totalAreaFacades') },
                { name: 'area_glazed', label: t('reports.geometry.totalAreaGlazing') },
              ]}
            />
          </div>

          <div className="text-gray-500 bg-white rounded w-1/3">
            <Suspense fallback={<Spinner isLoading />}>
              <WindRoseChart id={id} />
            </Suspense>
          </div>
        </div>
      </div>
    );
  }
};
export default ReportsProjectInformation;
