import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import {
  Form, Formik,
} from 'formik';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import FormSelect from 'components/Form/FormSelect';
import { useSendShareMutation } from 'store/services/api';

const validationSchema = Yup.object().shape({
  user: Yup.string().required(t('validationErrors.required')),
});

const DataGridShareModalUser = ({ options, itemId }) => {
  const formikInitialValues = {
    user: '',
  };

  const { users } = options;

  const [sendShare, { isLoading }] = useSendShareMutation();

  const onSubmitHandler = async (data) => {
    try {
      const result = await sendShare({
        data: {
          target: data.user,
          share_type: 'user',
        },
        id: itemId,
      });

      result ? toast.info('Shared') : toast.error('Could not share code');
    } catch {
      toast.error(t('toasterMessage.error.requestPassReset'));
    }
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ errors }) => (
        <Form className="flex flex-col gap-6">

          <FormSelect
            name="user"
            label={t('DataGrid.shareModal.user')}
            options={users.map(({
              _id, email, firstname, lastname,
            }) => ({ key: _id, value: `${email} - ${firstname} ${lastname}` }))}
          />

          <Button
            disabled={Object.keys(errors).length > 0 || isLoading}
            type="submit"
          >
            {t('DataGrid.shareModal.buttons.shareWithUser')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

DataGridShareModalUser.propTypes = {
  options: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default DataGridShareModalUser;
