import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getUser } from 'store/slices/user';

const singleUnit = ({ usUnit, euUnit }) => {
  const { region } = useSelector(getUser);

  const US_UNIT_REGION = 'NAC';

  if (region === US_UNIT_REGION) {
    return (
      <span>
        {usUnit}
      </span>
    );
  }
  return (
    <span>
      {euUnit}
    </span>
  );
};

singleUnit.propTypes = {
  usUnit: PropTypes.string.isRequired,
  euUnit: PropTypes.string.isRequired,
};

export default singleUnit;
