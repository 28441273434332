import PropTypes from 'prop-types';

const BreadcrumbItem = ({ children }) => (
  <li className="flex">
    <div className="flex items-center">
      {children}
    </div>
  </li>
);

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BreadcrumbItem;
