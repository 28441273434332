export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DESC = 'desc';
export const DATA_TYPES = {
  STRING: 0,
  NUMBER: 1,
  CURRENCY: 2,
  DATE: 3,
  EMAIL: 4,
  AREA: 5,
  URL: 6,
};
export const AUTOFETCH_INTERVAL = 20000;
