import PropTypes from 'prop-types';

const TabBar = ({ children }) => (
  <div>
    <div className="border-b border-gray-200 my-4">
      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
        {children}
      </nav>
    </div>
  </div>
);

TabBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabBar;
