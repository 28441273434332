import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import { t } from 'i18next';
import { Dialog } from '@headlessui/react';
import Button from 'components/Button/Button';
import * as Yup from 'yup';
import {
  Form, Formik,
} from 'formik';
import toast from 'react-hot-toast';
import FormTextInput from 'components/Form/FormTextInput';
import FormTextArea from 'components/Form/FormTextArea';
import { useSendProductEnquiryMutation } from 'store/services/api';
import { getUser } from 'store/slices/user';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(t('validationErrors.required')),
  email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
  topic: Yup.string().required(t('validationErrors.required')),
  message: Yup.string().required(t('validationErrors.required')),
});

const DataGridContactModal = ({
  open, onClose, itemName,
}) => {
  const [sendEnquiry, { isLoading }] = useSendProductEnquiryMutation();

  const {
    id, firstName, lastName, email, region,
  } = useSelector(getUser);

  const formikInitialValues = {
    name: `${firstName} ${lastName}`,
    email,
    topic: '',
    message: '',
  };

  const onSubmitHandler = async (data) => {
    try {
      const result = await sendEnquiry({
        data: {
          user_id: id,
          region,
          full_name: data.name,
          email: data.email,
          company_name: data.topic,
          question: data.message,
          glass_description: itemName,
          source: 'guardian',
        },
      });

      // result is currently returning an error message and a 200 at the same time
      // As a result, we can't really detect whether the enquiry is being sent.
      // For now a response is being deemed a success.

      result ? toast.success(t('DataGrid.contact.enquirySent')) : toast.error(t('DataGrid.contact.enquiryError'));

      onClose();
    } catch {
      toast.error(t('toasterMessage.error.requestPassReset'));
    }
  };

  return (
    <Modal
      open={open}
      setOpen={onClose}
    >

      <Dialog.Title
        as="h1"
        className="text-xl text-center font-bold leading-6 text-base-primary py-3"
      >
        {t('DataGrid.contact.sendEnquiry')}
      </Dialog.Title>

      <Dialog.Title
        as="h2"
        className="text-center text-sm leading-6 text-base-primary pb-6"
      >
        {t('DataGrid.shareModal.queryTitle', { item: itemName })}
      </Dialog.Title>

      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ errors }) => (
          <Form className="flex flex-col gap-2">
            <FormTextInput name="name" label={t('DataGrid.contact.name')} />
            <FormTextInput name="email" label={t('DataGrid.contact.email')} />
            <FormTextInput name="topic" label={t('DataGrid.contact.topic')} />
            <FormTextArea name="message" label={t('DataGrid.contact.message')} />

            <div>
              <Button
                isBlock
                disabled={Object.keys(errors).length > 0 || isLoading}
                type="submit"
                isLoading={isLoading}
              >
                {t('DataGrid.contact.sendEnquiry')}
              </Button>
            </div>
          </Form>

        )}
      </Formik>

    </Modal>
  );
};

DataGridContactModal.propTypes = {
  open: PropTypes.bool.isRequired,
  itemName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

DataGridContactModal.defaultProps = {
  itemName: '',
};

export default DataGridContactModal;
