import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useHashQuery = () => {
  const { hash } = useLocation();
  return useMemo(() => new URLSearchParams(hash.substring(1)), [hash]);
};
