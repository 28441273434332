import Button from 'components/Button/Button';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import { useContext } from 'react';
import { useUpdateProfileMutation } from 'store/services/api';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';
import ProfileContext from './ProfileContext';
import ProfileGroup from './ProfileGroup';
import ProfileInput from './ProfileInput';

const validationSchema = Yup.object().shape({
  firstname: Yup.string().min(2, t('validationErrors.tooShort')).max(50, t('validationErrors.tooLong')).required(t('validationErrors.required')),
  lastname: Yup.string().min(2, t('validationErrors.tooShort')).max(50, t('validationErrors.tooLong')).required(t('validationErrors.required')),
  username: Yup.string().min(2, t('validationErrors.tooShort')).max(50, t('validationErrors.tooLong')).required(t('validationErrors.required')),
});

const ProfileUser = () => {
  const [updateProfile] = useUpdateProfileMutation();
  const { id, email } = useSelector(getUser);
  const { info } = useContext(ProfileContext);

  const formikInitialValues = {
    firstname: info.firstname || '',
    lastname: info.lastname || '',
    username: info.username || '',
  };

  const onSubmit = async (userData) => {
    try {
      const result = await updateProfile({ id, userData: { info: { ...userData } } });
      result.data ? toast.success(t('toasterMessage.success.userInfo')) : toast.error(t('toasterMessage.error.userInfo'));
    } catch (error) {
      toast.error(t('toasterMessage.error.userInfo'));
    }
  };

  return (
    <div>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <ProfileGroup title={t('Profile.userInformation')} description="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">

              <ProfileInput
                label={t('Profile.firstName')}
                type="text"
                name="firstname"
                id="first-name"
                autoComplete="given-name"
                error={errors.firstname}
                displayError={!!errors.firstname && !!touched.firstname}
              />

              <ProfileInput
                label={t('Profile.lastName')}
                type="text"
                name="lastname"
                id="last-name"
                autoComplete="family-name"
                error={errors.lastname}
                displayError={!!errors.lastname && !!touched.lastname}
              />

              <ProfileInput
                label={t('Profile.username')}
                type="text"
                name="username"
                id="user-name"
                autoComplete="username"
                error={errors.username}
                displayError={!!errors.username && !!touched.username}
              />

              <div className="col-span-6 sm:col-span-4">
                <label htmlFor="email-address" className="block text-sm font-medium text-base-secondary">
                  {t('Profile.email')}
                </label>
                <span className="text-base-primary">{email}</span>
              </div>

            </ProfileGroup>

            <div className="flex justify-end py-6">
              <Button type="submit">
                {t('Profile.updateProfile')}
              </Button>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileUser;
