import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
// eslint-disable-next-line import/no-cycle
import { clearUserToken } from 'store/slices/user';

// TBC - some indicative REST endpoints
export const serviceUrl = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  chart: 'chart',
  login: 'login',
  logout: 'logout',
  metric: 'metric',
  codes: 'codes',
  globals: 'globals',
  regionals: 'regionals',
  asms: 'asms',
  projects: 'projects',
  events: 'events',
  designers: 'designers',
  assemblies: 'assemblies',
  reports: 'reports',
  users: 'users',
  standard: 'standard',
  custom: 'custom',
  profiles: 'profiles',
  forgot: 'forgot',
  colleagues: 'colleagues',
  breadcrumbs: 'breadcrumbs',
  downloads: 'downloads',
  import: 'import',
  revit: 'revit',
};

const baseQuery = fetchBaseQuery({
  baseUrl: serviceUrl.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = getState().user;
    headers.set('authorization', accessToken);
    return headers;
  },
});

export const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      api.dispatch(clearUserToken());
    }
  }

  return result;
};

export const baseUnauthenticatedQuery = fetchBaseQuery({
  baseUrl: serviceUrl.baseUrl,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return headers;
  },
});

export const defaultQuery = (urlSlug, queryObject) => {
  const queryParams = new URLSearchParams(queryObject).toString();
  return `${urlSlug}?${queryParams}`;
};
