import BrochurePage from 'components/BrochurePage/BrochurePage';

const PageConceptual = () => {
  const tr = true;
  const fl = false;

  return (
    <BrochurePage
      title="Early stage design tools"
      color="blue"
      activeColor="bg-[#1e68b5]"
      youtubeUrl="https://www.youtube.com/embed/jv1JgSTtlLE?rel=0"
      image="/images/brochure/Concept.png"
      links={[
        {
          label: 'Download Guardian Glass for BIM',
          url: 'https://api.fenestrapro.com/v0/msi/download/beta/',
        },
      ]}
      copy="The Conceptual Design Tools in Guardian Glass for BIM support early stage design considerations and enable users to:"
      copyImportants={[
        'Establish the optimal glass-to-wall ratio.',
        'Understand location, orientation, and the effects of the shading from surrounding buildings.',
        'Define an early-stage glazing performance selection',
      ]}
      isNext={tr}
      isBack={tr}
      isLast={fl}
      nextIndex={2}
      backIndex={0}
    />
  );
};

export default PageConceptual;
