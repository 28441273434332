import { useEffect, useState } from 'react';
import { t } from 'i18next';
import Metrics from 'components/Metrics/Metrics';
import DataGrid from 'components/DataGrid/DataGrid';
import {
  useLazyGetDesignersProjectsQuery,
  useGetDesignersMetricQuery,
  useLazyGetReportsByUserQuery,
  useLazyGetUserInfoQuery,
  useGetIsFirstTimeLoginByIdQuery,
} from 'store/services/api';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import { useNavigate } from 'react-router-dom';
import { serviceUrl } from 'store/services/settings';
import { PROJECTS_PATH, REPORTS_PATH } from 'routes';
import Seo from 'components/Seo/Seo';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { CONTAINER_DESIGNER, ROLE_DESIGNER } from 'constants';
import useAreaUnit from 'hooks/useAreaUnit';
import Meta from 'components/Meta/Meta';
import useFormat from 'hooks/useFormat';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';
import { ArrowsExpandIcon, BriefcaseIcon, GlobeIcon } from '@heroicons/react/outline';
import useUserId from 'hooks/useUserId';
import singleUnit from '../../hooks/singleUnit';
import m2kUnit from '../../hooks/m2kUnit';
import FirstTimeModal from '../../components/FirstTimeModal/FirstTimeModal';

const DashboardDesigner = () => {
  const [isFirstTimeModalOpen, setIsFirstTimeModalOpen] = useState(false);
  const result = useGetIsFirstTimeLoginByIdQuery();

  const onFirstTimeModalOpenHandler = () => {
    setIsFirstTimeModalOpen(true);
  };

  useEffect(() => {
    if (result.data) {
      onFirstTimeModalOpenHandler();
    }
  }, [result]);

  const navigate = useNavigate();
  const userId = useUserId();

  const { formatDate, formatNumber, formatEmail } = useFormat();

  const metricsProperties = {
    courses: {
      icon: <GlobeIcon />,
    },
    projects: {
      icon: <BriefcaseIcon />,
    },
    area: {
      icon: <ArrowsExpandIcon />,
    },
  };

  const user = useSelector(getUser);
  const isDesigner = user.role === ROLE_DESIGNER;
  const [isProjectExist, setIsProjectExist] = useState(false);

  const dataGridTabProperties = [{
    name: t('DataGrid.tabs.projects'),
    urlSlug: serviceUrl.projects,
    onItemClick: ({ id }) => navigate(`${PROJECTS_PATH}/${id}?designerId=${userId}`),
    dataGridProps: [{
      key: 'name', label: t('DataGrid.tableHeader.designer.name'), sort: true, filter: true, defaultSortOrder: SORT_ORDER_ASC, width: '15%', bold: true,
    }, {
      key: 'country', label: t('DataGrid.tableHeader.designer.country'), sort: true, filter: true, width: '10%',
    }, {
      key: 'last_session', label: t('DataGrid.tableHeader.users.lastSession'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.DATE,
    }, {
      key: 'last_user', label: t('DataGrid.tableHeader.designer.lastUser'), sort: true, filter: true, width: '15%',
    }, {
      key: 'no_users', label: t('DataGrid.tableHeader.designer.noUsers'), sort: true, filter: true, dataType: DATA_TYPES.NUMBER, width: '10%',
    },
    !isDesigner ? {
      key: 'area', label: t('DataGrid.tableHeader.designer.area'), sort: true, filter: true, width: '10%', dataType: DATA_TYPES.AREA, unit: useAreaUnit(),
    } : null,
    !isDesigner ? {
      key: 'percent_glazing', label: t('DataGrid.tableHeader.designer.percentGlazing'), sort: true, filter: true, dataType: DATA_TYPES.NUMBER, width: '10%',
    } : null,
    {
      key: 'solarload', label: t('DataGrid.tableHeader.designer.solarload'), sort: true, filter: true, dataType: DATA_TYPES.NUMBER, width: '10%', unit: m2kUnit(),
    }, {
      key: 'daylighting', label: t('DataGrid.tableHeader.designer.daylighting'), sort: true, filter: true, dataType: DATA_TYPES.NUMBER, width: '10%', unit: singleUnit({ usUnit: '%', euUnit: '%' }),
    }].filter(Boolean),
  }];

  const dataGridTabsProperties = [{
    name: 'Active Reports',
    urlSlug: serviceUrl.standard,
    onItemClick: ({ id }) => navigate(`${REPORTS_PATH}/${id}`),
    dataGridProps: [{
      key: 'name', label: 'Name', sort: true, filter: true, width: '40%', bold: true, defaultSortOrder: SORT_ORDER_ASC, dataType: DATA_TYPES.STRING,
    }, {
      key: 'type', label: 'Type', sort: true, filter: true, width: '20%', dataType: DATA_TYPES.STRING,
    }, {
      key: 'author', label: 'Author', sort: true, filter: true, dataType: DATA_TYPES.STRING, width: '20%',
    }, {
      key: 'date', label: 'Created', sort: true, filter: true, dataType: DATA_TYPES.DATE, width: '10%',
    }],
  }];

  return (
    <>
      <FirstTimeModal
        open={isFirstTimeModalOpen}
        onClose={() => setIsFirstTimeModalOpen(false)}
      />
      <Seo title={t('dashboard.title')} />

      <div className="flex gap-8 w-full">

        <section className="flex-grow flex-shrink py-6">

          <Breadcrumb current={CONTAINER_DESIGNER} />

          {isDesigner
            && (
            <div className="sm:flex sm:items-center gap-6">
              <span className="text-[#38c7ba] text-2xl font-bold text-highlight-primary pb-5">{isProjectExist ? 'What would you like to do today...' : 'As a new user here is a quick guide to get you started...'}</span>
            </div>
            )}

          <div className="flex gap-4 w-full">
            {!isDesigner
              && (
                <Meta
                  fetchDataHook={useLazyGetUserInfoQuery}
                  properties={[{
                    key: 'name', label: t('designerDashboard.meta.name'),
                  }, {
                    key: 'name_of_firm', label: t('designerDashboard.meta.name_of_firm'),
                  }, {
                    key: 'role', label: t('designerDashboard.meta.role'),
                  }, {
                    key: 'email', label: t('designerDashboard.meta.email'), formatter: formatEmail,
                  }, {
                    key: 'region', label: t('designerDashboard.meta.location'),
                  }, {
                    key: 'last_login', label: t('designerDashboard.meta.last_login'), formatter: formatDate,
                  }, {
                    key: 'no_projects', label: t('designerDashboard.meta.no_projects'), formatter: formatNumber,
                  }, {
                    key: 'area', label: t('designerDashboard.meta.area'), formatter: formatNumber, unit: useAreaUnit(),
                  }]}
                />
              )}

            <Metrics
              learningLinks
              fetchDataHook={useGetDesignersMetricQuery}
              properties={metricsProperties}
              exclude={isDesigner ? ['area'] : []}
              isProjectExist={setIsProjectExist}
            />
          </div>

          <DataGrid
            title={t('designerDashboard.projects')}
            properties={dataGridTabProperties}
            isExport
            isPagination
            informationMessage={isDesigner ? 'Download Guardian Glass for BIM and open it on a project to generate the project data.' : 'Your invited users have yet to use the application on a project. We suggest you contact them by email to see if there is a problem, if there is we can help, just let us know and we can schedule a support call.'}
            itemsPerPage={5}
            lazyFetchDataHook={useLazyGetDesignersProjectsQuery}
          />

          <DataGrid
            title={t('designerDashboard.reports')}
            properties={dataGridTabsProperties}
            isExport
            isPagination
            informationMessage={isDesigner ? 'Download Guardian Glass for BIM, open it on a project and under the Outputs tab click to generate a report.' : 'Your invited users may have used the application on a project but have yet to generate a Report.'}
            itemsPerPage={5}
            lazyFetchDataHook={useLazyGetReportsByUserQuery}
          />

        </section>

      </div>
    </>
  );
};

export default DashboardDesigner;
