import Accordion from 'components/Accordion/Accordion';
import AccordionItem from 'components/Accordion/AccordionItem';
import AccordionReportContent from 'components/Accordion/AccordionReportContent';
import { REPORT_BARCHART, REPORT_BARCHART_NAVIGATE, REPORT_LINECHART } from 'components/ReportChart/constants';
import { colors } from 'utils/colors';
import ReportMeta from 'components/ReportMeta/ReportMeta';
import {
  useLazyGetOverviewPerformanceQuery,
  useLazyGetAnnualSolarHeatgainQuery,
  useLazyGetMonthlySolarHeatgainQuery,
  useLazyGetPeakMonthlySolarHeatgainQuery,
  useLazyGetAnnualDaylightFactorQuery,
  useLazyGetAnnualShadingQuery,
} from 'store/services/api';
import AccordionReportContentSwitch from 'components/Accordion/AccordionReportContentSwitch';
import { t } from 'i18next';

const ReportsProjectPerformance = () => (
  <div className="py-6">
    <h2 className="font-bold text-slate-600 mb-4">Facade Performance</h2>

    <ReportMeta
      lazyFetchDataHook={useLazyGetOverviewPerformanceQuery}
      // Not ideal. The API is returning units differently
      // between 'overview_performance' and 'overview_geometry'
      // The response structure should be like for like
      // To work around this for now, multipleUnits has be introduced
      multipleUnits
      gauges={[
        {
          name: 'daylighting',
          label: t('reports.performance.daylightFactor'),
          limit: 15,
          autoColor: 'desc',
          thresholds: [
            { value: 100, color: '#ffff33' },
            { value: 50, color: '#cccc00' },
            { value: 20, color: '#888' },
          ],
        },
        {
          name: 'uval',
          label: t('reports.performance.uValue'),
          limit: 2.5,
          thresholds: [
            { value: 100, color: '#7B0E38' },
            { value: 60, color: '#0A7A50' },
          ],
        },
        {
          name: 'heatgain',
          label: t('reports.performance.solarHeatGain'),
          limit: 50,
          thresholds: [
            { value: 100, color: '#7B0E38' },
            { value: 50, color: '#0A7A50' },
            { value: 30, color: '#0B316D' },
          ],
        },
      ]}
    />

    <Accordion>

      <AccordionItem label="Average Annual Solar Heat Gains" id="annual_orientations">

        <AccordionReportContentSwitch
          key="m1"
          properties={[
            {
              label: t('reports.performance.orientations'),
              component: <AccordionReportContent
                key="444"
                lazyFetchDataHook={useLazyGetAnnualSolarHeatgainQuery}
                queryParams={{ mode: 'orientation' }}
                properties={[
                  { label: t('reports.performance.orientations'), color: colors[2] },
                ]}
                displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                toggleTableOption
              />,
            },
            {
              label: t('reports.performance.facades'),
              component: <AccordionReportContent
                key="222"
                lazyFetchDataHook={useLazyGetAnnualSolarHeatgainQuery}
                queryParams={{ mode: 'facade' }}
                properties={[
                  { label: t('reports.performance.facades'), color: colors[3] },
                ]}
                displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                toggleTableOption
              />,
            },
          ]}
        />

      </AccordionItem>

      <AccordionItem label="Average Monthly Solar Heat Gains" id="monthly_average_orientations">
        <AccordionReportContentSwitch
          key="m2"
          properties={[
            {
              label: t('reports.performance.orientations'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetMonthlySolarHeatgainQuery}
                key="yyy"
                queryParams={{ mode: 'orientation' }}
                properties={[
                  {
                    label: 'Monthly Facade Heatgain',
                    color: colors[2],
                    referenceAreas: [
                      {
                        y1: 0, y2: 15, fill: '#0B316D',
                      },
                      {
                        y1: 15, y2: 25, fill: '#0A7A50',
                      },
                      {
                        y1: 25, fill: '#7B0E38',
                      },
                    ],
                  },
                ]}
                displayTypes={[REPORT_BARCHART_NAVIGATE]}
                toggleTableOption
              />,
            },
            {
              label: t('reports.performance.facades'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetMonthlySolarHeatgainQuery}
                key="xxx"
                queryParams={{ mode: 'facade' }}
                properties={[
                  {
                    label: 'Monthly Facade Heatgain',
                    color: colors[3],
                    referenceAreas: [
                      {
                        y1: 0, y2: 15, fill: '#0B316D',
                      },
                      {
                        y1: 15, y2: 25, fill: '#0A7A50',
                      },
                      {
                        y1: 25, fill: '#7B0E38',
                      },
                    ],
                  },
                ]}
                displayTypes={[REPORT_BARCHART_NAVIGATE]}
                toggleTableOption
              />,
            },
          ]}
        />
      </AccordionItem>

      <AccordionItem label="Peak Monthly Solar Heat Gains" id="monthly_peak_orientations">

        <AccordionReportContentSwitch
          key="m3"
          properties={[
            {
              label: t('reports.performance.orientations'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetPeakMonthlySolarHeatgainQuery}
                key="b"
                queryParams={{ mode: 'orientation' }}
                properties={[
                  {
                    label: 'Monthly Facade Heatgain',
                    color: colors[2],
                    referenceAreas: [
                      {
                        y1: 0, y2: 15, fill: '#0B316D',
                      },
                      {
                        y1: 15, y2: 25, fill: '#0A7A50',
                      },
                      {
                        y1: 25, fill: '#7B0E38',
                      },
                    ],
                  },
                ]}
                displayTypes={[REPORT_BARCHART_NAVIGATE]}
                toggleTableOption
              />,
            },
            {
              label: t('reports.performance.facades'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetPeakMonthlySolarHeatgainQuery}
                key="m"
                queryParams={{ mode: 'facade' }}
                properties={[
                  {
                    label: 'Monthly Facade Heatgain',
                    color: colors[3],
                    referenceAreas: [
                      {
                        y1: 0, y2: 15, fill: '#0B316D',
                      },
                      {
                        y1: 15, y2: 25, fill: '#0A7A50',
                      },
                      {
                        y1: 25, fill: '#7B0E38',
                      },
                    ],
                  },
                ]}
                displayTypes={[REPORT_BARCHART_NAVIGATE]}
                toggleTableOption
              />,
            },
          ]}
        />

      </AccordionItem>

      {/* New */}
      <AccordionItem label="Annual daylight factor" id="annual_daylight_factor">

        <AccordionReportContentSwitch
          key="dlfactor1"
          properties={[
            {
              label: t('reports.performance.orientations'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetAnnualDaylightFactorQuery}
                key="b1"
                queryParams={{ mode: 'orientation' }}
                properties={[
                  {
                    label: 'Annual Daylight Factor',
                    color: colors[0],
                    referenceAreas: [{
                      y1: 0, y2: 2, fill: '#888',
                    },
                    {
                      y1: 2, y2: 5, fill: '#cc0',
                    },
                    {
                      y1: 5, y2: 8, fill: '#ff3',
                    }],
                  },
                ]}
                displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                toggleTableOption
              />,
            },
            {
              label: t('reports.performance.facades'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetAnnualDaylightFactorQuery}
                key="m2"
                queryParams={{ mode: 'facade' }}
                properties={[
                  {
                    label: 'Annual Daylight Factor',
                    color: colors[2],
                    referenceAreas: [{
                      y1: 0, y2: 2, fill: '#888',
                    },
                    {
                      y1: 2, y2: 5, fill: '#cc0',
                    },
                    {
                      y1: 5, y2: 8, fill: '#ff3',
                    }],
                  },
                ]}
                displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                toggleTableOption
              />,
            },
          ]}
        />

      </AccordionItem>

      <AccordionItem label="Average Annual Shading" id="average_annual_shading">

        <AccordionReportContentSwitch
          key="shading"
          properties={[
            {
              label: t('reports.performance.orientations'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetAnnualShadingQuery}
                key="b1"
                queryParams={{ mode: 'orientation' }}
                properties={[
                  {
                    label: 'Average Annual Shading',
                    color: colors[0],
                  },
                ]}
                displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                toggleTableOption
              />,
            },
            {
              label: t('reports.performance.facades'),
              component: <AccordionReportContent
                lazyFetchDataHook={useLazyGetAnnualShadingQuery}
                key="m2"
                queryParams={{ mode: 'facade' }}
                properties={[
                  { label: 'Average Annual Shading', color: colors[2] },
                ]}
                displayTypes={[REPORT_BARCHART, REPORT_LINECHART]}
                toggleTableOption
              />,
            },
          ]}
        />

      </AccordionItem>

    </Accordion>
  </div>
);

export default ReportsProjectPerformance;
