import PropTypes from 'prop-types';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

// import { useSelector } from 'react-redux';
// import { getUser } from 'store/slices/user';
import getColor from 'utils/colors';
import ReportChartYAxis from './ReportChartYAxis';
import ReportChartTooltip from './ReportChartTooltip';
import ReportChartXAxis from './ReportChartXAxis';

const ReportChartBarAlternate = ({ chartData, properties }) => {
  // const user = useSelector(getUser);
  // const userUnits = user.units === 'default' ? 'metric' : user.units;

  const maxResults = 100;
  const chartDataPercentage = chartData.data.slice(0, maxResults).map((item) => (
    { name: item.name, percentage: item.ratio * 100 }
  ));

  return (
    <div className="h-72 py-6">
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={400}
          data={chartDataPercentage}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={<ReportChartXAxis />} />

          <YAxis tick={<ReportChartYAxis />} domain={[0, 100]} />

          <Tooltip content={<ReportChartTooltip />} />

          <Legend />

          {properties.map((property, index) => (
            <Bar
              key={`bar_${property.label}`}
              dataKey="percentage"
              name={property.label}
              unit="%"
              fill={getColor(index)}
              stackId={property.stackId || null}
              background="#CCCCCC"
            />
          ))}

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReportChartBarAlternate;

ReportChartBarAlternate.propTypes = {
  chartData: PropTypes.object.isRequired,
  properties: PropTypes.array.isRequired,
};
