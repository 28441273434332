import PropTypes from 'prop-types';

const ReportChartYAxis = ({
  x, y, payload,
}) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={5} textAnchor="end" fill="#999" fontSize={13}>
      {payload.value}
    </text>
  </g>
);

ReportChartYAxis.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

ReportChartYAxis.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};

export default ReportChartYAxis;
