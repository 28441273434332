import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';
import FormTextInput from 'components/Form/FormTextInput';
import Button from 'components/Button/Button';
import FormSelect from 'components/Form/FormSelect';
import { useInviteUserMutation } from 'store/services/api';
import { REGIONS_ALTERNATE, ROLE_REGIONAL } from 'constants';
import { useContext } from 'react';
import { userTypeGuardian, userTypes } from './InviteUserModalConstants';
import InviteUserModalContext from './InviteUserModalContext';

const validationSchemaGuardian = Yup.object().shape({
  firstname: Yup
    .string()
    .required(t('validationErrors.required')),
  email: Yup
    .string()
    .matches(/[a-z0-9]+@guardian.com|fenestrapro.com/, { message: 'Guardian email address only' })
    .required(t('validationErrors.required')),
  region: Yup
    .string()
    .when('role', {
      is: (value) => value && value === ROLE_REGIONAL,
      then: Yup
        .string()
        .required(),
    }),
});

const validationSchemaUser = Yup.object().shape({
  firstname: Yup.string().required(t('validationErrors.required')),
  email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
});

const InviteUserModalUserForm = ({ type, onClose }) => {
  const {
    role,
    parentUserId,
  } = useContext(InviteUserModalContext);

  const isGuardian = (type === userTypeGuardian);

  const [sendInvite, { isLoading }] = useInviteUserMutation();

  const formikGuardianInitialValues = {
    firstname: '',
    email: '',
    role,
    region: '',
    parent_user_id: parentUserId,
  };

  const formikUserInitialValues = { firstname: '', email: '', parent_user_id: parentUserId };

  const onSubmitHandler = async (data) => {
    try {
      const result = await sendInvite({ data, mode: type });
      result.data ? toast.success(t('InviteUserModal.inviteSuccess')) : toast.error(t('InviteUserModal.inviteError'));

      onClose();
    } catch {
      toast.error(t('InviteUserModal.inviteError'));
    }
  };

  return (

    <Formik
      initialValues={isGuardian ? formikGuardianInitialValues : formikUserInitialValues}
      validationSchema={isGuardian ? validationSchemaGuardian : validationSchemaUser}
      onSubmit={onSubmitHandler}
    >
      {({ errors }) => (
        <Form className="flex flex-col gap-2 py-4">
          <FormTextInput name="firstname" label="Firstname" />

          <FormTextInput name="email" label="Email" />

          {isGuardian && role === ROLE_REGIONAL
            && (
              <FormSelect
                name="region"
                label="Region"
                options={REGIONS_ALTERNATE.map((region) => ({ key: region, value: region }))}
              />
            )}

          <div className="mt-6">
            <Button
              isBlock
              disabled={Object.keys(errors).length > 0 || isLoading}
              type="submit"
              isLoading={isLoading}
            >
              {t('InviteUserModal.inviteAction')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteUserModalUserForm;

InviteUserModalUserForm.propTypes = {
  type: PropTypes.oneOf(userTypes).isRequired,
  onClose: PropTypes.func.isRequired,
};
