import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from 'store/slices/user';
import { useEffect } from 'react';
import { Gauge } from 'components/Gauge/Gauge';

const ReportMeta = ({
  lazyFetchDataHook, gauges, metrics, multipleUnits,
}) => {
  const { id } = useParams();
  const [trigger, result] = lazyFetchDataHook();

  const triggerDataFetch = async () => {
    await trigger({ id });
  };

  useEffect(() => {
    if (!result.data) {
      triggerDataFetch();
    }
  }, [id]);

  const user = useSelector(getUser);
  const userUnits = user.units === 'default' ? 'metric' : user.units;

  if (result.isFetching) {
    return (
      <div className="bg-white rounded p-6 mb-6 flex flex-col text-slate-600 gap-6 h-48 animate-pulse">
        <div className="w-3/5 h-6 bg-gray-300 block rounded" />
        <div className="w-2/5 h-6 bg-gray-300 block rounded" />
        <div className="w-3/5 h-6 bg-gray-300 block rounded" />
      </div>
    );
  }

  if (result.isSuccess) {
    return (
      <div className="bg-white rounded p-6 mb-6 flex flex-col text-slate-600 gap-8">
        <div className="flex justify-around text-sm">
          {gauges && result.data && gauges.map(({
            name, label, limit, autoColor, thresholds,
          }) => (
            <div className="flex flex-col justify-center items-center" key={`meta_${label}`}>
              <span className="pb-2">{label}</span>
              <Gauge
                percent={(result.data.data[name] / limit) * 100}
                value={result.data.data[name]?.toFixed(1)}
                autoColor={autoColor}
                thresholds={thresholds}
              />
              <span style={{ marginTop: -5 }}>
                {(multipleUnits) ? result.data.units[name][userUnits]
                  : '%'}
              </span>
            </div>
          ))}

        </div>
        {metrics
          && (
            <div className="columns-2 text-sm pl-8">
              {metrics && result.data && metrics.map(({ name, label }) => (
                <div className="py-2" key={`meta_${label}`}>
                  <span className="pr-2">
                    {label}
                    :
                  </span>
                  <span className="font-medium text-slate-800">
                    {result.data.data[name]?.toFixed(1)}

                    {user.units === 'default'
                      ? (
                        <span>
                          m
                          <sup>2</sup>
                        </span>
                      ) : (
                        <span>
                          ft
                          <sup>2</sup>
                        </span>
                      )}
                  </span>
                </div>
              ))}
            </div>
          )}
      </div>
    );
  }
};

export default ReportMeta;

ReportMeta.propTypes = {
  lazyFetchDataHook: PropTypes.func.isRequired,
  gauges: PropTypes.array,
  metrics: PropTypes.array,
  multipleUnits: PropTypes.bool,
};

ReportMeta.defaultProps = {
  gauges: [],
  metrics: [],
  multipleUnits: false,
};
