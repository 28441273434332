import { t } from 'i18next';
import Metrics from 'components/Metrics/Metrics';
import {
  useLazyGetProjectAssembliesQuery,
  useLazyGetProjectInfoQuery,
  useLazyGetProjectsMetricQuery,
  useLazyGetProjectsQuery,
} from 'store/services/api';
import { DATA_TYPES } from 'components/DataGrid/DataGridConstants';
import DataGrid from 'components/DataGrid/DataGrid';
import Seo from 'components/Seo/Seo';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { CONTAINER_PROJECT, ROLE_DESIGNER } from 'constants';
import useAreaUnit from 'hooks/useAreaUnit';
import Meta from 'components/Meta/Meta';
import useFormat from 'hooks/useFormat';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/slices/user';

const Projects = () => {
  const ProjectReportDatagrid = [{
    name: t('projects.dataGridTitle'),
    dataGridProps: [{
      key: 'name', label: t('projects.name'), sort: true, width: '20%', bold: true,
    }, {
      key: 'type', label: t('projects.type'), sort: true, width: '20%',
    }, {
      key: 'date', label: t('projects.date'), sort: true, dataType: DATA_TYPES.DATE, width: '20%',
    }, {
      key: 'author', label: t('projects.author'), sort: true, width: '20%',
    }],
  }];

  const ProjectAssembliesDatagrid = [{
    name: 'Assemblies',
    dataGridProps: [{
      key: 'desc', label: t('projects.assemblies.desc'), sort: true, width: '30%', bold: true,
    }, {
      key: 'area', label: t('projects.assemblies.area'), sort: true, width: '15%', dataType: DATA_TYPES.AREA, unit: useAreaUnit(),
    },
    {
      key: 'uval', label: t('projects.assemblies.uval'), sort: true, width: '15%', dataType: DATA_TYPES.NUMBER,
    },
    {
      key: 'vlt', label: t('projects.assemblies.vlt'), sort: true, width: '15%', dataType: DATA_TYPES.NUMBER,
    },
    {
      key: 'shgc', label: t('projects.assemblies.shgc'), sort: true, width: '15%', dataType: DATA_TYPES.NUMBER,
    },
    ],
  }];

  const user = useSelector(getUser);
  const isDesigner = user.role === ROLE_DESIGNER;

  const { formatDate, formatNumber, formatEmail } = useFormat();

  return (
    <>
      <Seo title={t('projects.title')} />

      <div className="py-6">

        <Breadcrumb current={CONTAINER_PROJECT} />

        <Meta
          fetchDataHook={useLazyGetProjectInfoQuery}
          properties={[
            { key: 'name', label: t('projects.meta.name') },
            { key: 'no_users', label: t('projects.meta.no_users') },
            { key: 'country', label: t('projects.meta.location') },
            {
              key: 'area', label: t('projects.meta.area_facade'), formatter: formatNumber, unit: 'm2',
            },
            {
              key: 'percent_glazing', label: t('projects.meta.percentage_glazing'), formatter: formatNumber, unit: '%',
            },
            { key: 'last_user', label: t('projects.meta.last_user'), formatter: formatEmail },
            { key: 'last_session', label: t('projects.meta.last_session'), formatter: formatDate },
          ]}
        />

        {/*
          Notes:
          building type?
          no city, just country?
          stage of project?
          percentage glazing. No total area glazed?
          no firm available?
          no user name?
        */}

        <Metrics
          fetchDataHook={useLazyGetProjectsMetricQuery}
        />

        <section className="flex gap-8">
          <div className="w-full">
            <DataGrid
              title={t('projects.assemblies.title')}
              properties={ProjectAssembliesDatagrid}
              informationMessage={isDesigner ? 'In the Guardian Glass for BIM application in Revit, ensure you assign glazing to all façades and surfaces.' : 'Your invited users may have used the application on a project but have yet to generate a Report.'}
              itemsPerPage={5}
              isPagination
              lazyFetchDataHook={useLazyGetProjectAssembliesQuery}
            />
          </div>
        </section>

        <section className="flex gap-8">
          <div className="w-full">
            <DataGrid
              title={t('projects.dataGridTitle')}
              properties={ProjectReportDatagrid}
              informationMessage="In Guardian Glass for BIM, open it on a project and under the Outputs tab click to generate a report."
              itemsPerPage={5}
              lazyFetchDataHook={useLazyGetProjectsQuery}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Projects;
