import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { t } from 'i18next';
import useFormat from 'hooks/useFormat';
import useUserId from 'hooks/useUserId';
import { ROLE_DESIGNER } from 'constants';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';
import MetricsSkeleton from './MetricsSkeleton';
import MetricsActions from './MetricsActions';
import MetricsIntroduction from './MetricsIntroduction';
import IconLearning from '../Icon/IconLearning';
import { useLazyGetLearnUponUrlQuery } from '../../store/services/api';

const Metrics = ({
  fetchDataHook, properties, learningLinks, exclude, isProjectExist,
}) => {
  const userId = useUserId();
  const { formatNumber } = useFormat();

  const { data, isFetching } = fetchDataHook({
    userId,
  }, { skip: !userId });

  const METRIC_COURSES = 'courses';

  const user = useSelector(getUser);
  const [trigger, learnUpon] = useLazyGetLearnUponUrlQuery();

  const triggerLearnUponFetch = async () => {
    await trigger();
  };

  useEffect(() => {
    if (learnUpon.error?.data) {
      window.open(learnUpon.error?.data, '_blank');
    }
  }, [learnUpon]);

  useEffect(() => {
    if (data) {
      isProjectExist(data[0].value !== 0);
    }
  }, [data]);

  const getAndRedirectLearnUpon = () => {
    triggerLearnUponFetch();
  };

  return (
    <div className="flex-grow">
      <dl className="flex flex-col md:flex-row rounded-lg bg-lowlight-primary overflow-hidden shadow divide-y divide-gray-200 md:divide-y-0 md:divide-x h-full">

        {isFetching && (
          <MetricsSkeleton />
        )}

        {!isFetching && learningLinks && (user.role === ROLE_DESIGNER) && data[0].value === 0
          && <MetricsIntroduction />}

        {!isFetching && learningLinks && (user.role === ROLE_DESIGNER) && data[0].value !== 0
          && <MetricsActions />}

        {data && data.filter(({ name }) => !exclude.includes(name)).map(({
          label, baseline, positivity, value, unit, name,
        }) => {
          const changeInPercents = +(((value - baseline) / baseline) * 100).toFixed(2);

          if (name === METRIC_COURSES) {
            return (
              <div key={name} className="flex w-1/5 bg-slate-200 hover:bg-slate-300 cursor-default">
                <div className="px-4 py-5 sm:p-6 items-center ">

                  <span className="text-sm font-normal text-base-primary">{t('metrics.learningPlatform')}</span>
                  <p className="text-highlight-primary font-medium text-base">
                    {value}
                    {' '}
                    {t('metrics.of')}
                    {' '}
                    {baseline}
                    {' '}
                    {t('metrics.completed')}
                  </p>

                  <div className="block bg-white h-2 w-full rounded-md overflow-hidden mt-2">
                    {baseline > 0
                      && (
                        <div className="h-full bg-highlight-secondary" style={{ width: `${(value / baseline) * 100}%` }} />
                      )}
                  </div>

                  <button
                    type="submit"
                    className="bg-[#38c7ba] hover:bg-gray-500 px-4 py-2 mt-4 text-sm font-medium text-white border-white rounded-md"
                    onClick={() => getAndRedirectLearnUpon()}
                  >
                    <div className="flex flex-row">
                      <IconLearning />
                      Learning Platform
                    </div>
                  </button>
                </div>
              </div>
            );
          }

          return (
            <div key={name} className="flex-1 px-4 py-5 sm:p-6 items-center">
              <div className="flex items-center gap-2">
                <span className="text-[#38c7ba] text-base font-medium text-base-primary">{label}</span>
              </div>
              <div className="mt-2 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-highlight-primary gap-3">
                  {properties?.[name]?.icon
                    && (
                      <div className="bg-highlight-secondary w-10 h-10 rounded-full flex items-center justify-center">
                        <span className="w-5 h-5 inline-block text-highlight-primary">{properties?.[name]?.icon}</span>
                      </div>
                    )}
                  {formatNumber(value, 1)}
                  <span className="text-base">{unit}</span>
                  <span className="ml-2 text-sm font-medium text-base-secondary">
                    {baseline && `${t('metrics.from')} ${baseline}${unit || ''}`}
                  </span>
                </div>

                {baseline && (
                  <div
                    className={classNames('inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0', {
                      'bg-gray-200 text-gray-800': !positivity || changeInPercents === 0,
                      'bg-green-100 text-green-800': (changeInPercents > 0 && positivity === 'gt') || (changeInPercents < 0 && positivity === 'lt'),
                      'bg-red-100 text-red-800': (changeInPercents > 0 && positivity === 'lt') || (changeInPercents < 0 && positivity === 'gt'),
                    })}
                  >
                    {!!changeInPercents && changeInPercents > 0 && (
                      <ArrowSmUpIcon
                        className={classNames('-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5', {
                          'text-gray-800': !positivity,
                          'text-green-500': positivity === 'gt',
                          'text-red-500': positivity === 'lt',
                        })}
                        aria-hidden="true"
                      />
                    )}
                    {!!changeInPercents && changeInPercents < 0 && (
                      <ArrowSmDownIcon
                        className={classNames('-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5', {
                          'text-gray-800': !positivity,
                          'text-green-500': positivity === 'lt',
                          'text-red-500': positivity === 'gt',
                        })}
                        aria-hidden="true"
                      />
                    )}

                    <span className="sr-only">
                      {positivity === 'gt' ? 'Increased' : 'Decreased'}
                      {' '}
                      {t('metrics.by')}
                    </span>
                    {`${changeInPercents}%`}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </dl>
    </div>
  );
};

Metrics.propTypes = {
  fetchDataHook: PropTypes.func.isRequired,
  properties: PropTypes.object,
  learningLinks: PropTypes.bool,
  exclude: PropTypes.array,
  isProjectExist: PropTypes.func,
};

Metrics.defaultProps = {
  properties: undefined,
  learningLinks: false,
  exclude: [],
  isProjectExist: () => { },
};

export default Metrics;
