import { Field } from 'formik';
import PropTypes from 'prop-types';

const ProfileInput = ({
  label, type, name, id, autoComplete, error, displayError,
}) => (
  <div className="col-span-6 sm:col-span-4">
    <label htmlFor={id} className="block text-sm font-medium text-base-secondary">
      {label}
    </label>
    <Field
      className="mt-1 focus:ring-highlight-primary focus:border-highlight-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      type={type}
      name={name}
      id={id}
      autoComplete={autoComplete}
    />
    <div className="h-5">
      {displayError && <span className="text-xs text-red-400">{error}</span>}
    </div>
  </div>
);

ProfileInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  error: PropTypes.string,
  displayError: PropTypes.bool.isRequired,
};

ProfileInput.defaultProps = {
  error: '',
  autoComplete: '',
};

export default ProfileInput;
