import { Field } from 'formik';
import PropTypes from 'prop-types';

const ProfileCheckbox = ({
  id, name, label,
}) => (
  <div className="flex items-start">
    <div className="h-5 flex items-center">
      <Field
        className="focus:ring-highlight-primary h-4 w-4 text-highlight-primary border-gray-300 rounded"
        id={id}
        name={name}
        type="checkbox"
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={id} className="font-medium text-base-primary">
        {label}
      </label>
    </div>
  </div>
);

ProfileCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ProfileCheckbox;
