import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  forwardRef, useImperativeHandle, useMemo, useState,
} from 'react';
import PaginationLabel from './PaginationLabel';

const getDisplayedTabs = (activePageTab, numberOfPages, maxNumberOfTabs) => {
  const numberOfVisibleTabs = numberOfPages < maxNumberOfTabs ? numberOfPages : maxNumberOfTabs;
  const numberOfSideTabs = Math.floor(numberOfVisibleTabs / 2);
  const centerTabNumber = numberOfSideTabs + 1;
  let pageStart = 1;

  if (numberOfPages > maxNumberOfTabs) {
    if (
      activePageTab > centerTabNumber
      && activePageTab < numberOfPages - numberOfSideTabs
    ) {
      pageStart = activePageTab - numberOfSideTabs;
    } else if (activePageTab >= numberOfPages - numberOfSideTabs) {
      pageStart = numberOfPages - numberOfSideTabs * 2;
    }
  }

  return Array.from(
    { length: numberOfVisibleTabs },
    (element, index) => index + pageStart,
  );
};

const Pagination = forwardRef(({
  totalItems,
  itemsPerPage,
  onChange,
  maxNumberOfTabs,
}, ref) => {
  const [activePageTab, setActivePageTab] = useState(1);
  const numberOfPages = Math.ceil(totalItems / itemsPerPage);
  const limit = itemsPerPage * activePageTab;
  const limitLabel = limit > totalItems ? totalItems : limit;
  const offsetLabel = limit - itemsPerPage + 1;
  const isPaginationVisible = totalItems > itemsPerPage;
  const displayedTabs = useMemo(
    () => getDisplayedTabs(activePageTab, numberOfPages, maxNumberOfTabs),
    [activePageTab, numberOfPages, maxNumberOfTabs],
  );

  const onTabClickHandler = (newActivePageTab) => {
    setActivePageTab(newActivePageTab);
    onChange(newActivePageTab);
  };

  const onNextClickHandler = () => {
    if (activePageTab < numberOfPages) {
      const newActivePageTab = activePageTab + 1;
      setActivePageTab(newActivePageTab);
      onChange(newActivePageTab);
    }
  };

  const onPreviousClickHandler = () => {
    if (activePageTab > 1) {
      const newActivePageTab = activePageTab - 1;
      setActivePageTab(newActivePageTab);
      onChange(newActivePageTab);
    }
  };

  useImperativeHandle(ref, () => ({
    resetPagination() {
      setActivePageTab(1);
    },
  }));

  return (
    <div className="py-3 flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        {isPaginationVisible && (
          <div
            aria-hidden="true"
            className={classNames('relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-lowliest-primary hover:bg-gray-50 cursor-pointer', {
              'hover:bg-lowlight-primary text-gray-300 cursor-default':
                  activePageTab === 1,
            })}
            onClick={onPreviousClickHandler}
          >
            Previous
          </div>
        )}
        <PaginationLabel
          offset={offsetLabel}
          limit={limitLabel}
          totalItems={totalItems}
        />
        {isPaginationVisible && (
        <div
          aria-hidden="true"
          className={classNames('ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-lowlight-primary hover:bg-gray-50 cursor-pointer', {
            'hover:bg-lowlight-primary text-gray-300 cursor-default':
                  activePageTab === numberOfPages,
          })}
          onClick={onNextClickHandler}
        >
          Next
        </div>
        )}
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <PaginationLabel
          offset={offsetLabel}
          limit={limitLabel}
          totalItems={totalItems}
        />
        {isPaginationVisible && (
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <div
                aria-hidden="true"
                className={classNames('relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-lowlight-primary text-sm font-medium text-base-secondary hover:bg-gray-50 cursor-pointer', {
                  'hover:bg-lowlight-primary text-gray-300 cursor-default': activePageTab === 1,
                })}
                onClick={onPreviousClickHandler}
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              {displayedTabs.map((pageTab) => (
                <div
                  aria-hidden="true"
                  key={`tab-key-${pageTab}`}
                  aria-current="page"
                  className={classNames('bg-lowlight-primary border-gray-300 text-base-secondary hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer', {
                    'z-10 border-sky-500 text-highlight-primary': pageTab === activePageTab,
                  })}
                  onClick={() => onTabClickHandler(pageTab)}
                >
                  {pageTab}
                </div>
              ))}
              <div
                aria-hidden="true"
                className={classNames('relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-lowlight-primary text-sm font-medium text-base-secondary hover:bg-gray-50 cursor-pointer', {
                  'hover:bg-lowlight-primary text-gray-300 cursor-default': activePageTab === numberOfPages,
                })}
                onClick={onNextClickHandler}
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </div>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
});

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  maxNumberOfTabs: (props, propName, componentName) => {
    if (props[propName] < 3) {
      return new Error(
        `Invalid prop value of ${propName} supplied to ${componentName}! Value must be greater than 3.`,
      );
    }
  },
  onChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  maxNumberOfTabs: 7,
};

export default Pagination;
