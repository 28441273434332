import { Link } from 'react-router-dom';
import { DASHBOARD_PATH } from 'routes';

const Header = () => (
  <header className="bg-gradient-to-r from-highlight-primary via-highlight-primary to-highlight-secondary w-full h-24 top-0 left-0 fixed">
    <div className="w-full h-full block right-0 md:bg-header-pattern absolute bg-right bg-no-repeat bg-contain" />
    <div className="flex items-center h-full">
      <Link to={DASHBOARD_PATH}>
        <div className="bg-logo bg-contain bg-center bg-no-repeat block h-16 w-64 ml-6" />
      </Link>
    </div>
  </header>
);

export default Header;
