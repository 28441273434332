import { createTheme } from '../utils';

const baseTheme = createTheme({
  highlightPrimary: '#1867B6',
  highlightSecondary: '#18B4D6',
  basePrimary: '#193459',
  baseSecondary: '#4D4F5C',
  lowlightPrimary: '#FFF',
  lowlightSecondary: '#F2F4F6',
  logo: "url('/logos/fenestra-mono.svg')",
  loginImage: "url('/images/login-fenestra.jpg')",
  loginLogo: "url('/logos/fenestra.svg')",
  bannerA: "url('/images/banner-1.png')",
  bannerB: "url('/images/banner-2.png')",
  bannerC: "url('/images/banner-3.png')",
  bannerD: "url('/images/banner-4.png')",
  brochureBanner: "url('/images/brochure-banner-3.png')",
});

export default baseTheme;
