import { t } from 'i18next';
import PageHeader from 'components/PageHeader/PageHeader';
import Seo from 'components/Seo/Seo';

const MSI = () => (
  <>
    <Seo title={t('projects.title')} />

    <div className="py-6">
      <PageHeader title={t('msi.title')} />

      <p>Structure TBC</p>
    </div>

  </>
);

export default MSI;
