import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { translation as en } from './en';
import { translation as fr } from './fr';

const languages = ['en', 'fr'];

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: 'en',
    detection: { order: ['path', 'navigator'] },
    interpolation: {
      escapeValue: false,
    },
    whitelist: languages,
    resources,
  });

export default i18n;
