import { DotsVerticalIcon } from '@heroicons/react/outline';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DropdownMenuItem from 'components/DropdownMenu/DropdownMenuItem';
import DropdownMenuDivider from 'components/DropdownMenu/DropdownMenuDivider';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import DataGridContext from '../DataGridContext';

const DataGridTableActionsMenu = ({ item }) => {
  const {
    isContact,
    isEdit,
    isDelete,
    isShare,
    isLink,
    selectedTab,
    properties,
    onItemEditHandler,
    onItemDeleteHandler,
    onItemShareHandler,
    onItemContactHandler,
  } = useContext(DataGridContext);

  const selectedTabProperties = properties.find(
    (property) => property.name === selectedTab.name,
  );

  const onItemEditClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onItemEditHandler(item);
  };

  const onItemShareClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onItemShareHandler(item);
  };

  const onItemDeleteClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onItemDeleteHandler(item);
  };

  const onItemLinkClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(item.product_url);
  };

  const onItemContactClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onItemContactHandler(item);
  };

  return (
    <DropdownMenu
      position="left"
      menuButton={(
        <DotsVerticalIcon className="h-5" />
      )}
    >

      {isEdit && (
        <DropdownMenuItem
          onItemClick={onItemEditClickHandler}
        >
          {t('DataGrid.editButton')}
        </DropdownMenuItem>
      )}

      {isContact && (
        <DropdownMenuItem
          onItemClick={onItemContactClickHandler}
        >
          {t('DataGrid.contactButton')}
        </DropdownMenuItem>
      )}

      {isShare && (
        <DropdownMenuItem
          onItemClick={onItemShareClickHandler}
        >
          {t('DataGrid.shareButton')}
        </DropdownMenuItem>
      )}

      {isLink && item.product_url && (
        <DropdownMenuItem
          onItemClick={onItemLinkClickHandler}
        >
          {t('DataGrid.openProductUrl')}
        </DropdownMenuItem>
      )}

      {(selectedTabProperties.isDelete || isDelete) && (
        <>
          {(isEdit || isShare)
            && <DropdownMenuDivider />}
          <DropdownMenuItem
            isWarning
            onItemClick={onItemDeleteClickHandler}
          >
            {t('DataGrid.deleteButton')}
          </DropdownMenuItem>
        </>
      )}
    </DropdownMenu>
  );
};

DataGridTableActionsMenu.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DataGridTableActionsMenu;
