import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  ComposedChart,
  ReferenceArea,
} from 'recharts';
import getColor from 'utils/colors';
import getMonthName from 'utils/getMonthName';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ReportChartYAxis from './ReportChartYAxis';
import ReportChartTooltip from './ReportChartTooltip';
import ReportChartXAxis from './ReportChartXAxis';
import ReportChartOverlay from './ReportChartOverlay';

const ReportChartBarNavigate = ({ chartData, properties }) => {
  const options = chartData[0].data.map((option) => option.key);
  const [activeOption, setActiveOption] = useState(options[0]);
  const selectedData = chartData[0].data.find((item) => item.key === activeOption) || null;
  const transformedChartData = selectedData ? selectedData.value.data.map((item, index) => ({ name: getMonthName(index), value: item, average: selectedData.value.average })) : []; // eslint-disable-line
  const activeIndex = options.findIndex((option) => option === activeOption);

  const navigateRight = () => {
    if (options[activeIndex + 1] !== undefined) {
      setActiveOption(options[activeIndex + 1]);
    }
  };

  const navigateLeft = () => {
    if (options[activeIndex - 1] !== undefined) {
      setActiveOption(options[activeIndex - 1]);
    }
  };

  return (
    <div className="h-72 py-6 relative">

      {!transformedChartData.length
        && <ReportChartOverlay label="No data available" />}

      {options.length
        && (
          <div className="absolute -top-12 right-32 z-40 bg-white p-3 rounded shadow flex gap-4">
            <ChevronLeftIcon
              className="text-slate-400 hover:text-slate-500 w-6  cursor-pointer"
              onClick={navigateLeft}
            />
            <select onChange={(e) => setActiveOption(e.target.value)}>
              {options.map((option) => (
                <option
                  value={option}
                  key={`option_${option}`}
                  selected={option === activeOption}
                >
                  {option}
                </option>
              ))}
            </select>
            <ChevronRightIcon
              className="text-slate-400 hover:text-slate-500 w-6 cursor-pointer"
              onClick={navigateRight}
            />
          </div>
        )}

      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={300}
          data={transformedChartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={<ReportChartXAxis />}
          />

          <YAxis
            tick={<ReportChartYAxis />}
            label={{
              value: chartData[0].units, fill: '#666', angle: -90, fontSize: 13,
            }}
          />

          <Tooltip content={<ReportChartTooltip />} />

          {properties[0]?.referenceAreas && properties[0]?.referenceAreas.map(({
            y1, y2, fill,
          }) => (
            <ReferenceArea
              y1={y1}
              y2={y2}
              fill={fill}
              fillOpacity={0.2}
              ifOverflow="extendDomain"
            />
          ))}

          <Legend />

          {properties.map((property, index) => (
            <>
              <Line
                key={`bar_${property.label}`}
                dataKey="value"
                name={property.label}
                unit={chartData[index].units}
                fill={property.color || getColor(index)}
                stackId={property.stackId || null}
              />

              <Line type="monotone" dataKey="average" stroke="#ff7300" strokeWidth={2} name="Average" />
            </>
          ))}

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReportChartBarNavigate;

ReportChartBarNavigate.propTypes = {
  chartData: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
};
