import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { useGetImportCustomGlazingQuery } from '../../store/services/api';
import Spinner from '../../components/Spinner/Spinner';
import { ASSEMBLIES_PATH } from '../../routes';

const ImportCustomGlazing = () => {
  const query = useQuery();
  const token = query.get('glass_token');
  const { data, error, isLoading } = useGetImportCustomGlazingQuery(token);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (error?.data && !isLoading) {
        toast.error('Something went wrong. Please try again later.');
      } else if (data) {
        if (data.isFenestraProUser && data.isGuardianUser) {
          toast.success('Custom Glazing successfully Imported. Please choice which platform you want to use.');
        } else if (data.isFenestraProUser) {
          toast.success('Custom Glazing successfully Imported. You will be navigating to the Fenestra Platform.');
          window.open('https://app.fenestrapro.com/glassdatabase', '_self');
        } else if (data.isGuardianUser) {
          toast.success('Custom Glazing successfully Imported. You will be navigating to the Guardian Platform.');
          navigate(`${ASSEMBLIES_PATH}`);
        }
      }
    } catch {
      toast.error('Importing glazing issue. Please try again');
    }
  }, [data, isLoading]);

  if (data) {
    return (
      <div className="flex flex-row h-screen">
        <div className="flex-1 bg-slate-300 items-center justify-center">
          <button
            onClick={() => window.open('https://platform.fenestrapro.com/', '_self')}
            type="button"
            className="bg-fenestrapro-background bg-contain bg-no-repeat w-[400px] h-[200px]"
            aria-label="Go to Fenestrapro"
          />
        </div>
        <div className="flex-1 bg-neutral-400 items-center justify-center">
          <button
            onClick={() => window.open('https://fpro.fenestrapro.com/', '_self')}
            type="button"
            className="bg-guardian-background bg-contain bg-no-repeat fill-blue-500 w-[400px] h-[200px] ml-[300px]"
            aria-label="Go to Guardian Bim"
          />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Spinner isLoading />
        {' '}
        <span className="pl-3 animate-pulse">Please Wait Importing Custom Glazing</span>
      </div>
    );
  }

  if (error?.data) {
    return (
      <div>
        <h2>{error.data}</h2>
      </div>
    );
  }
};

export default ImportCustomGlazing;
