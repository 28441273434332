import { t } from 'i18next';
import { useFormik } from 'formik';
import { useLoginMutation } from 'store/services/login';
import { isUserAuthenticated, setUser } from 'store/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Link, Navigate, useLocation, useNavigate,
} from 'react-router-dom';
import * as Yup from 'yup';
import Button from 'components/Button/Button';
import {
  DASHBOARD_PATH,
  PASSWORD_RECOVERY_PATH,
  PASSWORD_RECOVERY_REQUEST_PATH,
} from 'routes';
import LoginInput from './LoginInput';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
  password: Yup.string().min(2, t('validationErrors.tooShort')).max(50, t('validationErrors.tooLong')).required(t('validationErrors.required')),
});

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isUserLoggedIn = useSelector(isUserAuthenticated);

  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberme: false,
    },
    validationSchema,
    onSubmit: async (credentials) => {
      try {
        const from = location.state?.from?.pathname || '/';
        const { error } = await login(credentials);

        dispatch(setUser(error.data));
        toast.success(t('toasterMessage.success.loginSuccessful'));
        navigate(from, { replace: true });
      } catch (error) {
        toast.error(t('toasterMessage.error.loginFailed'));
      }
    },
  });

  return isUserLoggedIn ? (
    <Navigate
      to={DASHBOARD_PATH}
      state={{ from: location }}
      replace
    />
  ) : (
    <div className="min-h-full flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <div
              className="h-16 w-auto block bg-login-logo bg-no-repeat bg-contain"
            />
            <h2 className="text-xl font-bold text-slate-600 mt-12">
              {t('loginScreen.header')}
            </h2>

          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit} method="POST" className="space-y-6">
                <div>
                  <LoginInput
                    id="email"
                    type="email"
                    name="email"
                    label={t('loginScreen.emailInputLabel')}
                    value={values.email}
                    error={errors.email}
                    displayError={!!errors.email && !!touched.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="space-y-1">
                  <LoginInput
                    id="password"
                    type="password"
                    name="password"
                    label={t('loginScreen.passInputLabel')}
                    value={values.password}
                    error={errors.password}
                    displayError={!!errors.password && !!touched.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <label
                      htmlFor="remember-me"
                      className="ml-2 text-sm text-base-primary flex items-center"
                    >
                      <input
                        id="remember-me"
                        name="rememberme"
                        type="checkbox"
                        className="h-4 w-4 text-highlight-primary focus:ring-sky-500 border-gray-300 rounded mr-2"
                        value={values.rememberme}
                        onChange={handleChange}
                      />
                      {t('loginScreen.rememberMeLabel')}
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link
                      to={`${PASSWORD_RECOVERY_PATH}/${PASSWORD_RECOVERY_REQUEST_PATH}`}
                      className="font-medium text-highlight-primary hover:text-sky-500"
                    >
                      {t('loginScreen.forgotYourPassword')}
                    </Link>
                  </div>
                </div>

                <div>
                  <Button
                    type="submit"
                    disabled={Object.keys(errors).length > 0 || isLoading}
                    isBlock
                    isLoading={isLoading}
                  >
                    {t('loginScreen.signInButton')}
                  </Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <div
          className="absolute inset-0 h-full w-full object-cover bg-login-image bg-cover bg-no-repeat"
        />
      </div>
    </div>
  );
};

export default Login;
