import { t } from 'i18next';
import { useDeleteAssemblyMutation, useLazyGetAssembliesQuery } from 'store/services/api';
import DataGrid from 'components/DataGrid/DataGrid';
import PageHeader from 'components/PageHeader/PageHeader';
import { SORT_ORDER_ASC, DATA_TYPES } from 'components/DataGrid/DataGridConstants';
import Seo from 'components/Seo/Seo';
import { serviceUrl } from 'store/services/settings';
import toast from 'react-hot-toast';
import { REGIONS } from 'constants';

const Assemblies = () => {
  const [deleteAssembly] = useDeleteAssemblyMutation();

  const dataGridProperties = [
    {
      key: 'name', label: t('assemblies.tableHeader.name'), sort: true, width: '30%', bold: true, defaultSortOrder: SORT_ORDER_ASC, dataType: DATA_TYPES.STRING,
    },
    {
      key: 'uval', label: t('assemblies.tableHeader.uval'), sort: true, dataType: DATA_TYPES.NUMBER, width: '10%',
    },
    {
      key: 'shgc', label: t('assemblies.tableHeader.shgc'), sort: true, dataType: DATA_TYPES.NUMBER, width: '10%',
    }, {
      key: 'vlt', label: t('assemblies.tableHeader.vlt'), sort: true, dataType: DATA_TYPES.NUMBER, width: '10%',
    },
    {
      key: 'standard', label: t('assemblies.tableHeader.standard'), sort: true, width: '30%', dataType: DATA_TYPES.STRING,
    },
    {
      key: 'region', label: t('assemblies.tableHeader.region'), sort: true, width: '30%', defaultSortOrder: SORT_ORDER_ASC, dataType: DATA_TYPES.STRING,
    }];

  const dataGridDetailsProperties = [
    {
      key: 'name', label: t('assemblies.tableHeader.name'),
    },
    {
      key: 'desc', label: t('assemblies.tableHeader.description'),
    },
    {
      key: 'uval', label: t('assemblies.tableHeader.uval'),
    },
    {
      key: 'shgc', label: t('assemblies.tableHeader.shgc'),
    }, {
      key: 'vlt', label: t('assemblies.tableHeader.vlt'),
    },
    {
      key: 'standard', label: t('assemblies.tableHeader.standard'),
    },
    {
      key: 'region', label: t('assemblies.tableHeader.region'),
    }, {
      key: 'epd', label: t('assemblies.tableHeader.epd'),
    },
    {
      key: 'product_url', label: t('assemblies.tableHeader.productUrl'), dataType: DATA_TYPES.URL,
    }];

  const dataGridTabsProperties = [{
    name: t('assemblies.tabs.standard'),
    urlSlug: serviceUrl.standard,
    dataGridProps: dataGridProperties,
    isDelete: false,
  }, {
    name: t('assemblies.tabs.custom'),
    urlSlug: serviceUrl.custom,
    dataGridProps: dataGridProperties,
    isDelete: true,
  }];

  const onItemDelete = async ({ id }, onSuccessCallback) => {
    try {
      const result = await deleteAssembly({ itemId: id, tabUrlSlug: 'custom' });

      if (result.data) {
        toast.success(t('toasterMessage.success.codeDelete'));
        onSuccessCallback();
      } else {
        toast.error(result.error.data);
      }
    } catch (error) {
      toast.error(t('toasterMessage.error.codeDelete'));
    }
  };

  return (
    <>
      <Seo title={t('assemblies.title')} />

      <section className="py-6">
        <PageHeader title={t('assemblies.title')} />

        <DataGrid
          properties={dataGridTabsProperties}
          detailsProperties={dataGridDetailsProperties}
          displayModalOnItemClick
          isShare
          isContact
          isExport
          isPagination
          isLink
          isInlineFilter
          inlineFilterProperties={[
            {
              type: 'range', key: 'uval', label: 'U-Value', min: 0, max: 7, values: [0, 7],
            },
            {
              type: 'range', key: 'shgc', label: 'Solar Heat Gain Coefficient', min: 0, max: 1, values: [0, 1], step: 0.01,
            },
            {
              type: 'range', key: 'vlt', label: 'Visible Light Transmittance', min: 0, max: 1, values: [0, 1], step: 0.01,
            },
            {
              type: 'select', key: 'region', label: 'Region', values: REGIONS,
            },
          ]}
          informationMessage="To create a Custom Glazing Make up select “Create Custom Glazing” below, generate a bespoke make up and select to export to Guardian Glass for BIM."
          itemsPerPage={10}
          lazyFetchDataHook={useLazyGetAssembliesQuery}
          onItemDelete={onItemDelete}
        />
      </section>
    </>
  );
};

export default Assemblies;
