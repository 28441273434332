import BrochurePage from 'components/BrochurePage/BrochurePage';

const PageDetailed = () => {
  const tr = true;
  const fl = false;

  return (
    <BrochurePage
      title="Detail Design Tools"
      color="purple"
      activeColor="bg-[#1e68b5]"
      youtubeUrl="https://www.youtube.com/embed/89ipUhxg5NM?rel=0"
      image="/images/brochure/Detail.png"
      links={[
        {
          label: 'Download Guardian Glass for BIM',
          url: 'https://api.fenestrapro.com/v0/msi/download/beta/',
        },
      ]}
      copy="Guardian Glass for BIM's Detail Design Tools supports users at the schematic and detailed design stages to:"
      copyImportants={[
        'Select and specify glazing to meet performance requirements.',
        'Generate the optimal geometric configuration.',
        'Download detailed specification and performance reports.',
      ]}
      isNext={tr}
      isBack={tr}
      isLast={fl}
      nextIndex={3}
      backIndex={1}
    />
  );
};

export default PageDetailed;
