import {
  ChevronDownIcon, ChevronUpIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from '../DataGridConstants';
import DataGridContext from '../DataGridContext';

const DataGridTableHeader = () => {
  const [internalProperties, setInternalProperties] = useState();
  const {
    selectedTab, onSortOrderChange, isFetching, isEdit, isDelete, isShare,
  } = useContext(DataGridContext);

  const onLabelClickHandler = (selectedKey, sortOrder) => {
    const newSortOrder = sortOrder !== SORT_ORDER_ASC ? SORT_ORDER_ASC : SORT_ORDER_DESC;

    onSortOrderChange(selectedKey, newSortOrder);
    setInternalProperties(internalProperties.map((element) => (
      element.key === selectedKey
        ? { ...element, sortOrder: newSortOrder }
        : { ...element, sortOrder: '' }
    )));
  };

  useEffect(() => {
    if (!isFetching && !internalProperties) {
      setInternalProperties(selectedTab.dataGridProps.map((prop) => ({
        ...prop,
        sortOrder: prop.defaultSortOrder || '',
      })));
    }
  }, [isFetching]);

  return (
    <thead className="bg-gray-50">
      <tr>
        {internalProperties && internalProperties.map(({
          label, key, sort, sortOrder, width, unit,
        }) => (
          <th
            key={`col_${key}`}
            scope="col"
            className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-base-primary sm:pl-6"
            style={{ width }}
          >
            <div
              aria-hidden="true"
              onClick={() => sort && onLabelClickHandler(key, sortOrder)}
              className={classNames('group flex items-center cursor-pointer', {
                'cursor-default': !sort,
              })}
            >
              <span>
                {label}
                {unit && <span className="text-gray-400 text-xs font-normal pl-1">{unit}</span>}
              </span>
              <span className={classNames('ml-2 flex-none rounded text-gray-400', {
                invisible: !sortOrder,
                'group-hover:visible group-hover:text-gray-300': sort,
                'group-hover:text-gray-400': sortOrder,
              })}
              >
                {sort && sortOrder === SORT_ORDER_DESC ? <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />}
              </span>
            </div>
          </th>
        ))}

        {(isEdit || isDelete || isShare) && (
          <th aria-label="offset-element" className="py-3 pl-3 pr-4 sm:pr-6" />
        )}

        <th scope="col" className="py-3 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">View</span>
        </th>
      </tr>
    </thead>
  );
};

export default DataGridTableHeader;
