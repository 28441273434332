import toast from 'react-hot-toast';
import { t } from 'i18next';
import Spinner from 'components/Spinner/Spinner';
import { useEffect } from 'react';
import AlertBar from 'components/AlertBar/AlertBar';
import { useHashQuery } from 'hooks/useQuery';
import { useGetSsoAuthTokenQuery } from 'store/services/login';
import { useDispatch } from 'react-redux';
import { setUser } from 'store/slices/user';
import { useNavigate } from 'react-router-dom';

const Sso = () => {
  const query = useHashQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = query.get('id_token');
  const { data, error, isLoading } = useGetSsoAuthTokenQuery(token);

  useEffect(() => {
    try {
      if (error?.data && !isLoading) {
        dispatch(setUser(error?.data));
        toast.success(t('sso.welcome'));
        navigate('/dashboard');
      }
    } catch {
      toast.error('SSO issue. Please try again');
    }
  }, [data, isLoading]);

  if (data) {
    return (
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Spinner isLoading />
        {' '}
        <span className="pl-3 animate-pulse">
          {t('sso.redirect')}
        </span>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Spinner isLoading />
        {' '}
        <span className="pl-3 animate-pulse">{t('sso.checking')}</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <AlertBar label="Invalid Login Request! Please go Back to the Guardian Glass Resource Hub and update your profile" />
        <button
          onClick={() => window.open('https://www.guardianglass.com/')}
          type="button"
          className="ml-4 text-base font-medium text-[#38c7ba] hover:text-[#38c7ba] underline bg-blue"
        >
          Guardian Glass Resource Hub
        </button>
      </div>
    );
  }
};

export default Sso;
