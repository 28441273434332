import Modal from 'components/Modal/Modal';
import PropTypes from 'prop-types';

const VideoModal = ({
  open, onClose, videoLink,
}) => {
  const onCloseButtonClickHandler = () => {
    onClose();
  };

  return (
    <Modal open={open} setOpen={onCloseButtonClickHandler}>
      <div className="p-4">
        <iframe width="100%" height="400" src={videoLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </div>
    </Modal>
  );
};

VideoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  videoLink: PropTypes.string.isRequired,
};

export default VideoModal;
