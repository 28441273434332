import PropTypes from 'prop-types';

const ReportChartXAxis = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="middle" fill="#999" fontSize={13}>
      {payload.value}
    </text>
  </g>
);

ReportChartXAxis.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

ReportChartXAxis.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};

export default ReportChartXAxis;
