import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'components/Spinner/Spinner';

const Button = forwardRef(
  (
    {
      children,
      type,
      variant,
      size,
      isBlock,
      disabled,
      isLoading,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      disabled={disabled}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
      className={classNames('inline-flex items-center justify-center rounded-md border border-transparent px-4 h-10 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto', {
        'bg-[#38c7ba] disabled:bg-slate-200': variant === 'primary',
        'bg-blue-400 hover:bg-blue-500 disabled:bg-blue-200': variant === 'action',
        'bg-gray-400 hover:bg-gray-500 disabled:bg-slate-200': variant === 'secondary',
        'bg-red-600 hover:bg-red-700': variant === 'warning',
        'md:w-full': isBlock,
        'bg-blue-600 hover:bg-blue-700 disabled:bg-slate-200': variant === 'blue',
        'bg-orange-400 hover:bg-orange-500 disabled:bg-slate-200': variant === 'orange',
        'bg-purple-600 hover:bg-purple-700 disabled:bg-slate-200': variant === 'purple',
        'bg-[#38c7ba] hover:bg-purple-700 disabled:bg-slate-200': variant === 'guardian',
        'bg-[#3267B2] border-white rounded-md hover:bg-[#28528E] disabled:bg-slate-200 h-12 px-6 m-2 text-lg': variant === 'brochure',
        'bg-[#38c7ba] border-white rounded-md hover:bg-[#21776F] disabled:bg-slate-200 h-12 px-6 m-2 text-lg': variant === 'brochureDownload',
        'bg-[#38c7ba] hover:bg-gray-500 absolute -bottom-4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-6 py-2 text-lg text-white border-white rounded-md': variant === 'brochureVideo',
        'bg-[#38c7ba] border-white rounded-md hover:bg-[#21776F] disabled:bg-slate-200 h-12 px-6 m-2 text-lg p-10': variant === 'download',

      })}
    >
      <Spinner
        isLoading={isLoading}
        className="mr-2"
      />
      {' '}
      {children}
    </button>
  ),
);

export default Button;

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.string,
  size: PropTypes.string,
  isBlock: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'normal',
  isBlock: false,
  disabled: false,
  isLoading: false,
};
