import { createApi } from '@reduxjs/toolkit/query/react';
import {
  baseQueryWithErrorHandling,
  defaultQuery,
  serviceUrl,
} from './settings';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({

    getGlobalsMetric: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.globals}/${userId}/${serviceUrl.metric}`, queryParams)
      ),
    }),

    getRegionalsMetric: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.regionals}/${userId}/${serviceUrl.metric}`, queryParams)
      ),
    }),

    getAsmsMetric: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.asms}/${userId}/${serviceUrl.metric}`, queryParams)
      ),
    }),

    getDesignersMetric: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.designers}/${userId}/${serviceUrl.metric}`, queryParams)
      ),
    }),

    getRegionals: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.globals}/${userId}/${serviceUrl.regionals}`, queryParams)
      ),
    }),

    getRegionalsTab: builder.query({
      query: ({ queryParams, userId, tabUrlSlug }) => (
        defaultQuery(`${serviceUrl.regionals}/${userId}/${tabUrlSlug}`, queryParams)
      ),
    }),

    getAsms: builder.query({
      query: ({ queryParams, userId, tabUrlSlug }) => (
        defaultQuery(`${serviceUrl.asms}/${userId}/${tabUrlSlug}`, queryParams)
      ),
    }),

    getDesignersProjects: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.designers}/${userId}/${serviceUrl.projects}`, queryParams)
      ),
    }),

    getAsmsEvents: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.asms}/${userId}/${serviceUrl.events}`, queryParams)
      ),
    }),

    getDesignersEvents: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.designers}/${userId}/${serviceUrl.events}`, queryParams)
      ),
    }),

    getCodes: builder.query({
      query: ({ queryParams }) => (
        defaultQuery(serviceUrl.codes, queryParams)
      ),
    }),

    getAssemblies: builder.query({
      query: ({ queryParams, tabUrlSlug }) => (
        defaultQuery(`${serviceUrl.assemblies}/${tabUrlSlug}`, queryParams)
      ),
    }),

    deleteAssembly: builder.mutation({
      query: ({ itemId, tabUrlSlug }) => ({
        url: `${serviceUrl.assemblies}/${tabUrlSlug}/${itemId}`,
        method: 'DELETE',
      }),
    }),

    getProjects: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.projects}/${userId}/${serviceUrl.reports}`, queryParams)
      ),
    }),

    getProjectAssemblies: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.projects}/${userId}/${serviceUrl.assemblies}`, queryParams)
      ),
    }),

    getProjectById: builder.query({
      query: (id) => (
        defaultQuery(`${serviceUrl.projects}/${id}`)
      ),
    }),

    getProjectsMetric: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.projects}/${userId}/${serviceUrl.metric}`, queryParams)
      ),
    }),

    getProjectsChart: builder.query({
      query: ({ queryParams, userId }) => (
        defaultQuery(`${serviceUrl.projects}/${userId}/${serviceUrl.chart}`, queryParams)
      ),
      transformResponse: (response) => response.reduce((acc, series) => ({
        ...acc,
        [series.name]: {
          units: series.units,
          data: series.data.map((seriesDataItem) => ({
            ...seriesDataItem,
            datetime: new Date(seriesDataItem.datetime).getTime(),
          })),
        },
      }), {}),
    }),

    getProfileById: builder.query({
      query: (id) => (
        defaultQuery(`${serviceUrl.profiles}/${id}`)
      ),
    }),

    updateProfile: builder.mutation({
      query: ({ id, userData }) => ({
        url: `${serviceUrl.profiles}/${id}`,
        method: 'PUT',
        body: userData,
      }),
    }),

    sendProductEnquiry: builder.mutation({
      query: ({ data }) => ({
        url: `${serviceUrl.assemblies}/enquiry`,
        method: 'POST',
        body: data,
      }),
    }),

    createCode: builder.mutation({
      query: (codeItem) => ({
        url: `${serviceUrl.codes}`,
        method: 'POST',
        body: codeItem,
      }),
    }),

    updateCode: builder.mutation({
      query: ({ codeItem, itemId }) => ({
        url: `${serviceUrl.codes}/${itemId}`,
        method: 'PUT',
        body: codeItem,
      }),
    }),

    deleteCode: builder.mutation({
      query: (itemId) => ({
        url: `${serviceUrl.codes}/${itemId}`,
        method: 'DELETE',
      }),
    }),

    getReports: builder.query({
      query: ({ queryParams }) => (
        defaultQuery(`${serviceUrl.reports}`, queryParams)
      ),
    }),

    getMonthlyFacadeReport: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/monthly_facades`, queryParams)
      ),
    }),

    getMonthlyOrientationReport: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/monthly_orientations`, queryParams)
      ),
    }),

    getOverviewGeometry: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/overview_geometry`, queryParams)
      ),
    }),

    getOverviewPerformance: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/overview_performance`, queryParams)
      ),
    }),

    getProjectOverview: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/overview`, queryParams)
      ),
    }),

    getProjectGlazing: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/glazing`, queryParams)
      ),
    }),

    getAnnualSurfaceHeatgain: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/annual_surfaces`, queryParams)
      ),
    }),

    getAnnualOrientationHeatgain: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/annual_orientations`, queryParams)
      ),
    }),

    getAnnualFacadeHeatgain: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/annual_facades`, queryParams)
      ),
    }),

    getSurfaceAreaHeatgainAverage: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/areas_surfaces`, queryParams)
      ),
    }),

    getSurfaceFacadeHeatgainAverage: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/areas_surfaces`, queryParams)
      ),
    }),

    getSolarOrientations: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/solar_orientation`, queryParams)
      ),
    }),

    getSolidGlazedAreas: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/solid_glazed_area`, queryParams)
      ),
    }),

    getAnnualSolarHeatgain: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/annual_solar_heat_gain`, queryParams)
      ),
    }),

    getMonthlySolarHeatgain: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/monthly_solar_heat_gain`, queryParams)
      ),
    }),
    getPeakMonthlySolarHeatgain: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/peak_monthly_solar_heat_gain`, queryParams)
      ),
    }),

    getAnnualDaylightFactor: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/annual_daylight_factor`, queryParams)
      ),
    }),

    getAnnualShading: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.reports}/${id}/annual_shading`, queryParams)
      ),
    }),

    sendShare: builder.mutation({
      query: ({ data, id }) => ({
        url: `${serviceUrl.baseUrl}/share/${id}`,
        method: 'PUT',
        body: data,
      }),
    }),

    getColleagues: builder.query({
      query: ({ id, queryParams }) => (
        defaultQuery(`${serviceUrl.colleagues}/${id}`, queryParams)
      ),
    }),

    inviteUser: builder.mutation({
      query: ({ data, mode }) => ({
        url: `${serviceUrl.users}/invite?mode=${mode}`,
        method: 'POST',
        body: data,
      }),
    }),

    getReportsByUser: builder.query({
      query: ({ userId, queryParams }) => (
        defaultQuery(`${serviceUrl.designers}/${userId}/${serviceUrl.reports}`, queryParams)
      ),
    }),

    getUserInfo: builder.query({
      query: ({ userId }) => (
        defaultQuery(`${serviceUrl.designers}/${userId}`)
      ),
    }),

    getProjectInfo: builder.query({
      query: ({ userId }) => (
        defaultQuery(`${serviceUrl.projects}/${userId}`)
      ),
    }),

    getLearnUponUrl: builder.query({
      query: () => (
        defaultQuery(`${serviceUrl.users}/learnUponSsoLogin`)
      ),
    }),

    getBreadcrumbAsm: builder.query({
      query: ({ designerId }) => (
        defaultQuery(`${serviceUrl.breadcrumbs}/asm/${designerId}`)
      ),
    }),

    getBreadcrumbRegional: builder.query({
      query: ({ asmId }) => (
        defaultQuery(`${serviceUrl.breadcrumbs}/regional/${asmId}`)
      ),
    }),

    getMsiUrl: builder.query({
      query: () => (
        defaultQuery(`${serviceUrl.downloads}/msi`)
      ),
    }),

    getIsFirstTimeLoginById: builder.query({
      query: () => (
        defaultQuery(`${serviceUrl.users}/isUserFirstTime`)
      ),
    }),

    getIsUserFirstTime: builder.query({
      query: () => (
        defaultQuery(`${serviceUrl.users}/updateIsUserFirstTime`)
      ),
    }),

    getImportCustomGlazing: builder.query({
      query: (token) => ({
        url: `${serviceUrl.import}/customGlazing/${token}`,
      }),
    }),

    getGlazingQueryInfo: builder.query({
      query: ({ userId, glassId }) => ({
        url: `${serviceUrl.revit}/glazingQuery/${userId}/${glassId}`,
      }),
    }),

  }),
});

export const {
  useLazyGetCodesQuery,
  useLazyGetRegionalsQuery,
  useGetGlobalsMetricQuery,
  useLazyGetRegionalsTabQuery,
  useGetRegionalsMetricQuery,
  useLazyGetAsmsQuery,
  useGetAsmsMetricQuery,
  useGetAsmsEventsQuery,
  useLazyGetDesignersProjectsQuery,
  useGetDesignersEventsQuery,
  useGetDesignersMetricQuery,
  useLazyGetAssembliesQuery,
  useLazyGetProjectsQuery,
  useLazyGetProjectAssembliesQuery,
  useLazyGetProjectsMetricQuery,
  useGetProjectsChartQuery,
  useGetProfileByIdQuery,
  useUpdateProfileMutation,
  useSendProductEnquiryMutation,
  useGetProjectByIdQuery,
  useCreateCodeMutation,
  useUpdateCodeMutation,
  useDeleteCodeMutation,
  useDeleteAssemblyMutation,
  useLazyGetReportsQuery,
  useLazyGetMonthlyFacadeReportQuery,
  useLazyGetMonthlyOrientationReportQuery,
  useLazyGetOverviewGeometryQuery,
  useLazyGetOverviewPerformanceQuery,
  useLazyGetProjectOverviewQuery,
  useLazyGetProjectGlazingQuery,
  useLazyGetAnnualSurfaceHeatgainQuery,
  useLazyGetAnnualOrientationHeatgainQuery,
  useLazyGetAnnualFacadeHeatgainQuery,
  useLazyGetSurfaceAreaHeatgainAverageQuery,
  useLazyGetSurfaceFacadeHeatgainAverageQuery,
  useLazyGetSolarOrientationsQuery,
  useLazyGetSolidGlazedAreasQuery,
  useLazyGetAnnualSolarHeatgainQuery,
  useLazyGetMonthlySolarHeatgainQuery,
  useLazyGetPeakMonthlySolarHeatgainQuery,
  useLazyGetAnnualDaylightFactorQuery,
  useLazyGetAnnualShadingQuery,
  useSendShareMutation,
  useLazyGetColleaguesQuery,
  useInviteUserMutation,
  useLazyGetReportsByUserQuery,
  useLazyGetUserInfoQuery,
  useLazyGetProjectInfoQuery,
  useLazyGetLearnUponUrlQuery,
  useLazyGetBreadcrumbAsmQuery,
  useLazyGetBreadcrumbRegionalQuery,
  useLazyGetMsiUrlQuery,
  useGetIsFirstTimeLoginByIdQuery,
  useLazyGetIsUserFirstTimeQuery,
  useGetImportCustomGlazingQuery,
  useGetGlazingQueryInfoQuery,
} = api;

export const { resetApiState } = api.util;
