import Button from 'components/Button/Button';
import { t } from 'i18next';

const PasswordRecoveryReset = () => (
  <>
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 className="mb-4 text-left text-2xl font-bold text-base-primary">{t('recovery.resetPassword')}</h2>
    </div>
    <form className="space-y-6" action="/#" method="POST">
      <div>
        <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
          {t('recovery.currentPassword')}
        </label>
        <div className="mt-1">
          <input
            id="currentPassword"
            name="currentPassword"
            type="password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
          {t('recovery.newPassword')}
        </label>
        <div className="mt-1">
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
          />
        </div>
      </div>

      <div>
        <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-700">
          {t('recovery.confirmNewPassword')}
        </label>
        <div className="mt-1">
          <input
            id="confirmNewPassword"
            name="confirmNewPassword"
            type="password"
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
          />
        </div>
      </div>

      <div>
        <Button isBlock>{t('recovery.resetPassword')}</Button>
      </div>
    </form>
  </>
);

export default PasswordRecoveryReset;
