import { t } from 'i18next';
import Metrics from 'components/Metrics/Metrics';
import DataGrid from 'components/DataGrid/DataGrid';
import { useLazyGetRegionalsQuery, useGetGlobalsMetricQuery } from 'store/services/api';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import { useNavigate } from 'react-router-dom';
import { REGIONAL_DASHBOARD_PATH } from 'routes';
import Seo from 'components/Seo/Seo';
import { UserIcon } from '@heroicons/react/solid';
import {
  BriefcaseIcon, GlobeIcon, ArrowsExpandIcon,
} from '@heroicons/react/outline';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { REGIONS_ALTERNATE, ROLE_REGIONAL } from 'constants';

const DashboardGlobal = () => {
  const navigate = useNavigate();

  const dataGridProperties = [{
    key: 'name', label: t('DataGrid.tableHeader.global.name'), sort: true, filter: true, width: '20%', defaultSortOrder: SORT_ORDER_ASC,
  }, {
    key: 'email', label: t('DataGrid.tableHeader.global.email'), sort: true, filter: true, width: '30%',
  }, {
    key: 'no_asms', label: t('DataGrid.tableHeader.global.noAsms'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.NUMBER,
  }, {
    key: 'no_user', label: t('DataGrid.tableHeader.global.noPassiveUsers'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.NUMBER,
  }, {
    key: 'region', label: t('DataGrid.tableHeader.global.region'), sort: true, filter: true, width: '20%',
  }];

  const dataGridTabsProperties = [{
    name: t('dashboard.global.chartTitle'),
    dataGridProps: dataGridProperties,
    onItemClick: ({ id }) => navigate(`${REGIONAL_DASHBOARD_PATH}/${id}`),
  }];

  const metricsProperties = {
    regionals: {
      icon: <GlobeIcon />,
    },
    projects: {
      icon: <BriefcaseIcon />,
    },
    users: {
      icon: <UserIcon />,
    },
    area: {
      icon: <ArrowsExpandIcon />,
    },
  };

  return (
    <>
      <Seo title={t('dashboard.title')} />

      <div className="flex gap-8 w-full">

        <section className="flex-grow flex-shrink py-6">

          <Breadcrumb
            isInviteUser
            addUserRole={ROLE_REGIONAL}
            modalTitle={t('globalDashboard.inviteUser')}
          />

          {/*
          Name of regional leader
          Region
          */}

          <Metrics
            fetchDataHook={useGetGlobalsMetricQuery}
            properties={metricsProperties}
          />

          <DataGrid
            properties={dataGridTabsProperties}
            itemsPerPage={10}
            isPagination
            isInlineFilter
            inlineFilterProperties={[
              {
                type: 'select', key: 'region', label: 'Region', values: REGIONS_ALTERNATE,
              },
            ]}
            title={t('globalDashboard.regionalManagers')}
            informationMessage="Click above to invite Regional Leaders by inputting their name, email address and select their region. They will receive an invite with a link to register."
            lazyFetchDataHook={useLazyGetRegionalsQuery}
          />

        </section>

      </div>
    </>
  );
};

export default DashboardGlobal;
