import { createTheme } from '../utils';

const guardianTheme = createTheme({
  highlightPrimary: '#3166b0',
  highlightSecondary: '#87d4cc',
  basePrimary: '#4d4f5b',
  baseSecondary: '#333',
  lowlightPrimary: '#FFF',
  lowlightSecondary: '#f2f4f6',
  logo: "url('/logos/guardian.svg')",
  headerPattern: "url('/logos/guardian-header.svg')",
  loginImage: "url('/images/login-guardian.jpg')",
  loginLogo: "url('/images/logo-login-guardian.png')",
  bannerA: "url('/images/banner-1.png')",
  bannerB: "url('/images/banner-2.png')",
  bannerC: "url('/images/banner-3.png')",
  bannerD: "url('/images/banner-4.png')",
  brochureBanner: "url('/images/brochure-banner-3.png')",
});

export default guardianTheme;
