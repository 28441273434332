import { SearchIcon, FilterIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import Spinner from 'components/Spinner/Spinner';
import DataGridTabs from 'components/DataGrid/DataGridTabs';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import debounce from 'lodash.debounce';
import { t } from 'i18next';
import IconButton from 'components/IconButton/IconButton';
import Button from 'components/Button/Button';
import { UserAddIcon } from '@heroicons/react/outline';
import InviteUserModal from 'components/InviteUserModal/InviteUserModal';
import DataGridHeaderExport from './DataGridHeaderExport';
import DataGridFiltering from '../DataGridFilteringModal/DataGridFilteringModal';
import DataGridContext from '../DataGridContext';

const DataGridHeader = ({
  isSearch,
  isExport,
  isFetching,
  isFilter,
  isCreate,
  onSearchChange,
  onAddButtonClick,
  isInviteUser,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const { selectedTab, title } = useContext(DataGridContext);

  const onFilterModalOpenHandler = () => {
    setIsFilterModalOpen(true);
  };

  const onFilterModalCloseHandler = () => {
    setIsFilterModalOpen(false);
  };

  const onInviteUserModalOpenHandler = () => {
    setIsInviteUserModalOpen(true);
  };

  const onInviteUserModalCloseHandler = () => {
    setIsInviteUserModalOpen(false);
  };

  const debounceInput = useCallback(debounce(onSearchChange, 500), [isFetching]);

  const onSearchInputChangeHandler = (event) => {
    setSearchTerm(event.target.value);
    debounceInput(event.target.value);
  };

  useEffect(() => {
    setSearchTerm('');
  }, [selectedTab]);

  return (
    <div className="sm:flex sm:items-center gap-6">
      {title
        && <span className="text-[#38c7ba] text-xl font-bold text-highlight-primary">{title}</span>}
      <div className="sm:flex-auto">
        <DataGridTabs />
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
        <Spinner isLoading={isFetching} />
        {isCreate && (
          <Button onClick={onAddButtonClick}>{t('DataGrid.addButton')}</Button>
        )}
        {isInviteUser
          && (
            <IconButton
              icon={<UserAddIcon className="w-5 text-gray-500" />}
              onClick={onInviteUserModalOpenHandler}
            />
          )}
        {isFilter && (
          <IconButton
            icon={<FilterIcon className="w-5 text-gray-500" />}
            onClick={onFilterModalOpenHandler}
          />
        )}
        {isSearch && (
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              value={searchTerm}
              type="search"
              name="search"
              id="search"
              className="focus:ring-sky-500 focus:border-sky-500 block w-full h-10 m-0 pl-10 sm:text-sm border-gray-300 rounded-md"
              placeholder={t('DataGrid.searchPlaceholder')}
              onChange={onSearchInputChangeHandler}
            />
          </div>
        )}

        {isExport && (
          <DataGridHeaderExport />
        )}
      </div>

      <DataGridFiltering open={isFilterModalOpen} onClose={onFilterModalCloseHandler} />

      <InviteUserModal
        open={isInviteUserModalOpen}
        onClose={onInviteUserModalCloseHandler}
      />
    </div>
  );
};

DataGridHeader.propTypes = {
  isSearch: PropTypes.bool,
  isExport: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  isFilter: PropTypes.bool,
  isCreate: PropTypes.bool,
  isInviteUser: PropTypes.bool,
  onSearchChange: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func,
};

DataGridHeader.defaultProps = {
  isSearch: false,
  isExport: false,
  isFilter: false,
  isCreate: false,
  isInviteUser: false,
  onAddButtonClick: null,
};

export default DataGridHeader;
