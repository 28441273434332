import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import classNames from 'classnames';

const InviteUserModalTab = ({ label }) => (
  <Tab>
    {({ selected }) => (
      <button
        type="button"
        className={classNames('text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md', {
          'bg-gray-100 text-gray-700': selected,
        })}
      >
        {label}
      </button>
    )}
  </Tab>
);

InviteUserModalTab.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InviteUserModalTab;
