import { Dialog } from '@headlessui/react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import { useContext, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import Button from 'components/Button/Button';
import * as Yup from 'yup';
import DataGridContext from '../DataGridContext';
import DataGridItemChangeModalInput from './DataGridItemChangeModalInput';
import { DATA_TYPES } from '../DataGridConstants';

const yupRules = {
  [DATA_TYPES.STRING]: Yup.string().required(t('validationErrors.required')),
  [DATA_TYPES.NUMBER]: Yup.number(t('validationErrors.numeric')).required(t('validationErrors.required')),
  [DATA_TYPES.EMAIL]: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
};

const DataGridItemChangeModal = ({
  open, onClose, onItemCreate, itemToChange,
}) => {
  const { selectedTab } = useContext(DataGridContext);
  const isCreate = itemToChange === null || itemToChange === undefined;
  const formikInitialValues = useMemo(() => selectedTab.dataGridProps.reduce((acc, prop) => (
    prop.skipModify ? acc : {
      ...acc,
      [prop.key]: isCreate ? '' : itemToChange[prop.key],
    }
  ), {}), [selectedTab, itemToChange]);

  const validationSchema = useMemo(() => (
    Yup.object().shape(selectedTab.dataGridProps.reduce((acc, prop) => (
      prop.skipModify ? acc : {
        ...acc,
        [prop.key]: yupRules[prop.dataType],
      }
    ), {}))
  ), [selectedTab, itemToChange]);

  const onSubmitHandler = (changedItem) => {
    onItemCreate({ ...changedItem, id: itemToChange?.id });
  };

  return validationSchema && formikInitialValues ? (
    <Modal
      open={open}
      setOpen={onClose}
    >

      <Dialog.Title
        as="h1"
        className="text-xl font-bold leading-6 text-base-primary pb-6"
      >
        {isCreate ? t('DataGrid.itemChangeModal.createItemTitle') : t('DataGrid.itemChangeModal.updateItemTitle')}
      </Dialog.Title>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ errors, touched }) => (
          <Form>

            {selectedTab.dataGridProps.map((item) => (
              !item.skipModify && (
                <DataGridItemChangeModalInput
                  key={item.key}
                  item={item}
                  error={errors[item.key]}
                  displayError={!!errors[item.key] && !!touched[item.key]}
                />
              )
            ))}

            <div className="flex justify-end pt-4">
              <Button
                type="submit"
                disabled={Object.keys(errors).length > 0}
              >
                {isCreate ? t('DataGrid.itemChangeModal.createItemButton') : t('DataGrid.itemChangeModal.updateItemButton')}
              </Button>
            </div>

          </Form>
        )}
      </Formik>

    </Modal>
  ) : null;
};

DataGridItemChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemCreate: PropTypes.func,
  itemToChange: PropTypes.object,
};

DataGridItemChangeModal.defaultProps = {
  onItemCreate: () => { },
  itemToChange: undefined,
};

export default DataGridItemChangeModal;
