import { t } from 'i18next';
import Metrics from 'components/Metrics/Metrics';
import DataGrid from 'components/DataGrid/DataGrid';
import { useLazyGetDesignersProjectsQuery, useGetDesignersMetricQuery } from 'store/services/api';
import PageHeader from 'components/PageHeader/PageHeader';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import { PROJECTS_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import Seo from 'components/Seo/Seo';

const DashboardManager = () => {
  const navigate = useNavigate();

  const dataGridProperties = [{
    key: 'name', label: t('DataGrid.tableHeader.designer.name'), sort: true, filter: true, defaultSortOrder: SORT_ORDER_ASC, width: '30%', bold: true,
  }, {
    key: 'country', label: t('DataGrid.tableHeader.designer.country'), sort: true, filter: true, width: '20%',
  }, {
    key: 'last_user', label: t('DataGrid.tableHeader.designer.lastUser'), sort: true, filter: true, width: '25%',
  }, {
    key: 'no_users', label: t('DataGrid.tableHeader.designer.noUsers'), sort: true, filter: true, dataType: DATA_TYPES.NUMBER, width: '15%',
  }];

  const dataGridTabsProperties = [{
    name: t('projects.title'),
    dataGridProps: dataGridProperties,
    onItemClick: ({ id }) => navigate(`${PROJECTS_PATH}/${id}`),
  }];

  return (
    <>
      <Seo title={t('dashboard.title')} />

      <div className="flex gap-8 w-full">

        <section className="flex-grow flex-shrink py-6">

          <PageHeader title={t('dashboard.title')} />

          <Metrics
            fetchDataHook={useGetDesignersMetricQuery}
          />

          <DataGrid
            properties={dataGridTabsProperties}
            isExport
            isPagination
            isSearch
            isFilter
            itemsPerPage={5}
            lazyFetchDataHook={useLazyGetDesignersProjectsQuery}
          />

        </section>

      </div>
    </>
  );
};

export default DashboardManager;
