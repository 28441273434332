import {
  ROLE_ASM, ROLE_DESIGNER, ROLE_MANAGER, ROLE_REGIONAL, ROLE_GLOBAL,
} from 'constants';

export const DASHBOARD_PATH = '/dashboard';
export const GLOBAL_DASHBOARD_PATH = '/global';
export const REGIONAL_DASHBOARD_PATH = '/regional';
export const ASM_DASHBOARD_PATH = '/asm';
export const DESIGNER_DASHBOARD_PATH = '/designer';

export const BIM_PATH = '/bim';
export const CONCEPTUAL_PATH = '/conceptual';
export const DETAILED_PATH = '/detailed';
export const THERMAL_PATH = '/thermal';

export const REPORTS_PATH = '/reports';
export const PROJECTS_PATH = '/project';
export const RESOURCES_PATH = '/resources';
export const ASSEMBLIES_PATH = '/assemblies';
export const CODES_PATH = '/codes';
export const LOGIN_PATH = '/login';
export const SSO_PATH = '/login/sso';
export const IMPORT_CUSTOM_GLAZING_PATH = '/import/glazing';
export const SEND_GLAZING_QUERY = '/query/glazing';
export const MSI_PATH = '/msi';
export const PROFILE_PATH = '/profile';
export const PROFILE_USER_PATH = 'user';
export const PROFILE_PREFERENCES_PATH = 'preferences';
export const PROFILE_PASSWORD_PATH = 'password';
export const PASSWORD_RECOVERY_PATH = '/password-recovery';
export const PASSWORD_RECOVERY_REQUEST_PATH = 'request';
export const PASSWORD_RECOVERY_RESET_PATH = 'reset';

export const ALLOWED_ROLES = {
  [DETAILED_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [THERMAL_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [CONCEPTUAL_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [BIM_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [DASHBOARD_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [GLOBAL_DASHBOARD_PATH]: [ROLE_GLOBAL],
  [REGIONAL_DASHBOARD_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL],
  [ASM_DASHBOARD_PATH]: [ROLE_ASM, ROLE_GLOBAL, ROLE_REGIONAL],
  [REPORTS_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_MANAGER, ROLE_DESIGNER, ROLE_MANAGER],
  [PROJECTS_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_MANAGER, ROLE_DESIGNER, ROLE_ASM],
  [RESOURCES_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [ASSEMBLIES_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [CODES_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [MSI_PATH]: [ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [PROFILE_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
  [DESIGNER_DASHBOARD_PATH]: [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER],
};
