import {
  ROLE_GLOBAL, ROLE_REGIONAL, ROLE_DESIGNER, ROLE_ASM,
  ROLE_MANAGER,
} from 'constants';
import { getUser } from 'store/slices/user';
import { useSelector } from 'react-redux';

import DashboardGlobal from './DashboardGlobal';
import DashboardRegional from './DashboardRegional';
import DashboardDesigner from './DashboardDesigner';
import DashboardASM from './DashboardASM';
import DashboardManager from './DashboardManager';

const Dashboard = () => {
  const user = useSelector(getUser);

  switch (user.role) {
    case ROLE_REGIONAL:
      return <DashboardRegional />;
    case ROLE_DESIGNER:
      return <DashboardDesigner />;
    case ROLE_ASM:
      return <DashboardASM />;
    case ROLE_MANAGER:
      return <DashboardManager />;
    case ROLE_GLOBAL:
      return <DashboardGlobal />;
    default:
      return (<DashboardGlobal />);
  }
};

export default Dashboard;
