/* eslint-disable camelcase */
import { t } from 'i18next';
import {
  useCreateCodeMutation, useDeleteCodeMutation, useLazyGetCodesQuery, useUpdateCodeMutation,
} from 'store/services/api';
import DataGrid from 'components/DataGrid/DataGrid';
import PageHeader from 'components/PageHeader/PageHeader';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import Seo from 'components/Seo/Seo';
import toast from 'react-hot-toast';

const UValUnit = () => (
  <span>
    W/(m
    <sup>2</sup>
    K)
  </span>
);

const Codes = () => {
  const [createCode] = useCreateCodeMutation();
  const [updateCode] = useUpdateCodeMutation();
  const [deleteCode] = useDeleteCodeMutation();

  const dataGridProperties = [{
    key: 'name', label: t('codes.dataGridHeader.name'), sort: true, width: '20%', bold: true, defaultSortOrder: SORT_ORDER_ASC, dataType: DATA_TYPES.STRING,
  }, {
    key: 'creator', label: t('codes.dataGridHeader.creator'), sort: true, width: '20%', dataType: DATA_TYPES.EMAIL, skipModify: true,
  }, {
    key: 'floor', label: t('codes.dataGridHeader.floor'), sort: false, width: '10%', dataType: DATA_TYPES.NUMBER, unit: <UValUnit />,
  }, {
    key: 'glass', label: t('codes.dataGridHeader.glass'), sort: false, width: '10%', dataType: DATA_TYPES.NUMBER, unit: <UValUnit />,
  }, {
    key: 'roof', label: t('codes.dataGridHeader.roof'), sort: false, width: '10%', dataType: DATA_TYPES.NUMBER, unit: <UValUnit />,
  }, {
    key: 'wall', label: t('codes.dataGridHeader.wall'), sort: false, width: '10%', dataType: DATA_TYPES.NUMBER, unit: <UValUnit />,
  }, {
    key: 'percent_glazing', label: t('codes.dataGridHeader.percentGlazing'), sort: false, width: '20%', dataType: DATA_TYPES.NUMBER, unit: '%',
  }];

  const dataGridDetailsProperties = [{
    key: 'name', label: t('codes.dataGridHeader.name'),
  }, {
    key: 'creator', label: t('codes.dataGridHeader.creator'),
  }, {
    key: 'floor', label: t('codes.dataGridHeader.floor'), unit: <UValUnit />,
  }, {
    key: 'glass', label: t('codes.dataGridHeader.glass'), unit: <UValUnit />,
  }, {
    key: 'roof', label: t('codes.dataGridHeader.roof'),
  }, {
    key: 'wall', label: t('codes.dataGridHeader.wall'), unit: <UValUnit />,
  }, {
    key: 'percent_glazing', label: t('codes.dataGridHeader.percentGlazing'), unit: '%',
  }];

  const dataGridTabsProperties = [{
    name: t('codes.datagrid'),
    dataGridProps: dataGridProperties,
  }];

  const onItemCreate = async ({
    name, percent_glazing, roof, wall, floor, glass,
  }, onSuccessCallback) => {
    try {
      const result = await createCode({
        name,
        values: {
          percent_glazing, roof, wall, floor, glass,
        },
      });

      if (result.data) {
        toast.success(t('toasterMessage.success.codeCreation'));
        onSuccessCallback();
      } else {
        toast.error(result.error.data);
      }
    } catch (error) {
      toast.error(t('toasterMessage.error.codeCreation'));
    }
  };

  const onItemEdit = async ({
    name, percent_glazing, roof, wall, floor, glass, id,
  }, onSuccessCallback) => {
    try {
      const result = await updateCode({
        itemId: id,
        codeItem: {
          name,
          values: {
            percent_glazing, roof, wall, floor, glass,
          },
        },
      });

      if (result.data) {
        toast.success(t('toasterMessage.success.codeUpdate'));
        onSuccessCallback();
      } else {
        toast.error(result.error.data);
      }
    } catch (error) {
      toast.error(t('toasterMessage.error.codeUpdate'));
    }
  };

  const onItemDelete = async ({ id }, onSuccessCallback) => {
    try {
      const result = await deleteCode(id);

      if (result.data) {
        toast.success(t('toasterMessage.success.codeDelete'));
        onSuccessCallback();
      } else {
        toast.error(result.error.data);
      }
    } catch (error) {
      toast.error(t('toasterMessage.error.codeDelete'));
    }
  };

  return (
    <>
      <Seo title={t('codes.title')} />

      <section className="py-6">
        <PageHeader title={t('codes.title')} />

        <DataGrid
          shareType="code"
          properties={dataGridTabsProperties}
          detailsProperties={dataGridDetailsProperties}
          displayModalOnItemClick
          isExport
          isPagination
          isCreate
          isEdit
          isDelete
          itemsPerPage={10}
          lazyFetchDataHook={useLazyGetCodesQuery}
          informationMessage="To create a Custom Code, select “Create Custom Code” above."
          onItemCreate={onItemCreate}
          onItemEdit={onItemEdit}
          onItemDelete={onItemDelete}
        />
      </section>
    </>
  );
};

export default Codes;
