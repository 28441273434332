import BrochurePage from 'components/BrochurePage/BrochurePage';

const PageThermal = () => {
  const tr = true;
  const fl = false;

  return (
    <BrochurePage
      title="Thermal Analysis Tools"
      color="orange"
      activeColor="bg-[#1e68b5]"
      youtubeUrl="https://www.youtube.com/embed/rnWm-Uzgm_U?rel=0"
      image="/images/brochure/Thermal.png"
      links={[
        {
          label: 'Download Guardian Glass for BIM',
          url: 'https://api.fenestrapro.com/v0/msi/download/beta/',
        },
      ]}
      copy="The Thermal Analysis Tools in Guardian Glass for BIM enables designer to calculate total window or curtain wall system U-values using the following variables:"
      copyImportants={[
        'Thermal Performance of frames and opaque components.',
        'Variation at edge of glazing u-value in accordance with NFRC.',
        'Variation in glazing due to linear heat loss in accordance with EN standards',
      ]}
      isNext={fl}
      isBack={tr}
      isLast={tr}
      nextIndex={0}
      backIndex={2}
    />
  );
};

export default PageThermal;
