import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

const AccordionReportContentSwitch = ({ properties }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid">
      <div className="col-start-1 col-span-1 row-start-1 row-span-1 flex gap-4 h-12">
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">Select a tab</label>
            <select id="tabs" name="tabs" className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md">
              <option>My Account</option>
              <option>Company</option>
              <option selected>Team Members</option>
              <option>Billing</option>
            </select>
          </div>

          <div className="hidden sm:block">
            <nav className="flex space-x-4 pb-4" aria-label="Tabs">
              {properties.map((property, key) => (
                <button
                  type="button"
                  key={`link_${property.label}`}
                  onClick={() => setActiveIndex(key)}
                  className={classNames('text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md', {
                    'bg-blue-100 text-blue-700': key === activeIndex,
                  })}
                >
                  {property.label}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {properties[activeIndex].component}

    </div>
  );
};

export default AccordionReportContentSwitch;

AccordionReportContentSwitch.propTypes = {
  properties: PropTypes.array.isRequired,
};
