import classNames from 'classnames';
import useFormat from 'hooks/useFormat';
import { useContext, useEffect, useState } from 'react';
import DataGridContext from '../DataGridContext';
import DataGridTableActionsMenu from './DataGridTableActionsMenu';

const DataGridTableBody = () => {
  const {
    gridData,
    selectedTab,
    itemActionLabel,
    displayModalOnItemClick,
    isEdit,
    isLink,
    isDelete,
    isShare,
    onItemClick,
  } = useContext(DataGridContext);
  const [dataGridProps, setDataGridProps] = useState(selectedTab.dataGridProps);
  const { format } = useFormat();
  const { isFetching } = useContext(DataGridContext);

  const onItemActionClickHandler = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    onItemClick(item);
  };

  useEffect(() => {
    !isFetching && setDataGridProps(selectedTab.dataGridProps);
  }, [isFetching]);

  return (
    <tbody className="divide-y divide-gray-200 bg-lowlight-primary">
      {gridData?.length > 0 && gridData.map((item) => (
        <tr
          key={item.id}
          onClick={() => {
            !!onItemClick && onItemClick(item);
          }}
          className={classNames({
            'hover:bg-sky-50 cursor-pointer': !!selectedTab.onItemClick || displayModalOnItemClick,
          })}
        >
          {dataGridProps.map(({ key, dataType, bold }) => (
            <td
              key={`${key}_${item.id}`}
              className={classNames('whitespace-nowrap py-3 pl-4 pr-3 text-sm text-base-primary sm:pl-6 truncate', {
                'font-bold': bold,
              })}
            >
              {format(item[key], dataType)}
            </td>
          ))}

          {(isEdit || isDelete || isShare || isLink) && (
            <td>
              <DataGridTableActionsMenu item={item} />
            </td>
          )}

          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
            {itemActionLabel && (!!selectedTab.onItemClick || displayModalOnItemClick) && (
              <a href="/#" onClick={(event) => onItemActionClickHandler(event, item)} className="text-highlight-primary hover:text-sky-900">
                {itemActionLabel}
                <span className="sr-only">
                  ,
                  {item.name}
                </span>
              </a>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default DataGridTableBody;
