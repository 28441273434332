import { t } from 'i18next';
import Metrics from 'components/Metrics/Metrics';
import DataGrid from 'components/DataGrid/DataGrid';
import { useLazyGetAsmsQuery, useGetAsmsMetricQuery } from 'store/services/api';
import { DATA_TYPES, SORT_ORDER_ASC } from 'components/DataGrid/DataGridConstants';
import Seo from 'components/Seo/Seo';
import { DESIGNER_DASHBOARD_PATH, PROJECTS_PATH } from 'routes';
import { useNavigate } from 'react-router-dom';
import { serviceUrl } from 'store/services/settings';
import { UserIcon } from '@heroicons/react/solid';
import {
  BriefcaseIcon, GlobeIcon, ArrowsExpandIcon, TemplateIcon,
} from '@heroicons/react/outline';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { CONTAINER_ASM, ROLE_DESIGNER } from 'constants';
import useAreaUnit from 'hooks/useAreaUnit';

const DashboardASM = () => {
  const navigate = useNavigate();

  const dataGridTabsProperties = [{
    name: t('DataGrid.tabs.users'),
    urlSlug: serviceUrl.users,
    onItemClick: ({ id }) => navigate(`${DESIGNER_DASHBOARD_PATH}/${id}`),
    dataGridProps: [{
      key: 'name', label: t('DataGrid.tableHeader.users.name'), sort: true, filter: true, width: '15%', defaultSortOrder: SORT_ORDER_ASC,
    }, {
      key: 'email', label: t('DataGrid.tableHeader.users.email'), sort: true, filter: true, width: '20%',
    }, {
      key: 'is_msi_downloaded', label: t('DataGrid.tableHeader.users.isMsiDownloaded'), sort: true, filter: true, width: '10%',
    }, {
      key: 'last_login', label: t('DataGrid.tableHeader.users.lastSession'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.DATE,
    }, {
      key: 'no_projects', label: t('DataGrid.tableHeader.users.noProjects'), sort: true, filter: true, width: '15%',
    }, {
      key: 'area', label: t('DataGrid.tableHeader.users.area'), sort: true, filter: true, width: '15%', dataType: DATA_TYPES.AREA, unit: useAreaUnit(),
    }, {
      key: 'no_courses', label: t('DataGrid.tableHeader.users.noCourses'), sort: true, filter: true, width: '10%',
    }],
  },

  {
    name: t('DataGrid.tabs.projects'),
    urlSlug: serviceUrl.projects,
    onItemClick: ({ id }) => navigate(`${PROJECTS_PATH}/${id}`),
    dataGridProps: [{
      key: 'name', label: t('DataGrid.tableHeader.projects.name'), sort: true, filter: true, width: '20%', defaultSortOrder: SORT_ORDER_ASC,
    }, {
      key: 'country', label: t('DataGrid.tableHeader.projects.country'), sort: true, filter: true, width: '15%',
    }, {
      key: 'area',
      label: t('DataGrid.tableHeader.projects.area'),
      sort: true,
      filter: true,
      width: '15%',
      dataType: DATA_TYPES.AREA,
      unit: useAreaUnit(),
    }, {
      key: 'no_users', label: t('DataGrid.tableHeader.projects.noUsers'), sort: true, filter: true, width: '10%',
    }, {
      key: 'last_session', label: t('DataGrid.tableHeader.projects.lastSession'), sort: true, filter: true, width: '10%', dataType: DATA_TYPES.DATE,
    }],
  }];

  const metricsProperties = {
    asms: {
      icon: <GlobeIcon />,
    },
    projects: {
      icon: <BriefcaseIcon />,
    },
    users: {
      icon: <UserIcon />,
    },
    assemblies: {
      icon: <TemplateIcon />,
    },
    area: {
      icon: <ArrowsExpandIcon />,
    },
  };

  return (
    <>
      <Seo title={t('dashboard.title')} />

      <div className="flex gap-8 w-full">

        <section className="flex-grow flex-shrink py-6">

          <Breadcrumb
            current={CONTAINER_ASM}
            isInviteUser
            addUserRole={ROLE_DESIGNER}
            modalTitle={t('asmDashboard.inviteUser')}
          />

          <Metrics
            fetchDataHook={useGetAsmsMetricQuery}
            properties={metricsProperties}
          />

          {/*
            New properties::

            Name of region
            Name of ASM
            Name of regional leader
          */}

          <DataGrid
            title={t('asmDashboard.assignedUsersProjects')}
            properties={dataGridTabsProperties}
            isExport
            isPagination
            itemsPerPage={10}
            informationMessage="Click above to invite New Users by simply inputting their name and email address. They will receive an invite with a link to register."
            lazyFetchDataHook={useLazyGetAsmsQuery}
          />

        </section>
      </div>
    </>
  );
};

export default DashboardASM;
