import { createApi } from '@reduxjs/toolkit/query/react';
import { resetApiState } from './api';
import { baseQueryWithErrorHandling, serviceUrl } from './settings';

export const logoutApi = createApi({
  reducerPath: 'logoutApi',
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: serviceUrl.logout,
        method: 'GET',
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(resetApiState());
      },
    }),
  }),
});

export const { useLogoutMutation } = logoutApi;
