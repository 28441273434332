import PropTypes from 'prop-types';

const Accordion = ({ children }) => (
  <div className="bg-white rounded-lg shadow overflow-hidden divide-y divide-slate-300">
    {children}
  </div>

);

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Accordion;
