import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import DataGridInlineFilterRange from './DataGridInlineFilterRange';

const DataGridInlineFilter = ({ properties, onUpdate }) => {
  // Michael Bradley
  // The architecture here isn't ideal
  // It should be refactored over time

  const [rangeOptions, setRangeOptions] = useState(
    properties.filter((property) => property.type === 'range').map(({ key, min, max }) => ({ key, min, max })),
  );

  const [region, setRegion] = useState(null);

  const updateRangeProperties = (e) => {
    setRangeOptions(rangeOptions.map((option) => {
      if (option.key === e.name) {
        return { key: e.name, min: e.min, max: e.max };
      }
      return option;
    }));
  };

  return (
    <div className="bg-white flex justify-between text-slate-800 shadow text-sm mt-4 px-6 pt-4 pb-12 rounded items-center">
      {properties.filter((property) => property.type === 'range').map(({
        label, min, max, values, key, step,
      }) => (
        <div>
          <DataGridInlineFilterRange
            key={`range_${key}`}
            name={key}
            label={label}
            min={min}
            max={max}
            step={step}
            defaultValues={values}
            updateValues={(e) => updateRangeProperties(e)}
          />
        </div>
      ))}

      {properties.filter((property) => property.type === 'select').map(({ values, label }) => (
        <div className="flex flex-col gap-2">
          <label className="text-blue-600">{label}</label>
          <select onChange={(e) => setRegion(e.target.value)}>
            <option value="">All Regions</option>
            {values.map((reg) => <option key={`filter_${reg}`} value={reg}>{reg}</option>)}
          </select>
        </div>
      ))}

      <div style={{ paddingTop: '25px' }}>
        <Button
          onClick={() => {
            const rangeGte = rangeOptions.map((option) => ({ key: option.key, operator: { value: 'gte' }, value: option.min }));
            const rangeLte = rangeOptions.map((option) => ({ key: option.key, operator: { value: 'lte' }, value: option.max }));

            const otherFilter = [{ key: 'region', operator: { value: 'eq' }, value: region }];

            onUpdate([...rangeGte, ...rangeLte, ...otherFilter]);

            toast(t('toasterMessage.info.filterApplied_one'));
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default DataGridInlineFilter;

DataGridInlineFilter.propTypes = {
  properties: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
