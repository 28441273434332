import PropTypes from 'prop-types';
import { Field } from 'formik';
import { DATA_TYPES } from '../DataGridConstants';

const inputTypesMap = {
  [DATA_TYPES.STRING]: 'text',
  [DATA_TYPES.NUMBER]: 'number',
  [DATA_TYPES.EMAIL]: 'email',
};

const DataGridItemChangeModalInput = ({ item, error, displayError }) => (
  <div className="col-span-6 sm:col-span-4 flex justify-between">
    <label htmlFor={`item-change-modal-input-${item.key}`} className="block text-sm font-medium text-base-secondary w-36 mt-3">
      {item.label}
    </label>
    <div className="flex-1 flex flex-col">
      <div className="flex flex-1">
        <Field
          className="mt-1 focus:ring-highlight-primary focus:border-highlight-primary block flex-1 shadow-sm sm:text-sm border-gray-300 rounded-md"
          type={inputTypesMap[item.dataType]}
          name={item.key}
          id={`item-change-modal-input-${item.key}`}
        />

        <div className="w-20 pl-4 flex items-center text-sm font-medium text-base-secondary">{item.unit}</div>
      </div>
      <div className="h-5">
        {displayError && <span className="text-xs text-red-400">{error}</span>}
      </div>
    </div>
  </div>
);

DataGridItemChangeModalInput.propTypes = {
  item: PropTypes.object.isRequired,
  error: PropTypes.string,
  displayError: PropTypes.bool,
};

DataGridItemChangeModalInput.defaultProps = {
  error: '',
  displayError: false,
};

export default DataGridItemChangeModalInput;
