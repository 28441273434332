export const colors = [
  ['#2ec6b8'],
  ['#1767b6'],
  ['#ffcb2d'],
  ['#f7941e'],
  ['#a1a19b'],
];

const getColor = (index) => colors[index % colors.length];

export default getColor;
