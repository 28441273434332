import PropTypes from 'prop-types';
import { ExclamationIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const AlertBar = ({ label, action, link }) => (
  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
    <div className="flex">
      <div className="flex-shrink-0">
        <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div className="ml-3">
        <p className="text-sm text-yellow-700">
          {label}
          {' '}
          &middot;
          {' '}
          {link && action
          && (
          <Link to={link} className="font-medium underline text-yellow-700 hover:text-yellow-600">
            {action}
          </Link>
          )}
        </p>
      </div>
    </div>
  </div>

);

AlertBar.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string,
  link: PropTypes.string,
};

AlertBar.defaultProps = {
  action: null,
  link: null,
};

export default AlertBar;
