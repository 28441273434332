import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { getUser, isUserAuthenticated } from 'store/slices/user';
import { DASHBOARD_PATH } from 'routes';

const ProtectedPage = ({ children, allowedRoles }) => {
  const isUserLoggedIn = useSelector(isUserAuthenticated);
  const user = useSelector(getUser);
  const userHasRights = allowedRoles.includes(user.role);
  const location = useLocation();

  if (isUserLoggedIn) {
    if (userHasRights) {
      return children;
    }

    return (
      <Navigate
        to={DASHBOARD_PATH}
        state={{ from: location }}
        replace
      />
    );
  }

  return (
    <SsoLoginRedirect />
  );
};

const SsoLoginRedirect = () => {
  window.location.href = process.env.REACT_APP_SSO_URL;
  return null;
};

ProtectedPage.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.array.isRequired,
};

export default ProtectedPage;
