import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import Seo from 'components/Seo/Seo';
import { useNavigate } from 'react-router-dom';
import Button from '../Button/Button';
import {
  BIM_PATH,
  CONCEPTUAL_PATH,
  DETAILED_PATH,
  THERMAL_PATH,
} from '../../routes';
import VideoModal from '../VideoModal/VideoModal';
import DownloadModal from '../DownloadModal/DownloadModal';

const BrochurePage = ({
  title, copy, copyImportants, image, isNext, isBack, nextIndex, backIndex, isLast, youtubeUrl,
}) => {
  const navigate = useNavigate();
  const [isVideoModalOpen, setIsVideoTimeModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const onVideoModalOpenHandler = () => {
    setIsVideoTimeModalOpen(true);
  };

  const onDownloadModelOpenHandler = () => {
    setIsDownloadModalOpen(true);
  };

  const openNextPage = () => {
    switch (nextIndex) {
      case 1:
        navigate(`${CONCEPTUAL_PATH}`);
        break;
      case 2:
        navigate(`${DETAILED_PATH}`);
        break;
      case 3:
        navigate(`${THERMAL_PATH}`);
        break;
      default:
        navigate(`${BIM_PATH}`);
        break;
    }
  };

  const openPreviousPage = () => {
    switch (backIndex) {
      case 0:
        navigate(`${BIM_PATH}`);
        break;
      case 1:
        navigate(`${CONCEPTUAL_PATH}`);
        break;
      case 2:
        navigate(`${DETAILED_PATH}`);
        break;
      default:
        navigate(`${BIM_PATH}`);
        break;
    }
  };

  return (
    <>
      <DownloadModal
        open={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      />
      <Seo title={title} />

      <div className="max-w-8xl bg-white mt-6 p-8">
        <div className="bg-gradient-to-br from-[#256AB3] to-[#44BEB5] bg-opacity-80 rounded-md flex flex-col gap-2">
          <div className="flex flex-row">
            <div className="basis-7/12">
              <div className="relative flex flex-col items-center p-4 mb-6">
                <img src={image} alt="Stroke" className="object-cover h-[520px]" />
                <Button variant="brochureVideo" onClick={onVideoModalOpenHandler}>Watch Video</Button>
              </div>
            </div>
            <div className="flex flex-col p-16 basis-5/12 text-white">
              <div className="basis-11/12">
                <h2 className="text-3xl font-bold mb-5">{title}</h2>
                <p className="text-xl">
                  {copy}
                </p>
                <br />
                <ul className="text-xl custom-list">
                  <li className="mb-2">{copyImportants[0]}</li>
                  <li className="mb-2">{copyImportants[1]}</li>
                  <li>{copyImportants[2]}</li>
                </ul>
              </div>
              <div className="flex flex-row justify-end">
                {isBack
                  && <Button variant="brochure" onClick={openPreviousPage}>Back</Button>}
                <div className="pl-4" />
                {isNext
                  && <Button variant="brochure" onClick={openNextPage}>Next</Button>}
                {isLast
                  && <Button variant="brochureDownload" onClick={onDownloadModelOpenHandler}>Download</Button>}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center pt-8">
          <img src="/images/brochure/brochure-footer.png" width="1000" alt="Stroke" />
        </div>
        <VideoModal
          videoLink={youtubeUrl}
          open={isVideoModalOpen}
          onClose={() => setIsVideoTimeModalOpen(false)}
        />
      </div>
    </>
  );
};

const GalleryThumb = ({ src }) => (
  <Tab>
    {({ selected }) => (
      <img
        src={src}
        alt="This is a person"
        width={120}
        className={classNames('', {
          'border-2 border-sky-600': selected,
        })}
      />
    )}

  </Tab>
);

GalleryThumb.propTypes = {
  src: PropTypes.string.isRequired,
};

const GalleryImage = ({ src }) => (
  <Tab.Panel>
    <img
      src={src}
      alt="This is a person"
      width={500}
    />
  </Tab.Panel>
);

GalleryImage.propTypes = {
  src: PropTypes.string.isRequired,
};

BrochurePage.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  copyImportants: PropTypes.array.isRequired,
  image: PropTypes.string.isRequired,
  isNext: PropTypes.bool.isRequired,
  isBack: PropTypes.bool.isRequired,
  nextIndex: PropTypes.number.isRequired,
  backIndex: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
  youtubeUrl: PropTypes.string.isRequired,
};

export default BrochurePage;
