import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import DropdownMenu from 'components/DropdownMenu/DropdownMenu';
import DropdownMenuItem from 'components/DropdownMenu/DropdownMenuItem';
import { useLazyGetLearnUponUrlQuery } from 'store/services/api';
import {
  ExternalLinkIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';
import {
  ALLOWED_ROLES,
} from 'routes';
import toast from 'react-hot-toast';
import { useLogoutMutation } from 'store/services/logout';
import IconLearning from 'components/Icon/IconLearning';
import SidebarItem from './SidebarLink';
import SidebarGroup from './SidebarGroup';
import {
  BIMTOOLS, INTERNAL_LINKS, RESOURCES, WEBTOOLS,
} from './SidebarConstants';
import IconDownload from '../Icon/IconDownload';
import DownloadModal from '../DownloadModal/DownloadModal';
import IconHelp from '../Icon/IconHelp';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const [logout] = useLogoutMutation();

  const onSignOutClickHandler = async () => {
    try {
      await logout();
      toast.success(t('toasterMessage.success.logoutSuccessful'));
    } catch (error) {
      toast.error(t('toasterMessage.error.logoutFailed'));
    }
  };

  const userName = () => {
    if (user.firstName || user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return (
      <p>
        No Name
        {' '}
        <Link className="text-blue-400" to="/settings/profile">[Update]</Link>
      </p>
    );
  };

  const [trigger, learnUpon] = useLazyGetLearnUponUrlQuery();

  const triggerLearnUponFetch = async () => {
    await trigger();
  };

  useEffect(() => {
    if (learnUpon.error?.data) {
      window.open(learnUpon.error?.data, '_blank');
    }
  }, [learnUpon]);

  const getAndRedirectLearnUpon = () => {
    triggerLearnUponFetch();
  };

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const onDownloadModelOpenHandler = () => {
    setIsDownloadModalOpen(true);
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-highlight-primary">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pb-4 overflow-y-auto mt-24">

                <SidebarGroup title={t('Sidebar.webTools')}>
                  {WEBTOOLS.map((item) => <SidebarItem key={`BIM_${item.name}`} item={item} />)}
                </SidebarGroup>

                <SidebarGroup title={t('Sidebar.bimTools')}>
                  {BIMTOOLS.map((item) => <SidebarItem key={`BIM_${item.name}`} item={item} />)}
                </SidebarGroup>

                <SidebarGroup title={t('Sidebar.resources')}>
                  {RESOURCES.map((item) => <SidebarItem key={`Resources_${item.name}`} item={item} />)}
                </SidebarGroup>

              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-base-primary">
                        {userName()}
                      </p>
                      <p className="text-sm font-medium text-base-secondary group-hover:text-gray-700 capitalize">
                        {user.role}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Mobile Responsive End */}

      {/* Desktop Start */}
      <div className="hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0 mt-24">
        <DownloadModal
          open={isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
        />
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-gradient-to-b from-highlight-primary to-highlight-secondary ">
          <div className="flex-1 flex flex-col pb-4 overflow-y-auto">

            <SidebarGroup title={t('Sidebar.bimTools')}>
              {BIMTOOLS.map((item) => <SidebarItem key={`Resources_${item.name}`} item={item} />)}

              <button
                className="group flex items-center px-3 py-3 text-sm font-medium rounded-md text-white/80 hover:bg-white/10"
                onClick={onDownloadModelOpenHandler}
                type="button"
              >
                <div className="flex w-full">
                  <span className="mr-3 flex-shrink-0 h-5 w-5">
                    <IconDownload />
                  </span>
                  <span className="flex-grow text-left">
                    {t('Sidebar.download')}
                  </span>
                </div>
              </button>

            </SidebarGroup>

            <SidebarGroup title={t('Sidebar.resources')}>
              {INTERNAL_LINKS.map((item) => {
                const displayNavLink = !!ALLOWED_ROLES?.[item.href]?.includes(user.role);
                return displayNavLink && (
                  <SidebarItem item={item} key={`resources_${item.name}`} />
                );
              })}

              {RESOURCES.map((item) => <SidebarItem key={`Resources_${item.name}`} item={item} />)}

              <button
                className="group flex items-center px-3 py-3 text-sm font-medium rounded-md text-white/80 hover:bg-white/10"
                onClick={() => getAndRedirectLearnUpon()}
                type="button"
              >
                <div className="flex w-full">
                  <span className="mr-3 flex-shrink-0 h-5 w-5">
                    <IconLearning />
                  </span>
                  <span className="flex-grow text-left">
                    {t('Sidebar.learning')}
                  </span>
                  <ExternalLinkIcon className="w-4 text-white/60" />
                </div>
              </button>

              <button
                className="group flex items-center px-3 py-3 text-sm font-medium rounded-md text-white/80 hover:bg-white/10"
                onClick={() => window.open('https://guardian-support.fenestrapro.com/', '_blank')}
                type="button"
              >
                <div className="flex w-full">
                  <span className="mr-3 flex-shrink-0 h-5 w-5">
                    <IconHelp />
                  </span>
                  <span className="flex-grow text-left">
                    {t('Sidebar.support')}
                  </span>
                  <ExternalLinkIcon className="w-4 text-white/60" />
                </div>
              </button>

            </SidebarGroup>

            <SidebarGroup title={t('Sidebar.webTools')}>
              {WEBTOOLS.map((item) => <SidebarItem key={`Resources_${item.name}`} item={item} />)}
            </SidebarGroup>

          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4 bg-white">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div className="ml-3 flex-grow">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    {userName()}
                  </p>
                  {user.role !== 'designer' ? (
                    <p className="text-xs font-medium text-base-secondary group-hover:text-gray-700 capitalize">
                      {user.role}
                    </p>
                  ) : null}
                </div>
                <div>
                  <DropdownMenu
                    position="above"
                    menuButton={(
                      <>
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                      </>
                    )}
                  >
                    <DropdownMenuItem onItemClick={onSignOutClickHandler}>{t('general.signout')}</DropdownMenuItem>
                  </DropdownMenu>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* Desktop End */}
    </>
  );
};

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};

export default Sidebar;
