const IconLearning = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <defs><style>{'.cls-1,.cls-2{fill:none;stroke:#fff;stroke-width:2px;}.cls-2{stroke - linecap:round;stroke-linejoin:round;}'}</style></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Icons">
        <g id="Group_442" data-name="Group 442">
          <path id="Path_484" data-name="Path 484" className="cls-1" d="M11.06,11.14l9-5-9-5-9,5Z" />
          <path id="Path_485" data-name="Path 485" className="cls-1" d="M11.06,11.14l6.16-3.42a12,12,0,0,1,.66,6.48,12,12,0,0,0-6.82,3,12,12,0,0,0-6.82-3A12,12,0,0,1,4.9,7.72Z" />
          <path id="Path_486" data-name="Path 486" className="cls-2" d="M11.06,11.14l9-5-9-5-9,5Zm0,0,6.16-3.42a12,12,0,0,1,.66,6.48,12,12,0,0,0-6.82,3,12,12,0,0,0-6.82-3A12,12,0,0,1,4.9,7.72Zm-4,6V9.64l4-2.22" />
        </g>
      </g>
    </g>
  </svg>
);

export default IconLearning;
