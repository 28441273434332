import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

const Spinner = ({ isLoading, className }) => (
  <Transition
    className={`flex justify-center ${className}`}
    show={isLoading}
    enter="transition-opacity duration-100"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <svg
      className="animate-spin h-6 w-6 text-slate-800"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-50"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </Transition>
);

Spinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: '',
};

export default Spinner;
