import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  Menu,
} from '@headlessui/react';

const DropdownMenuItem = ({
  children, onItemClick, icon, isWarning,
}) => (

  <Menu.Item>
    {() => (
      <button
        type="button"
        onClick={onItemClick}
        className={classNames(
          'px-4 py-2 text-sm w-full text-left text-gray-700 hover:text-gray-900 hover:bg-gray-100 flex gap-2 items-center',
          { 'text-red-600 hover:text-red-600': isWarning },
        )}
      >
        {icon && icon}
        {children}
      </button>
    )}
  </Menu.Item>

);

export default DropdownMenuItem;

DropdownMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  onItemClick: PropTypes.func.isRequired,
  icon: PropTypes.node,
  isWarning: PropTypes.bool,
};

DropdownMenuItem.defaultProps = {
  icon: null,
  isWarning: false,
};
