import PropTypes from 'prop-types';

const MetaSkeleton = ({ length }) => (
  <div className="flex">
    {[...Array(length)].map(() => (
      <div className="p-2 flex flex-col gap-2 w-full md:w-1/4 animate-pulse h-24 justify-center">
        <div className="block w-1/2 h-4 bg-gray-300 rounded" />
        <div className="block w-4/5 h-4 bg-gray-300 rounded" />
      </div>
    ))}
  </div>
);

MetaSkeleton.propTypes = {
  length: PropTypes.number,
};

MetaSkeleton.defaultProps = {
  length: 4,
};

export default MetaSkeleton;
