/* eslint-disable camelcase */
import Button from 'components/Button/Button';
import { Formik, Form } from 'formik';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useUpdateProfileMutation } from 'store/services/api';
import { getUser } from 'store/slices/user';
import ProfileGroup from './ProfileGroup';
import ProfileInput from './ProfileInput';

const validationSchema = Yup.object().shape({
  old_password: Yup.string().min(2, t('validationErrors.tooShort')).max(50, t('validationErrors.tooLong')).required(t('validationErrors.required')),
  new_password: Yup
    .string()
    .min(8, t('validationErrors.tooShort'))
    .max(50, t('validationErrors.tooLong'))
    .required(t('validationErrors.required'))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, t('validationErrors.weakPassword')),
  confirm_password: Yup
    .string()
    .min(8, t('validationErrors.tooShort'))
    .max(50, t('validationErrors.tooLong'))
    .required(t('validationErrors.required'))
    .oneOf([Yup.ref('old_password'), null], t('validationErrors.passMatch'))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, t('validationErrors.weakPassword')),
});

const ProfilePassword = () => {
  const [updateProfile] = useUpdateProfileMutation();
  const { id } = useSelector(getUser);

  const formikInitialValues = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const onSubmit = async (formData) => {
    try {
      const result = await updateProfile({
        id,
        userData: {
          password: {
            old_password: formData.old_password,
            new_password: formData.new_password,
          },
        },
      });
      result.data ? toast.success(t('toasterMessage.success.userInfo')) : toast.error(t('toasterMessage.error.userInfo'));
    } catch (error) {
      toast.error(t('toasterMessage.error.userInfo'));
    }
  };

  return (
    <div>
      <Formik
        initialValues={formikInitialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <ProfileGroup title={t('Profile.passwordSettings')} description="Lorem ipsum dolor sit amet, consectetur adipiscing elit.">

              <ProfileInput
                label={t('Profile.currentPassword')}
                type="password"
                name="old_password"
                id="old-password"
                error={errors.old_password}
                displayError={!!errors.old_password && !!touched.old_password}
              />

              <ProfileInput
                label={t('Profile.newPassword')}
                type="password"
                name="new_password"
                id="new-password"
                error={errors.new_password}
                displayError={!!errors.new_password && !!touched.new_password}
              />

              <ProfileInput
                label={t('Profile.confirmNewPassword')}
                type="password"
                name="confirm_password"
                id="confirm-password"
                error={errors.confirm_password}
                displayError={!!errors.confirm_password && !!touched.confirm_password}
              />

            </ProfileGroup>

            <div className="flex justify-end py-6">
              <Button type="submit">
                {t('Profile.updateProfile')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ProfilePassword;
