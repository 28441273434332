import PropTypes from 'prop-types';
import { useState } from 'react';
import { Range } from 'react-range';
import classNames from 'classnames';

const DataGridInlineFilterRange = ({
  label, min, max, defaultValues, step, updateValues, name,
}) => {
  const [values, setValues] = useState(defaultValues);

  return (
    <div className="flex flex-col gap-2">
      <span className="text-[#38c7ba] text-base font-bold">{label}</span>
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        onChange={(vals) => {
          setValues(vals);
          updateValues({ name, min: vals[0], max: vals[1] });
        }}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="rounded bg-slate-300 h-1"
            style={{
              ...props.style,
              width: '200px',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            className={classNames('bg-sky-700 hover:bg-sky-600 h-4 w-4 rounded-full relative', {
              'bg-sky-800': isDragged,
            })}
            style={{
              ...props.style,
            }}
          >
            <span className="absolute text-slate-400 top-6">{values[props.key]}</span>
          </div>
        )}
      />
    </div>
  );
};

export default DataGridInlineFilterRange;

DataGridInlineFilterRange.propTypes = {
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  key: PropTypes.number.isRequired,
  defaultValues: PropTypes.array.isRequired,
  step: PropTypes.number,
  updateValues: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

DataGridInlineFilterRange.defaultProps = {
  step: 0.1,
};
