import Button from 'components/Button/Button';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ROLE_DESIGNER } from 'constants';
import { REPORTS_PATH } from '../../routes';
import InviteUserModal from '../InviteUserModal/InviteUserModal';
import useUserId from '../../hooks/useUserId';
import { useLazyGetLearnUponUrlQuery } from '../../store/services/api';

const MetricsActions = () => {
  const navigate = useNavigate();
  const userId = useUserId();
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [trigger, learnUpon] = useLazyGetLearnUponUrlQuery();

  const triggerLearnUponFetch = async () => {
    await trigger();
  };

  useEffect(() => {
    if (learnUpon.error?.data) {
      window.open(learnUpon.error?.data, '_blank');
    }
  }, [learnUpon]);

  const getAndRedirectLearnUpon = () => {
    triggerLearnUponFetch();
  };

  const onInviteUserModalOpenHandler = () => {
    setIsInviteUserModalOpen(true);
  };

  const onInviteUserModalCloseHandler = () => {
    setIsInviteUserModalOpen(false);
  };

  function create() {
    window.open('https://glassanalytics.guardian.com/app/configure', '_blank');
  }

  function learn() {
    navigate(`${REPORTS_PATH}`);
  }

  return (
    <>
      <div className="flex-1 px-4 py-0 sm:p-6 items-center text-center">
        <Button onClick={() => learn()} isBlock>
          {t('metrics.actions.analyse_label')}
        </Button>
        <p className="text-xs text-highlight-primary leading-4 pt-2">{t('metrics.actions.analyse_desc')}</p>
      </div>
      <div className="flex-1 px-4 py-0 sm:p-6 items-center text-center">
        <Button onClick={() => getAndRedirectLearnUpon()} isBlock>
          {t('metrics.actions.learn_label')}
        </Button>
        <p className="text-xs text-highlight-primary leading-4 pt-2">{t('metrics.actions.learn_desc')}</p>
      </div>
      <div className="flex-1 px-4 py-0 sm:p-6 items-center text-center">
        <Button onClick={() => create()} isBlock>
          {t('metrics.actions.create_label')}
        </Button>
        <p className="text-xs text-highlight-primary leading-4 pt-2">{t('metrics.actions.create_desc')}</p>
      </div>
      <div className="flex-1 px-4 py-0 sm:p-6 items-center text-center">
        <Button onClick={onInviteUserModalOpenHandler} isBlock>
          {t('metrics.actions.specify_label')}
        </Button>
        <InviteUserModal
          open={isInviteUserModalOpen}
          addUserRole={ROLE_DESIGNER}
          onClose={onInviteUserModalCloseHandler}
          modalTitle={t('designerDashboard.inviteUser')}
          userId={userId}
        />
        <p className="text-xs text-highlight-primary leading-4 pt-2">{t('metrics.actions.specify_desc')}</p>
      </div>
    </>
  );
};

export default MetricsActions;
