import { useEffect, useRef } from 'react';

const useOnUserInteractionPause = (elementRef, callback, timeout, dependencies) => {
  const interval = useRef();

  const onElementClick = () => {
    clearInterval(interval.current);
    interval.current = setInterval(callback, timeout);
  };

  useEffect(() => {
    elementRef.current?.addEventListener('click', onElementClick);

    return () => {
      elementRef.current?.removeEventListener('click', onElementClick);
    };
  }, [...dependencies, elementRef.current]);

  useEffect(() => {
    onElementClick();
  }, dependencies);
};

export default useOnUserInteractionPause;
