import PropTypes from 'prop-types';

const SidebarGroup = ({ children, title }) => (
  <div className="pb-2">
    {title
      && <div className="px-6 text-white font-bold py-1 mb-2" style={{ backgroundColor: '#004887' }}>{title}</div>}
    <nav className="mt-4 flex flex-col px-4 mb-2">
      {children}
    </nav>
  </div>

);

SidebarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

SidebarGroup.defaultProps = {
  title: null,
};

export default SidebarGroup;
