import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import { Tab } from '@headlessui/react';
import { getUser } from 'store/slices/user';
import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { ROLE_DESIGNER } from 'constants';
import DataGridInviteUserModalTab from './InviteUserModalTab';
import DataGridInviteUserModalUserForm from './InviteUserModalUserForm';
import { userTypeGuardian, userTypeUser } from './InviteUserModalConstants';
import InviteUserModalContext from './InviteUserModalContext';

const InviteUserModal = ({
  open, onClose, addUserRole, modalTitle, userId,
}) => {
  const onCloseButtonClickHandler = () => {
    onClose();
  };
  const user = useSelector(getUser);
  const isGuardian = user.platformType === userTypeGuardian;

  const [role] = useState(addUserRole);
  const [parentUserId] = useState(userId);

  const inviteUserModalContextValue = useMemo(() => ({
    role,
    parentUserId,
  }), [role]);

  return (
    <InviteUserModalContext.Provider value={inviteUserModalContextValue}>
      <Modal open={open} setOpen={onCloseButtonClickHandler}>
        <div className="px-2">
          <h1 className="font-bold text-base-primary pb-2 text-xl">{modalTitle}</h1>

          <Tab.Group>
            <Tab.List>
              {isGuardian && role !== ROLE_DESIGNER
                && <DataGridInviteUserModalTab label="Guardian" />}

              {role === ROLE_DESIGNER
                && <DataGridInviteUserModalTab label="User" />}
            </Tab.List>
            <Tab.Panels>
              {isGuardian && role !== ROLE_DESIGNER
                && (
                  <Tab.Panel>
                    <DataGridInviteUserModalUserForm
                      key="InviteGuardian"
                      type={userTypeGuardian}
                      onClose={onClose}
                    />
                  </Tab.Panel>
                )}

              {role === ROLE_DESIGNER
                && (
                  <Tab.Panel>
                    <DataGridInviteUserModalUserForm
                      key="InviteUser"
                      type={userTypeUser}
                      onClose={onClose}
                    />
                  </Tab.Panel>
                )}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Modal>
    </InviteUserModalContext.Provider>
  );
};

InviteUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addUserRole: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  userId: PropTypes.string,
};

InviteUserModal.defaultProps = {
  userId: '',
};

export default InviteUserModal;
