export const ROLE_GLOBAL = 'global';
export const ROLE_REGIONAL = 'regional';
export const ROLE_ASM = 'asm';
export const ROLE_MANAGER = 'manager';
export const ROLE_DESIGNER = 'designer';

export const ROLES = [ROLE_GLOBAL, ROLE_REGIONAL, ROLE_ASM, ROLE_MANAGER, ROLE_DESIGNER];

export const CONTAINER_GLOBAL = 'global';
export const CONTAINER_REGIONAL = 'regional';
export const CONTAINER_ASM = 'asm';
export const CONTAINER_DESIGNER = 'designer';
export const CONTAINER_PROJECT = 'project';

export const REGIONS = ['Asia', 'Europe', 'Middle East', 'Africa', 'North America', 'South America', 'India'];
export const REGIONS_ALTERNATE = ['APAC', 'AME', 'EU', 'NA', 'SA', 'MEX', 'IND'];
