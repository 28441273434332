export const translation = {
  appTitle: 'Fenestra Pro',
  dashboard: {
    title: 'Tableau de bord',
    welcome: 'Bienvenue',
    download: 'Téléchargez',
  },
  reports: {
    title: 'Bilan',
  },
  projects: {
    title: 'Projects',
    id: 'ID',
    name: 'Nom',
    cost: 'Coût',
    email: 'E-mail',
    createdAt: 'Créé à',
  },
  resources: {
    title: 'Ressources',
  },
  assemblies: {
    title: 'Assemblage de verre',
  },
  codes: {
    title: 'Codes',
  },
  DataGrid: {
    export: 'Export',
    searchPlaceholder: 'Recherchez',
    paginationLabel:
      '<1>{{name}}</1>, à <3>{{limit}}</3> de <4>{{totalItems}}</4> résultat',
  },
};
