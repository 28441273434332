import PropTypes from 'prop-types';
import useFormat from 'hooks/useFormat';

const ReportChartTooltip = ({ active, payload, label }) => {
  const { formatNumber } = useFormat();

  if (active && payload && payload.length) {
    return (
      <div className="bg-lowlight-primary border border-slate-200 px-6 py-4 text-sm text-slate-800 shadow-sm">
        <p className="pb-4 text-slate-500">{label}</p>
        <ul className="font-medium list-disc list-inside">
          {payload.map((row) => (
            <li
              style={{ color: row.color }}
              key={`item-key-${row.name}-${row.value}`}
            >
              {`${row.name} ${formatNumber(row.value, 2)} ${row.unit}`}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

ReportChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ReportChartTooltip.defaultProps = {
  active: false,
  payload: [],
  label: '',
};

export default ReportChartTooltip;
