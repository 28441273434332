import { DATA_TYPES } from 'components/DataGrid/DataGridConstants';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';

const useFormat = () => {
  const locale = navigator.language;
  const { currency, region } = useSelector(getUser);

  const formatDate = (datetime) => (
    datetime ? new Intl.DateTimeFormat(locale, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(datetime)) : ''
  );

  const formatNumber = (number, numOfDecimals = 2) => (
    new Intl.NumberFormat(locale, {
      maximumFractionDigits: numOfDecimals,
    }).format(number)
  );

  const formatCurrency = (number) => (
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(number)
  );

  const formatArea = (number, numOfDecimals = 2) => {
    // Note from Michael
    // The JWT isn't currently returning the user's unit system (metric/US).
    // In this case, we'll determine the user's preference from their region for the time being
    // If regional === NAC - return ft2, otherwise m2
    // This should be refactored accordingly in the future

    const US_UNIT_REGION = 'NAC';

    return `${new Intl.NumberFormat(locale, {
      maximumFractionDigits: numOfDecimals,
    }).format((region === US_UNIT_REGION) ? number * 10.7639 : number)}`;
  };

  const formatUrl = (data) => (
    <a href={data} className="text-blue-600 hover:underline" target="_blank" rel="noreferrer">
      {data}
    </a>
  );

  const formatEmail = (data) => (
    <a href={`mailto:${data}`} className="text-blue-600 hover:underline" target="_blank" rel="noreferrer">
      {data}
    </a>
  );

  const format = (data, dataType) => {
    switch (dataType) {
      case DATA_TYPES.NUMBER:
        return formatNumber(data);
      case DATA_TYPES.CURRENCY:
        return formatCurrency(data);
      case DATA_TYPES.DATE:
        return formatDate(data);
      case DATA_TYPES.AREA:
        return formatArea(data);
      case DATA_TYPES.URL:
        return formatUrl(data);
      case DATA_TYPES.EMAIL:
        return formatEmail(data);
      default:
        return data;
    }
  };

  return {
    format, formatDate, formatNumber, formatCurrency, formatEmail,
  };
};

export default useFormat;
