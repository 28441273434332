import { InformationCircleIcon, SearchIcon } from '@heroicons/react/solid';
import { t } from 'i18next';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import DataGridContext from '../DataGridContext';
import DataGridNotice from '../DatagridNotice';
import DataGridSkeleton from '../DataGridSkeleton';
import DataGridTableBody from './DataGridTableBody';
import DataGridTableHeader from './DataGridTableHeader';

const DataGridTable = ({ informationMessage }) => {
  const {
    gridData,
    isFetching,
    queryParams,
    selectedTab,
    itemsPerPage,
  } = useContext(DataGridContext);

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="block py-2 align-middle md:px-6 lg:px-8">
          <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-md">
            {gridData && gridData?.length > 0 && (
              <table className="w-full divide-y divide-gray-300">
                <DataGridTableHeader />
                <DataGridTableBody />
              </table>
            )}
            {(!gridData || gridData?.length === 0)
              && !isFetching
              && (queryParams.search || queryParams.filter ? (
                <DataGridNotice label={t('DataGrid.noResultsFound')} icon={<SearchIcon />} />
              ) : (
                <DataGridNotice label={informationMessage} icon={<InformationCircleIcon />} />
              ))}
            {!gridData.length && isFetching && (
              <DataGridSkeleton properties={selectedTab.dataGridProps} limit={itemsPerPage} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DataGridTable.propTypes = {
  informationMessage: PropTypes.string,
};

DataGridTable.defaultProps = {
  informationMessage: t('DataGrid.noDataAvailable'),
};

export default DataGridTable;
