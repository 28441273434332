import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import FormTextInput from 'components/Form/FormTextInput';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useSendShareMutation } from 'store/services/api';

const validationSchema = Yup.object().shape({
  email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
});

const DataGridShareModalEmail = ({
  itemId,
  shareType,
  onClose,
}) => {
  const formikInitialValues = {
    email: '',
  };

  const [sendShare, { isLoading }] = useSendShareMutation();

  const onSubmitHandler = async (data) => {
    try {
      const result = await sendShare({
        data: {
          target: data.email,
          share_method: 'email',
          share_type: shareType,
        },
        id: itemId,
      });

      result.data ? toast.success('Shared') : toast.error('There is no user with such email');
      onClose();
    } catch (error) {
      toast.error(t('toasterMessage.error.requestPassReset'));
    }
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ errors }) => (
        <Form className="flex flex-col gap-6">
          <FormTextInput name="email" label="E-mail" />

          <Button
            disabled={Object.keys(errors).length > 0 || isLoading}
            type="submit"
          >
            {t('DataGrid.shareModal.buttons.shareWithEmail')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

DataGridShareModalEmail.propTypes = {
  itemId: PropTypes.string.isRequired,
  shareType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DataGridShareModalEmail;
