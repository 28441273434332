import { t } from 'i18next';
import PageHeader from 'components/PageHeader/PageHeader';
import Seo from 'components/Seo/Seo';

const Resources = () => (
  <>
    <Seo title={t('resources.title')} />

    <PageHeader title={t('resources.title')} />

  </>
);

export default Resources;
