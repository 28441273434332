import { useState } from 'react';
import Sidebar from 'components/Sidebar/Sidebar';
import { MenuIcon } from '@heroicons/react/outline';
import { Outlet } from 'react-router-dom';
import Header from 'components/Header/Header';

const MainLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="relative">
      <Header />
      <div className="mt-24">
        <Sidebar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={(state) => setSidebarOpen(state)}
        />

        <div className="md:pl-72 flex flex-col flex-1 bg-lowlight-secondary h-[calc(100vh_-_6rem)] overflow-y-auto">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-lowlight-primary">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-base-secondary hover:text-base-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1">
            <div className="sm:px-6 md:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
