import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { useQuery } from '../../hooks/useQuery';
import FormTextInput from '../../components/Form/FormTextInput';
import FormTextArea from '../../components/Form/FormTextArea';
import Button from '../../components/Button/Button';
import {
  useGetGlazingQueryInfoQuery,
  useSendProductEnquiryMutation,
} from '../../store/services/api';

const GlazingQuery = () => {
  const query = useQuery();
  const userId = query.get('user_id');
  const glassId = query.get('glass_id');
  const { data, error } = useGetGlazingQueryInfoQuery({
    userId,
    glassId,
  });
  const [formikInitialValues, setFormikInitialValues] = useState({
    name: '',
    email: '',
    topic: '',
    message: '',
  });
  const [sendEnquiry, { isLoading }] = useSendProductEnquiryMutation();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    try {
      if (error?.data) {
        toast.error('Something went wrong. Please try again later.');
      } else if (data) {
        setFormikInitialValues({
          name: `${data.firstname} ${data.lastname}`,
          email: `${data.email}`,
          topic: '',
          message: '',
        });
      }
    } catch {
      toast.error('Something went wrong. Please try again');
    }
  }, [data]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('validationErrors.required')),
    email: Yup.string().email(t('validationErrors.email')).required(t('validationErrors.required')),
    topic: Yup.string().required(t('validationErrors.required')),
    message: Yup.string().required(t('validationErrors.required')),
  });

  const onSubmitHandler = async (formData) => {
    try {
      const result = await sendEnquiry({
        data: {
          user_id: data.userId,
          region: data.region,
          full_name: formData.name,
          email: formData.email,
          company_name: formData.topic,
          question: formData.message,
          glass_description: data.glassDescription,
          source: 'guardian',
        },
      });

      if (result) {
        setSuccess(true);
      }

      result ? toast.success(t('DataGrid.contact.enquirySent')) : toast.error(t('DataGrid.contact.enquiryError'));
    } catch {
      toast.error('Something went wrong. Please try again');
    }
  };

  if (data && !success) {
    return (
      <div className="p-16">
        <h2 className="font-bold text-3xl">Send Query</h2>
        <h2 className="pb-4">{data.glassDescription}</h2>
        <Formik
          enableReinitialize
          initialValues={formikInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {({ errors }) => (
            <Form className="flex flex-col gap-2">
              <FormTextInput name="name" label={t('DataGrid.contact.name')} />
              <FormTextInput name="email" label={t('DataGrid.contact.email')} />
              <FormTextInput name="topic" label={t('DataGrid.contact.topic')} />
              <FormTextArea name="message" label={t('DataGrid.contact.message')} />

              <div>
                <Button
                  isBlock
                  disabled={Object.keys(errors).length > 0 || isLoading}
                  type="submit"
                  isLoading={isLoading}
                >
                  {t('DataGrid.contact.sendEnquiry')}
                </Button>
              </div>
            </Form>

          )}
        </Formik>
      </div>
    );
  }

  if (success) {
    return (
      <div>
        <h2>Query Sent Successfully</h2>
      </div>
    );
  }
};

export default GlazingQuery;
