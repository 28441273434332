import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BIM_PATH } from '../../routes';
import DownloadModal from '../DownloadModal/DownloadModal';
import VideoModal from '../VideoModal/VideoModal';

const MetricsIntroduction = () => {
  const navigate = useNavigate();
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoTimeModalOpen] = useState(false);
  const [isVideoModalOpen2, setIsVideoTimeModalOpen2] = useState(false);

  const dashboardVideoUrl = 'https://www.youtube.com/embed/5Sx8qgsYXEk?rel=0';
  const dashboardVideoUrl2 = 'https://www.youtube.com/embed/o7cqzPlYdyE?rel=0';

  const onVideoModalOpenHandler = () => {
    setIsVideoTimeModalOpen(true);
  };

  const onVideoModalOpenHandler2 = () => {
    setIsVideoTimeModalOpen2(true);
  };

  const navigateIntroduction = () => {
    navigate(`${BIM_PATH}`);
  };

  const onDownloadModelOpenHandler = () => {
    setIsDownloadModalOpen(true);
  };

  return (
    <>
      <DownloadModal
        open={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      />
      <VideoModal
        videoLink={dashboardVideoUrl}
        open={isVideoModalOpen}
        onClose={() => setIsVideoTimeModalOpen(false)}
      />
      <VideoModal
        videoLink={dashboardVideoUrl2}
        open={isVideoModalOpen2}
        onClose={() => setIsVideoTimeModalOpen2(false)}
      />
      <div className="container mx-auto">
        <div className="flex flex-wrap -mx-2">
          <button type="submit" className="w-full md:w-1/4 p-4" onClick={onVideoModalOpenHandler}>
            <div className="relative md:h-40">
              <img
                src="/images/dashboard-1.png"
                alt="dashboard-1"
                className="object-cover h-full w-full"
              />
            </div>
            <div className="bg-[#3267B2] flex flex-col p-4 text-white relative md:h-32">
              <h3 className="text-lg font-semibold mb-2">How to Download and Install</h3>
              <p>(45 second video)</p>
            </div>
          </button>
          <button type="submit" className="w-full md:w-1/4 p-4" onClick={onVideoModalOpenHandler2}>
            <div className="relative md:h-40">
              <img
                src="/images/dashboard-2.png"
                alt="dashboard-2"
                className="object-cover h-full w-full"
              />
            </div>
            <div className="bg-[#3267B2] flex flex-col p-4 text-white relative md:h-32">
              <h3 className="text-lg font-semibold mb-2">How to Open on a Revit Project</h3>
              <p>(63 second video)</p>
            </div>
          </button>
          <button type="submit" className="w-full md:w-1/4 p-4" onClick={navigateIntroduction}>
            <div className="relative md:h-40">
              <img
                src="/images/dashboard-3.png"
                alt="dashboard-3"
                className="object-cover h-full w-full"
              />
            </div>
            <div className="bg-[#38c7ba] flex flex-col p-4 text-white relative md:h-32">
              <h3 className="text-lg font-semibold mb-2">Introduction to Guardian Glass for BIM</h3>
              <p>&nbsp;</p>
            </div>
          </button>
          <button type="submit" className="w-full md:w-1/4 p-4" onClick={onDownloadModelOpenHandler}>
            <div className="relative md:h-40">
              <img
                src="/images/dashboard-4.png"
                alt="dashboard-4"
                className="object-cover h-full w-full"
              />
            </div>
            <div className="bg-[#38c7ba] flex flex-col p-4 text-white relative md:h-32">
              <h3 className="text-lg font-semibold mb-2">Download Guardian Glass for BIM</h3>
              <p>&nbsp;</p>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default MetricsIntroduction;
