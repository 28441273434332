import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import { Dialog } from '@headlessui/react';
import { t } from 'i18next';
import Button from 'components/Button/Button';

const DataGridDeleteConfirmModal = ({
  open, onClose, onConfirm,
}) => (
  <Modal
    open={open}
    setOpen={onClose}
  >

    <Dialog.Title
      as="h1"
      className="text-xl font-bold leading-6 text-base-primary pb-6"
    >
      {t('DataGrid.deleteConfirmationModal.title')}
    </Dialog.Title>

    <div className="flex w-full">
      <Button variant="warning" onClick={onConfirm}>
        {t('DataGrid.deleteConfirmationModal.yes')}
      </Button>

      <div className="ml-2">
        <Button
          variant="secondary"
          onClick={onClose}
        >
          {t('DataGrid.deleteConfirmationModal.no')}
        </Button>
      </div>
    </div>
  </Modal>
);

DataGridDeleteConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DataGridDeleteConfirmModal;
