import PropTypes from 'prop-types';
import Modal from 'components/Modal/Modal';
import { t } from 'i18next';
import { Dialog, Tab } from '@headlessui/react';
import { useLazyGetColleaguesQuery } from 'store/services/api';
import { useEffect } from 'react';
import useUserId from 'hooks/useUserId';
import DataGridShareModalTab from './DataGridShareModalTab';
import DataGridShareModalUser from './DataGridShareModalUser';
import DataGridShareModalTeam from './DataGridShareModalTeam';
import DataGridShareModalEmail from './DataGridShareModalEmail';

const DataGridShareModal = ({
  open,
  onClose,
  itemName,
  itemId,
  isUserShare,
  isTeamShare,
  isEmailShare,
  shareType,
}) => {
  const userId = useUserId();
  const [trigger, result] = useLazyGetColleaguesQuery();

  const triggerDataFetch = async () => {
    await trigger({ id: userId });
  };

  useEffect(() => {
    if (!result.data) {
      triggerDataFetch();
    }
  }, []);

  return (
    <Modal
      open={open}
      setOpen={onClose}
    >
      <Dialog.Title
        as="h2"
        className="text-xl text-left font-bold leading-6 text-base-primary pb-6"
      >
        {t('DataGrid.shareModal.title', { item: itemName })}
      </Dialog.Title>

      <Tab.Group>
        <nav className="flex space-x-4 mb-6" aria-label="Tabs">
          <Tab.List>
            {isUserShare && (
              <DataGridShareModalTab label={t('DataGrid.shareModal.buttons.users')} />
            )}
            {isTeamShare && (
              <DataGridShareModalTab label={t('DataGrid.shareModal.buttons.teams')} />
            )}
            {isEmailShare && (
              <DataGridShareModalTab label={t('DataGrid.shareModal.buttons.email')} />
            )}
          </Tab.List>
        </nav>
        <Tab.Panels>
          {isUserShare && (
            <Tab.Panel>
              <DataGridShareModalUser options={result.data} itemId={itemId} />
            </Tab.Panel>
          )}
          {isTeamShare && (
            <Tab.Panel>
              <DataGridShareModalTeam options={result.data} itemId={itemId} />
            </Tab.Panel>
          )}
          {isEmailShare && (
            <Tab.Panel>
              <DataGridShareModalEmail itemId={itemId} shareType={shareType} onClose={onClose} />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
};

DataGridShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  itemName: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isUserShare: PropTypes.bool,
  isTeamShare: PropTypes.bool,
  isEmailShare: PropTypes.bool,
  shareType: PropTypes.string,
};

DataGridShareModal.defaultProps = {
  itemName: '',
  isUserShare: false,
  isTeamShare: false,
  isEmailShare: true,
  shareType: '',
};

export default DataGridShareModal;
