import PropTypes from 'prop-types';

const ReportChartTable = ({ data, properties }) => (
  <>
    <div className="mx-6 mb-4 text-slate-700 font-medium">{data.name}</div>
    <div className="flex flex-col h-72 bg-white rounded shadow overflow-hidden mb-6 mx-6">
      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 overloy-y-scroll">
        <div className="inline-block min-w-full align-middle md:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="sticky top-0 bg-slate-500">
              <tr>
                {properties.map((property) => <th scope="col" className="py-2 px-6 text-left text-sm font-semibold text-white capitalize">{property}</th>)}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {data.data.map((item) => (
                <tr key={item.name}>
                  {properties.map((property) => <td className="whitespace-nowrap py-2 px-6 text-sm text-gray-500">{(typeof item[property] === 'object') ? JSON.stringify(item[property]) : item[property]}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>
);

export default ReportChartTable;

ReportChartTable.propTypes = {
  data: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
};
