import PropTypes from 'prop-types';

const DataGridSkeleton = ({ properties, limit }) => (
  <table className="w-full divide-y divide-gray-300">
    <thead className="bg-gray-50">
      <tr>
        {properties.map(({ key, width }) => (
          <th
            key={`skeleton-head-col-${key}`}
            scope="col"
            className="py-3.5 pl-4 pr-3 sm:pl-6"
            style={{ width }}
          >
            <div className="block h-4 w-3/5 bg-gray-200 rounded" />
          </th>
        ))}
        <th scope="col" className="py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">View</span>
        </th>
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-200 bg-lowlight-primary">
      {[...Array(limit).keys()].map((element) => (
        <tr key={element}>
          {properties.map(({ key }) => (
            <td
              key={`skeleton-body-col-${key}`}
              className="py-3.5 pl-4 pr-3 sm:pl-6"
            >
              {element % 2 === 0 ? (
                <div className="block h-4 w-3/5 bg-gray-200 rounded" />
              ) : (
                <div className="block h-4 w-2/5 bg-gray-200 rounded" />
              )}
            </td>
          ))}
          <td className="relative py-3 pl-3 pr-4 sm:pr-6">
            <div className="block h-4 w-3/5 bg-gray-200 rounded" />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

DataGridSkeleton.propTypes = {
  properties: PropTypes.array.isRequired,
  limit: PropTypes.number,
};

DataGridSkeleton.defaultProps = {
  limit: 10,
};

export default DataGridSkeleton;
