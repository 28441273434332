import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HomeIcon } from '@heroicons/react/solid';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from 'store/slices/user';
import {
  ASM_DASHBOARD_PATH, DASHBOARD_PATH, DESIGNER_DASHBOARD_PATH, REGIONAL_DASHBOARD_PATH,
} from 'routes';
import { UserAddIcon } from '@heroicons/react/outline';
import { t } from 'i18next';
import useUserId from 'hooks/useUserId';

import {
  CONTAINER_GLOBAL, CONTAINER_REGIONAL, CONTAINER_ASM, CONTAINER_DESIGNER,
  CONTAINER_PROJECT,
} from 'constants';
import InviteUserModal from 'components/InviteUserModal/InviteUserModal';
import { useState, useEffect } from 'react';
import IconButton from 'components/IconButton/IconButton';
import { useLazyGetBreadcrumbAsmQuery, useLazyGetBreadcrumbRegionalQuery } from 'store/services/api';
import { useQuery } from 'hooks/useQuery';
import BreadcrumbItem from './BreadcrumbItem';

const Breadcrumb = ({
  current, isInviteUser, addUserRole, modalTitle,
}) => {
  const user = useSelector(getUser);
  const userId = useUserId();
  const query = useQuery();
  const navigate = useNavigate();
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

  const { pathname } = useLocation();

  const containers = [
    { type: CONTAINER_GLOBAL, label: t('globalDashboard.title'), href: null },
    { type: CONTAINER_REGIONAL, label: t('regionalDashboard.title'), href: null },
    { type: CONTAINER_ASM, label: t('asmDashboard.title'), href: null },
    { type: CONTAINER_DESIGNER, label: t('designerDashboard.title'), href: null },
    { type: CONTAINER_PROJECT, label: t('projects.title'), href: null },
  ];

  const start = containers.findIndex((container) => container.type === user.role);
  const end = containers.findIndex((container) => container.type === current);
  const crumbs = containers.slice(start + 1, end + 1);

  const onInviteUserModalOpenHandler = () => {
    setIsInviteUserModalOpen(true);
  };

  const onInviteUserModalCloseHandler = () => {
    setIsInviteUserModalOpen(false);
  };

  const [trigger, asm] = useLazyGetBreadcrumbAsmQuery();
  const [triggerRegional, regional] = useLazyGetBreadcrumbRegionalQuery();

  const triggerBreadCrumbAsmFetch = async (designerId) => {
    await trigger({ designerId });
  };

  const triggerBreadCrumbRegionalFetch = async (asmId) => {
    await triggerRegional({ asmId });
  };

  const getUrl = (label) => {
    switch (label) {
      case 'ASM':
        triggerBreadCrumbAsmFetch(pathname.split('/')[2]);
        break;
      case 'Regional':
        triggerBreadCrumbRegionalFetch(pathname.split('/')[2]);
        break;
      case 'Designer':
        if (query.get('designerId')) {
          navigate(`${DESIGNER_DASHBOARD_PATH}/${query.get('designerId')}`);
        }
        break;
      default:
    }
  };

  useEffect(() => {
    if (asm.isSuccess) {
      navigate(`${ASM_DASHBOARD_PATH}/${asm.data}`);
    }
  }, [asm]);

  useEffect(() => {
    if (regional.isSuccess) {
      navigate(`${REGIONAL_DASHBOARD_PATH}/${regional.data}`);
    }
  }, [regional]);

  return (
    <nav className="bg-white border-b border-gray-200 flex rounded shadow-sm mb-6 h-12" aria-label="Breadcrumb">
      <ol className="w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
        <BreadcrumbItem>
          <NavLink
            to={DASHBOARD_PATH}
            className={({ isActive }) => classNames(
              isActive
                ? 'text-[#38c7ba] hover:text-[#38c7ba] text-base'
                : 'text-[#38c7ba] text-base',
              '',
            )}
          >
            <>
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </>
          </NavLink>
        </BreadcrumbItem>

        {pathname === DASHBOARD_PATH
          ? (
            <BreadcrumbItem>
              <span className="text-[#38c7ba] text-base font-medium ">
                Welcome
                {' '}
                <span className="text-[#38c7ba] text-base capitalize">
                  {`${user.firstname} ${user.lastname}`}
                </span>
                {' '}
                {user.role !== 'designer' ? (
                  <span className=" text-[#38c7ba] text-base font-normal">
                    (
                    {user.role}
                    )
                  </span>
                ) : null}
              </span>
            </BreadcrumbItem>
          )
          : (
            <>
              {crumbs.map((crumb, index) => (
                <BreadcrumbItem>
                  <svg
                    className="flex-shrink-0 w-6 h-full text-[#38c7ba] text-base"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>

                  {index + 1 === crumbs.length ? (<span className="ml-4 text-base font-medium text-[#38c7ba]">{crumb.label}</span>) : (
                    <button
                      onClick={() => getUrl(crumb.label)}
                      type="button"
                      className="ml-4 text-base font-medium text-[#38c7ba] hover:text-[#38c7ba] underline"
                      aria-current={true ? 'page' : undefined}
                    >
                      {crumb.label}
                    </button>
                  )}

                </BreadcrumbItem>
              ))}
            </>
          )}

      </ol>

      {isInviteUser
        && (
          <>
            <div className="flex gap-4 items-center pr-4">
              <span>
                <IconButton
                  icon={<UserAddIcon className="w-5 text-base text-[#38c7ba]" />}
                  onClick={onInviteUserModalOpenHandler}
                />

              </span>
            </div>

            <InviteUserModal
              open={isInviteUserModalOpen}
              addUserRole={addUserRole}
              onClose={onInviteUserModalCloseHandler}
              modalTitle={modalTitle}
              userId={userId}
            />
          </>
        )}
    </nav>
  );
};

Breadcrumb.propTypes = {
  current: PropTypes.string,
  isInviteUser: PropTypes.bool,
  addUserRole: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
};

Breadcrumb.defaultProps = {
  current: null,
  isInviteUser: false,
  addUserRole: null,
};

export default Breadcrumb;
