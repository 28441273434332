import PropTypes from 'prop-types';
import useUserId from 'hooks/useUserId';
import { useEffect } from 'react';
import MetaSkeleton from './MetaSkeleton';

const Meta = ({ fetchDataHook, properties }) => {
  const userId = useUserId();
  const [trigger, { data, status }] = fetchDataHook();

  const triggerDataFetch = async () => {
    await trigger({
      userId,
    });
  };

  useEffect(() => {
    triggerDataFetch();
  }, [userId]);

  return (
    <div className="bg-white rounded-lg shadow p-4 text-sm">
      {status === 'pending'
        && (
          <MetaSkeleton />
        )}

      {data && status === 'fulfilled'
        && (
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-4">
            {properties.map(({
              key, label, formatter, unit,
            }) => (
              <div key={`user_info_${key}`} className="sm:col-span-1">
                <dt className="text-base font-medium text-[#38c7ba]">{label}</dt>
                <dd className="text-sm text-gray-900">
                  {formatter ? formatter(data[key]) : data[key]}
                  {` ${unit || ''}`}
                </dd>
              </div>
            ))}
          </dl>
        )}

    </div>
  );
};

Meta.propTypes = {
  fetchDataHook: PropTypes.func.isRequired,
  properties: PropTypes.array,
};

Meta.defaultProps = {
  properties: undefined,
};

export default Meta;
